import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { SystemProps, keyframes, system, th } from '@xstyled/styled-components'
import { Select, SelectProps } from 'antd'

const slideUp = keyframes({
  '0%': { opacity: 0, transform: 'translateY(10px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`

export const $Select = styled(Select)<SelectProps>`
  &&& {
    color: red;
  }
`

export const $DropdownTrigger = styled.divBox`
  width: 100%;
  border: 1px solid;
  border-color: grey12;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  padding: 8px 12px;
  min-height: 32px;
  transition: all 0.3s ease-in-out;

  &[data-error='true'] {
    border-color: danger;
  }

  &[data-disabled='true'] {
    border-color: grey8;
    background-color: grey4;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
    border-color: grey24;
  }
`

type ThemeBg = 'light' | 'dark'

interface WithThemeProps {
  themeBg?: ThemeBg
}

export const DropdownContent = styled(RadixDropdownMenu.Content)<RadixDropdownMenu.MenuContentProps & SystemProps>`
  &&& {
    z-index: 100;
  }

  ${system}
`

export const $DropdownMenu = styled.divBox.attrs<WithThemeProps>((props) => ({
  themeBg: props.themeBg,
}))<WithThemeProps>`
  background-color: ${({ themeBg }) => th.color(themeBg === 'dark' ? 'dark' : 'white')};
  border-radius: 10px;
  min-width: 250px;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: ${th.shadow('normal')};
  animation: ${slideUp} 0.2s ease-in-out;
  z-index: 10;
`

export const $DropdownMenuItem = styled.divBox.attrs<WithThemeProps>((props) => ({
  themeBg: props.themeBg,
}))<WithThemeProps>`
  /* width: 100%; */
  display: flex;
  padding: 10px;
  font-size: 12px;

  h3 {
    color: ${({ themeBg }) => th.color(themeBg === 'light' ? 'grey' : 'light')} !important;
  }

  &:hover {
    outline: none;
    cursor: pointer;
    background-color: ${th.color('grey-faded')};
  }

  &[data-checked='true'] {
    background-color: ${th.color('accent-faded')};
  }
`
