const sanitizeDomain = (domain: string) => {
  const trimmedDomain = domain.trim()
  const domainWithoutProtocol = trimmedDomain.replace('http://', '').replace('https://', '')
  const domainWithoutPort = domainWithoutProtocol.split(':')[0]

  return domainWithoutPort
}

interface BuildURLParams {
  domain: string
  protocol?: string | null
  port?: number | null
}

export const buildURL = ({ domain, port, protocol = 'https' }: BuildURLParams) => {
  const sanitizedDomain = sanitizeDomain(domain)

  if (port != null) {
    return `${protocol}://${sanitizedDomain}:${port}`
  }

  return `${protocol}://${sanitizedDomain}`
}
