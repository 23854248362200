import { Form, useFormikContext } from 'formik'
import React from 'react'

import { Divider, InputField, RowFields } from '../../../components'

interface LocationFormProps {
  onChange?: (data: Partial<ILocation>) => void
  location?: Partial<ILocation>
  isActive?: boolean
}

// TODO merge with LocationSettingsDetails
export default function LocationForm({ location, onChange, isActive }: LocationFormProps) {
  const formik = useFormikContext<Partial<ILocation>>()
  const { errors, values } = formik

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(location ?? {}, false)
    // eslint-disable-next-line
  }, [location, isActive])

  React.useEffect(() => {
    onChange?.(values)
    // eslint-disable-next-line
  }, [values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>DETAILS</strong>
      </h4>
      <Divider light />
      <br />
      <InputField placeholderName="location" name="name" label="Name" error={errors?.name} />
      <RowFields>
        <InputField
          placeholderName="location"
          name="address.street"
          label="Street"
          error={errors?.address?.['street']}
        />
        <InputField
          placeholderName="location"
          name="address.number"
          label="Number"
          error={errors?.address?.['number']}
        />
      </RowFields>
      <RowFields>
        <InputField placeholderName="location" name="address.city" label="City" error={errors?.address?.['city']} />
        <InputField
          placeholderName="location"
          name="address.postcode"
          label="Postcode"
          error={errors?.address?.['postcode']}
        />
      </RowFields>
    </Form>
  )
}
