import { Typography } from '@mui/material'
import Fade from '@mui/material/Fade'
import { Form, Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import { Button, InputField, InputFieldProps, Modal } from '../../components'
import { BE_PATHS, FE_PATHS } from '../../constants/paths'
import { useAuthContext, useOrganisationContext } from '../../context'
import { useAxios, useRedirect } from '../../hooks'

import schema from './Login.schema'
import * as S from './LoginForm.styled'

const LoginInputField = (props: InputFieldProps<Partial<User>>) => InputField<Partial<User>>(props)

const LoginForm = ({ onSuccess }: { onSuccess: () => Promise<void> }) => {
  const { login } = useAuthContext()
  const { setSelectedOrganisationId } = useOrganisationContext()
  const [modalOpen, setModalOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const redirectToDashboard = useRedirect(FE_PATHS.DASHBOARD)
  const [, performLogin] = useAxios<Partial<User>>({ method: 'POST', url: BE_PATHS.LOGIN })

  const handleOnSubmit = async (body: Partial<User>) => {
    try {
      const { token }: any = await performLogin({ body })
      login(token, (user) => setSelectedOrganisationId(user.organisationId))
      await onSuccess()
      redirectToDashboard()
    } catch (e) {
      enqueueSnackbar('Email or password incorrect! Please try again', {
        variant: 'error',
      })
    }
  }

  const handleForgotPassword = () => {
    setModalOpen(true)
  }

  return (
    <S.$Form>
      <Formik initialValues={{ email: '', password: '' }} validationSchema={schema} onSubmit={handleOnSubmit}>
        {(formik: FormikProps<Partial<User>>) => {
          return (
            <Form onSubmit={formik.handleSubmit}>
              <>
                <LoginInputField name="email" placeholder="Email" />
                <br />
                <LoginInputField name="password" placeholder="Password" type="password" />
                {
                  <S.$Link tabIndex={0} onClick={handleForgotPassword}>
                    Forgot password?
                  </S.$Link>
                }
                <br />
                <br />
                <Fade timeout={{ enter: 2500 }} in>
                  <Typography component="h1" variant="h5">
                    <Button
                      variant="primary"
                      text="Login"
                      disabled={formik.isSubmitting}
                      style={{ h: '45px', w: '100%' }}
                      type="submit"
                    />
                  </Typography>
                </Fade>
              </>
            </Form>
          )
        }}
      </Formik>
      <Modal
        title="Forgot password?"
        body={'Please contant the system administrator to help you reset your password.'}
        onConfirm={() => setModalOpen(false)}
        variant="primary"
        isOpen={modalOpen}
      />
    </S.$Form>
  )
}

export default LoginForm
