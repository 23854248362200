import { Tooltip } from '@mantine/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import dayjs from 'dayjs'
import _ from 'lodash'
import React from 'react'
import * as TablerIcons from 'tabler-icons-react'

import { Condition, EmptyState, Loader, OverlaySpinner } from '..'
import { FORMATS } from '../../constants/formats'
import { Card } from '../Card'

import * as S from './Table.styled'

const isDate = (text: string | number) => dayjs(text).isValid()

export interface THeader<T> {
  key?: keyof T
  customKey?: string
  subkey?: string
  align?: 'left' | 'right' | 'center'
  type?: 'price' | 'datetime' | 'date' | 'string' | 'number' | 'array' | 'object'
  format?: (value: any, item: T) => string | number | undefined
  render?: (value: any, item: T) => JSX.Element
}

function formatValue<T>(value: any, header: THeader<T>, item: T): string | number | JSX.Element {
  if (header.format) return header.format(value, item) || '—'
  if (header.render) return header.render(value, item)
  if (header.type === 'price') return `£ ${Number(value).toFixed(2)}`
  if (header.type === 'datetime') return dayjs(value).format(FORMATS.DATETIME)
  if (header.type === 'date' && isDate(value)) return dayjs(value).format(FORMATS.DATE)
  if (header.type === 'number' || header.type === 'string') return value
  if (header.type === 'array') return value?.length || 0
  if (header.type === 'object' && typeof value === 'object') return value[header?.subkey as any] || ''
  return value
}

type Alignment = 'left' | 'center' | 'right' | 'justify' | 'char'

export interface TableHeadingProps {
  text: string
  align: Alignment
}

const TableHeading = ({ text, align }: TableHeadingProps) => {
  const headingText = text.charAt(0).toUpperCase() + text.slice(1)

  return (
    <th align={align}>
      <S.$HeadingText className="table__heading-text">{headingText}</S.$HeadingText>
    </th>
  )
}

export interface TableDataProps<T> {
  value: any
  align: Alignment
  header: THeader<T>
  item: T
  bold?: boolean
}

function TableData<T>({ value, align, header, item, bold = false }: TableDataProps<T>) {
  return (
    <td align={align}>
      <Condition when={bold} fallback={<S.$CellValue>{formatValue<T>(value, header, item)}</S.$CellValue>}>
        <S.$CellValue>
          <b>{formatValue<T>(value, header, item)}</b>
        </S.$CellValue>
      </Condition>
    </td>
  )
}

export interface TableAction<T> {
  label?: string
  tooltip?: string
  onClick?: (item: T) => void
  type?: 'edit' | 'delete'
  icon?: JSX.Element
  disabled?: (item: T) => boolean
  render?: (item: T) => JSX.Element
  hide?: (item: T) => boolean
}

export interface TableProps<T> {
  headers: THeader<T>[]
  align?: Alignment
  actions?: TableAction<T>[] | false
  items?: T[]
  summary?: T
  loading?: boolean
  hasMoreItems?: boolean
  onLoadMore?: () => void
  loadingPlaceholder?: JSX.Element
  heading?: string | JSX.Element
  emptyState?: {
    emptyTitle: string
    emptySubTitle: string
    icon?: JSX.Element
  }
}

export default function Table<T>({
  heading,
  headers,
  actions,
  items,
  summary,
  align = 'center',
  loading = false,
  onLoadMore,
  hasMoreItems,
  emptyState,
}: TableProps<T>) {
  const observer = React.useRef<IntersectionObserver>()
  const [loadingMore, setLoadingMore] = React.useState(false)

  const lastItemRef = React.useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && hasMoreItems) {
          setLoadingMore(true)
          onLoadMore?.()
        } else {
          setLoadingMore(false)
        }
      })

      if (node) observer.current.observe(node)
    },
    [loading, hasMoreItems, onLoadMore],
  )

  const {
    emptySubTitle = `We couldn't find any data that matches your criteria`,
    emptyTitle = 'No data',
    icon = <TablerIcons.FoldersOff size={70} />,
  } = emptyState ?? {}

  return (
    <>
      <Card withoutPadding>
        {heading && <S.$Heading>{heading}</S.$Heading>}
        <S.$Table data-with-heading={!!heading} data-with-summary={Boolean(summary)}>
          <thead>
            <S.$TableRow key="tr-headers">
              {headers.map((header, idx) => (
                <TableHeading
                  key={`thead-${idx}`}
                  align={
                    header?.align ||
                    (idx < headers.length - 1 ? 'left' : actions && actions?.length === 0 ? 'center' : align)
                  }
                  text={String(header.key || header.customKey)}
                />
              ))}
              {actions && actions?.length && <TableHeading key="thead-actions" align={align} text="Actions" />}
            </S.$TableRow>
          </thead>
          <tbody style={{ position: 'relative' }}>
            <tr>
              <OverlaySpinner loading={!loadingMore && loading} opaque />
            </tr>
            <Condition when={!items?.length}>
              <tr>
                <td colSpan={headers?.length} style={{ padding: 0 }}>
                  <EmptyState
                    title={emptyTitle}
                    subtitle={emptySubTitle}
                    icon={icon}
                    style={{ margin: '48px auto', maxWidth: '480px', boxShadow: 'none' }}
                  />
                </td>
              </tr>
            </Condition>
            {items?.map((d, i) => {
              return (
                <S.$TableRow key={`tr-${i}`} ref={items?.length === i + 1 && !summary ? lastItemRef : undefined}>
                  {headers.map((header, idx) => (
                    <TableData
                      key={`tItem-${idx}`}
                      align={
                        header?.align ||
                        (idx < headers.length - 1 ? 'left' : actions && actions?.length === 0 ? 'center' : align)
                      }
                      value={d[header.key as keyof T] as T[keyof T]}
                      header={header}
                      item={d}
                    />
                  ))}
                  {actions && (
                    <S.$ActionsContainer key={`td-actions`} align={align}>
                      {actions?.map((action) => {
                        if (action.hide?.(d)) return null
                        if (action.render) return action.render(d)

                        return (
                          <S.$Action
                            key={`action-${action.label}`}
                            disabled={action.disabled?.(d)}
                            onClick={() => action?.onClick && action.onClick(d)}
                          >
                            <Tooltip label={action.tooltip || action.label || _.capitalize(action.type)}>
                              {action.type === 'edit' && <EditIcon fontSize="inherit" />}
                              {action.type === 'delete' && <DeleteIcon fontSize="inherit" />}
                              {action.icon && action.icon}
                            </Tooltip>
                          </S.$Action>
                        )
                      })}
                    </S.$ActionsContainer>
                  )}
                </S.$TableRow>
              )
            })}
            {Boolean(items?.length) && summary && (
              <S.$TableSummaryRow key={`tr-summary`} ref={lastItemRef}>
                {headers.map((header, idx) => (
                  <TableData
                    key={`tItem-${idx}`}
                    align={
                      header?.align ||
                      (idx < headers.length - 1 ? 'left' : actions && actions?.length === 0 ? 'center' : align)
                    }
                    value={summary?.[header.key as keyof T] as T[keyof T]}
                    header={header}
                    item={summary}
                    bold
                  />
                ))}
              </S.$TableSummaryRow>
            )}
          </tbody>
        </S.$Table>
      </Card>
      {loadingMore && (
        <div
          style={{
            width: 200,
            height: 50,
            display: 'flex',
            margin: 'auto',
            marginTop: 20,
          }}
        >
          <Loader />
        </div>
      )}
    </>
  )
}
