import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from 'react-router-dom'
import { EmptyState } from 'src/components'
import { FE_PATHS } from 'src/constants'
import { Page, Shell } from 'src/containers'

export const Unauthorized = () => {
  const navigate = useNavigate()

  return (
    <Page>
      <Shell>
        <EmptyState
          title="Unauthorized"
          subtitle="You are not authorized to view this page"
          icon={<LockIcon />}
          buttonProps={{
            text: 'Go to Dashboard',
            variant: 'accent',
            onClick: () => navigate(FE_PATHS.DASHBOARD),
            style: { h: '50px' },
          }}
        />
      </Shell>
    </Page>
  )
}
