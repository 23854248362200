import styled from '@xstyled/styled-components'

export const $Container = styled.hrBox`
  margin-bottom: 4px;
  margin-top: 4px;
  height: 0.5px;
  width: 100%;
  border: none;
  border-radius: 2px;

  background-color: gray;
`
