import { FlattenSimpleInterpolation, Interpolation } from 'styled-components'
import { css } from '@xstyled/styled-components'
import { assign, getThemeValue, is, merge } from '@xstyled/util'

export const small = (styles: FlattenSimpleInterpolation) => {
  return css`
    @media (max-width: 767px) {
      ${styles}
    }
  `
}

export const medium = (styles: TemplateStringsArray) => {
  return css`
    @media (min-width: 767px) and (max-width: 1100px) {
      ${styles}
    }
  `
}

export const large = (styles: TemplateStringsArray) => {
  return css`
    @media (min-width: 1100px) and (max-width: 1500px) {
      ${styles}
    }
  `
}

export const variant =
  ({ key = null, default: defaultValue, variants = {}, prop = 'variant' }: any) =>
  (props: any): Interpolation<any> => {
    const themeVariants = is(key) ? getThemeValue(props, key) : null
    const computedVariants = merge(assign({}, variants), themeVariants)
    const value = props[prop] !== undefined ? props[prop] : defaultValue
    const result = getThemeValue(props, value, computedVariants)
    return result as any
  }

export const screens = {
  small,
  medium,
  large,
}
