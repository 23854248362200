import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Modal } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import { CameraSchema } from './Camera.schema'
import CameraForm from './CameraForm'

interface CameraModalProps {
  isOpen: boolean
  camera?: Partial<ICamera>
  locationId: string
  onSubmitSuccess: (device: ICamera) => void
  onClose: () => void
}

export const CameraModal = ({ isOpen, camera, locationId, onSubmitSuccess, onClose }: CameraModalProps) => {
  const toast = useToast()
  const [, submitData] = useAxios<Partial<ICamera>>({
    method: camera?.name ? 'PUT' : 'POST',
    url: camera?.id ? `${BE_PATHS.LOCATION_CAMERAS(locationId)}/${camera?.id}` : BE_PATHS.LOCATION_CAMERAS(locationId),
  })

  const [formData, setFormData] = React.useState<Partial<ICamera>>()

  const initialValues = {
    locationId,
    ...camera,
  } as Partial<ICamera>

  return (
    <Formik initialValues={initialValues} validationSchema={CameraSchema} onSubmit={() => {}}>
      {({ errors, validateForm, setTouched }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 750 }}
            title={!camera ? 'New camera' : 'Edit camera'}
            body={
              <CameraForm camera={camera || initialValues} onChange={setFormData} isActive={isOpen} errors={errors} />
            }
            confirmText={!camera ? 'Create' : 'Save'}
            variant="accent"
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                setTouched(Object.keys(validationErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}) as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newCamera = await submitData({ body: _.omit(formData, 'id') })
                newCamera && onSubmitSuccess(newCamera as ICamera)
                toast(`${formData?.name || 'Camera'} has been saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
