import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 110px;
  padding: 5px;
  background-color: primary;
  border-radius: 8px;
  box-shadow: 0px 1px 4px #ddd;
  border: 2px solid transparent;

  &[data-active='true'] {
    &[data-variant='default'] {
      border: 2px solid;
      border-color: dark;
    }
    &[data-variant='healthy'] {
      border: 2px solid;
      border-color: teal;
    }
    &[data-variant='warning'] {
      border: 2px solid;
      border-color: yellow;
    }
    &[data-variant='degraded'] {
      border: 2px solid;
      border-color: danger;
    }
  }

  ${Styles.screens.small`
		max-width: unset;
    margin-right: 0;
	`}
`

export const $Icon = styled.divBox`
  font-size: 18px;
  background-color: teal;
  color: primary;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 6px;

  &[data-variant='healthy'] {
    background-color: teal;
  }
  &[data-variant='warning'] {
    background-color: yellow;
  }
  &[data-variant='degraded'] {
    background-color: danger;
  }
  &[data-variant='default'] {
    background-color: dark;
  }
`

export const $CardLabel = styled.divBox`
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: grayer;
`

export const $CardCount = styled.divBox`
  margin-top: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;

  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: grayer;
  }
`

export const $PeripheralsError = styled.divBox`
  text-align: left;
  display: flex;
  flex-direction: column;
  margin: auto;
`
