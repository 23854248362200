import * as RadixPopover from '@radix-ui/react-popover'

import * as S from './Popover.styled'

S.Content.defaultProps = {
  side: 'bottom',
  align: 'center',
  sideOffset: 0,
}

export const Popover = {
  Root: RadixPopover.Root,
  Portal: RadixPopover.Portal,
  Trigger: S.Trigger,
  Content: S.Content,
  Arrow: RadixPopover.Arrow,
}
