import * as yup from 'yup'

const getUserSchema = (isCreate: boolean) => {
  const basePasswordValidation = yup.string().min(6).max(30)

  return yup.object().shape({
    name: yup.string().required('* Required'),
    email: yup.string().email().required('* Required'),
    type: yup.string().oneOf(['ADMIN', 'MANAGER', 'USER'], 'Must be admin, manager or user').required('* Required'),
    password: isCreate ? basePasswordValidation.required('* Required') : basePasswordValidation.optional(),
  })
}

export default getUserSchema
