import { useSnackbar } from 'notistack'

export function useCopyToClipboard() {
  const { enqueueSnackbar: toast } = useSnackbar()

  const copyToClipboard = (text: string | number) => {
    navigator.clipboard.writeText(String(text))
    toast('Copied to clipboard', { variant: 'default' })
  }

  return copyToClipboard
}
