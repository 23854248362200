export const truncateInTheMiddle = (str: string | undefined, maxLength: number) => {
  if (!str) return ''

  if (str.length <= maxLength) return str

  const mid = Math.floor(maxLength / 2)
  return `${str.substr(0, mid)}...${str.substr(str.length - mid)}`
}

export const convertBytesIntoHumanReadable = (bytes?: number) => {
  if (!bytes) return ''
  if (bytes === 0) return '0 B'

  const k = 1024
  const dm = 2
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
