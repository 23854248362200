import styled from '@xstyled/styled-components'

export const $Form = styled.divBox`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
`

export const $Link = styled.divBox`
  margin-top: 20px;
  cursor: pointer;
  color: dark;

  &:hover,
  &:focus {
    color: darker;
    text-decoration: underline;
    outline: none;
  }
`
