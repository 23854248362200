import { useDidUpdate } from '@mantine/hooks'
import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData } from 'src/hooks'
import { clearEmpties, genQueryParams, sortBy } from 'src/utils'

export const useBookings = (
  filters?: BookingFilter,
  page = 0,
  limit = 20,
): [UseAxiosData<IFullBooking[]>, () => Promise<void>] => {
  const { organisation } = useOrganisationContext()

  const [bookings, setBookings] = React.useState<IBooking[]>([])
  const [hasMore, setHasMore] = React.useState<boolean>(false)

  const organisationId = organisation?.id

  const [{ data: bookingsData }, refetchBookings] = useAxios<Paginated<IFullBooking>>({
    url: BE_PATHS.BOOKINGS,
    params: {
      filter: genQueryParams({ organisationId }),
      include: genQueryParams({ location: true }),
      page,
      limit,
    },
    pause: !organisationId,
    runOnInit: false,
  })

  const [fetching, setFetching] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!bookingsData) return
    if (bookingsData?.page !== 0) return

    setBookings(bookingsData?.results || [])
    setHasMore(bookingsData?.hasMore || false)

    // eslint-disable-next-line
  }, [bookingsData])

  const handleRefetchBookings = React.useCallback(async () => {
    if (!organisationId) return
    setFetching(true)

    const sanitizedFilters = clearEmpties(filters)

    const filterQueryParams = genQueryParams({
      ...sanitizedFilters,
      organisationId,
    })

    const response = await refetchBookings({
      params: {
        filter: filterQueryParams,
        include: genQueryParams({ location: true }),
        page,
        limit,
      },
    })

    setFetching(false)

    if (!response) return

    if (page === 0) {
      const newBookings = response?.results || []

      const sortedBookings = newBookings.sort(sortBy('startDate', 'DESC'))

      setBookings(sortedBookings)
      setHasMore(response?.hasMore || false)
      return
    }

    const sortedBookings = [...bookings, ...(response?.results || [])].sort(sortBy('startDate', 'DESC'))

    setBookings(sortedBookings)
    setHasMore(response?.hasMore || false)
  }, [page, filters])

  useDidUpdate(() => handleRefetchBookings(), [handleRefetchBookings])

  return [{ data: bookings, fetching, hasMore }, handleRefetchBookings]
}
