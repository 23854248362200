import DeleteIcon from '@mui/icons-material/Delete'
import { FieldArray, Form, useFormikContext } from 'formik'
import React from 'react'

import {
  Button,
  Divider,
  Flex,
  FormikSelectDropdown,
  FormikSelectDropdownProps,
  InputField,
  InputFieldProps,
  RowFields,
} from '../../../components'
import { genUUID } from '../../../utils'

const TariffInputField = (props: InputFieldProps<RecursivePartial<ITariff>>) =>
  InputField<RecursivePartial<ITariff>>(props)
const RateInputField = (props: InputFieldProps<RecursivePartial<ITariff['rates']>>) =>
  InputField<RecursivePartial<ITariff['rates']>>(props)
const TariffTypeSelectDropdown = (props: FormikSelectDropdownProps<ITariff['type']>) =>
  FormikSelectDropdown<ITariff['type']>(props)

interface TariffFormProps {
  onChange?: (data: RecursivePartial<ITariff>) => void
  tariff?: RecursivePartial<ITariff>
  isActive?: boolean
  errors?: any
}

export default function TariffForm({ tariff, isActive, onChange, errors }: TariffFormProps) {
  const formik = useFormikContext<RecursivePartial<ITariff>>()

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(tariff ?? {}, false)

    if (tariff?.rates?.length === 0 || tariff?.rates?.[0]?.label === undefined) {
      formik.setFieldValue(`rates[0]`, { id: genUUID() })
    }
    // eslint-disable-next-line
  }, [tariff, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit}>
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>DETAILS</strong>
      </h4>
      <Divider light />
      <br />
      <RowFields>
        <div>
          {isActive && (
            <TariffInputField autoFocus placeholderName="tariff" name="name" label="Name" error={errors.name} />
          )}
        </div>
        <div>
          <TariffTypeSelectDropdown
            placeholder="Select a type"
            nestedName="type"
            label="Type"
            error={errors?.type}
            multi={false}
            items={['regular', 'premium']?.map((type) => ({ label: type.toUpperCase(), value: type }))}
          />
        </div>
      </RowFields>
      <br />
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>RATES</strong>
      </h4>
      <Divider light />
      <br />
      <FieldArray
        name="rates"
        render={(arrayHelpers) => {
          const rates = formik.values.rates

          return (
            <div key={arrayHelpers.name}>
              {rates?.map((rate, index) => {
                return (
                  <div key={rate?.id}>
                    <RowFields>
                      <RateInputField
                        placeholder="Price tag"
                        nestedName={`rates[${index}].label`}
                        label="Label"
                        error={errors.rates?.[index]?.label}
                        type="text"
                        autoFocus={index > 0}
                      />
                      <RateInputField
                        placeholder="In minutes"
                        nestedName={`rates[${index}].duration`}
                        error={errors.rates?.[index]?.duration}
                        label="Duration"
                        type="number"
                      />
                      <RateInputField
                        placeholder="0.00 (GBP)"
                        nestedName={`rates[${index}].price`}
                        error={errors.rates?.[index]?.price}
                        label="Price"
                        type="number"
                      />
                      <Flex align="flex-end">
                        <Button
                          icon={<DeleteIcon fontSize="small" />}
                          style={{ mt: '8px', w: '52px', h: '50px', ml: 'auto' }}
                          variant="secondary"
                          disabled={rates.length === 1}
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </Flex>
                    </RowFields>
                    <br />
                  </div>
                )
              })}
              <Button
                text="+ Add rate"
                style={{ float: 'left' }}
                variant="secondary"
                onClick={() => {
                  formik.setFieldValue(`rates[${rates?.length}]`, { id: genUUID() })
                }}
              />
              <br />
              <br />
              <br />
            </div>
          )
        }}
      />
    </Form>
  )
}
