import Fade from '@mui/material/Fade'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

import logo from '../../assets/icons/logo.png'

import LoginForm from './LoginForm'
import * as S from './Login.styled'

const Background = styled.div`
  height: ${isMobile ? '155px' : '350px'};
  width: ${isMobile ? '335px' : '750px'};
  background-image: url(${logo});
  background-size: cover;
  opacity: 1;
  position: absolute;
  inset: 0;
  margin: auto;
`

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    position: 'absolute',
    inset: 0,
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#000',
    fontWeight: 400,
    fontSize: '3rem !important',
    letterSpacing: 0.92,
    marginBottom: 80,
    paddingBottom: 60,
    position: 'relative',
  },
  user: {
    color: '#000',
    fontWeight: 400,
    fontSize: 30,
    height: 86,
    letterSpacing: 0.92,
    position: 'relative',
  },
}))

export function Login() {
  const [backgroundAppear, setBackgroundAppear] = useState(false)
  const [loginAppear, setLoginAppear] = useState(true)
  const classes = useStyles()

  const handleOnSuccess = async () => {
    const promise = new Promise((resolve) => {
      setBackgroundAppear(true)
      setLoginAppear(false)

      setTimeout(() => {
        setBackgroundAppear(false)
      }, 1000)

      setTimeout(() => {
        resolve(true)
      }, 2000)
    })

    await Promise.resolve(promise)
  }

  return (
    <>
      <S.$RainbowContainer />
      <div>
        <Fade timeout={{ enter: 1000, exit: 1200 }} in={backgroundAppear}>
          <Background />
        </Fade>
        <div className={classes.paper}>
          <Fade timeout={{ enter: 500, exit: 1000 }} in={loginAppear}>
            <Typography component="h1" variant="h5" className={classes.title}>
              Login
            </Typography>
          </Fade>
          <Fade timeout={{ enter: 1300, exit: 1000 }} in={loginAppear}>
            <Typography component="h4" variant="body2" style={{ width: 300 }}>
              <LoginForm onSuccess={handleOnSuccess} />
            </Typography>
          </Fade>
        </div>
      </div>
    </>
  )
}
