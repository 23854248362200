import * as S from './Card.styled'

export interface CardProps {
  style?: SCProps<typeof S.$Container>
  children: Children
  withoutPadding?: boolean
}

export const Card = ({ style, children, withoutPadding }: CardProps) => {
  return (
    <S.$Container p={withoutPadding ? 0 : undefined} {...style}>
      {children}
    </S.$Container>
  )
}
