import { Form, useFormikContext } from 'formik'
import React from 'react'
import { Divider, FormikSelectDropdown, InputField, InputFieldProps } from 'src/components'

const CameraInputField = (props: InputFieldProps<Partial<ICamera>>) => InputField<Partial<ICamera>>(props)

interface CameraFormProps {
  onChange?: (data: Partial<ICamera>) => void
  camera?: Partial<ICamera>
  isActive?: boolean
  errors?: any
}

export default function CameraForm({ camera, onChange, isActive, errors }: CameraFormProps) {
  const formik = useFormikContext<Partial<ICamera>>()

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(camera ?? {}, false)
    // eslint-disable-next-line
  }, [location, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>DETAILS</strong>
      </h4>
      <Divider light />
      <br />
      <CameraInputField placeholder="The camera's name" name="name" label="Name" error={errors?.name} />
      <FormikSelectDropdown
        placeholder="Select the type"
        name="type"
        label="Type"
        multi={false}
        items={[
          { label: 'Entry', value: 'entry' },
          { label: 'Exit', value: 'exit' },
          { label: 'Both Ways', value: 'both_ways' },
        ]}
      />
      <CameraInputField placeholder="The camera's domain" name="domain" label="Domain" error={errors?.domain} />
      <CameraInputField placeholder="The camera's port" type="number" name="port" label="Port" error={errors?.port} />
      <FormikSelectDropdown
        placeholder="Select the camera's provider"
        name="provider"
        label="Provider"
        multi={false}
        items={[
          { label: 'Hikvision', value: 'hikvision' },
          { label: 'Mav', value: 'mav' },
        ]}
      />
      <FormikSelectDropdown
        placeholder="Select the camera's direction"
        name="direction"
        label="Direction"
        multi={false}
        items={[
          { label: 'Inwards', value: 'inwards' },
          { label: 'Outwards', value: 'outwards' },
        ]}
      />
    </Form>
  )
}
