import { Preflight, ThemeProvider } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter } from 'react-router-dom'

import { ErrorFallback } from './components'
import MainProvider from './context/MainProvider'
import { GlobalStyles } from './GlobalStyles'
import { SentryHelper } from './helpers'
import Routes from './routes'
import { theme } from './theme'
import { httpsRedirect } from './utils/httpsRedirect'

dayjs.extend(timezone)

const App = () => {
  React.useEffect(() => {
    httpsRedirect()
  }, [])

  return (
    <BrowserRouter>
      <MainProvider>
        <ThemeProvider theme={theme}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload()
            }}
          >
            <Preflight />
            <GlobalStyles />
            <Routes />
          </ErrorBoundary>
        </ThemeProvider>
      </MainProvider>
    </BrowserRouter>
  )
}

const AppWithErrorBoundary = SentryHelper.withErrorBoundary(App, { fallback: <p>an error has occurred</p> })

export default AppWithErrorBoundary
