import * as yup from 'yup'
export const BarrierSchema = yup.object().shape({
  name: yup.string().required('* Required'),
  locationId: yup.string().required('* Required'),
  cameraId: yup.string().required('* Required'),
  port: yup.number().required('* Required'),
  domain: yup.string().required('* Required'),
  channel: yup.number().required('* Required'),
  toggleChannel: yup.number().optional().nullable(),
  username: yup.string().optional().nullable(),
  password: yup.string().optional().nullable(),
})
