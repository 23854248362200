import React from 'react'

interface ConditionProps {
  when: any
  children: Children
  fallback?: Children
}

export const Condition = ({ when, children, fallback = null }: ConditionProps) => {
  return <>{when ? children : fallback}</>
}
