import TariffsIcon from '@mui/icons-material/LocalOffer'
import { x } from '@xstyled/styled-components'
import _ from 'lodash'
import React from 'react'
import { Badge, Button, Condition, EmptyState, Table, TableAction } from 'src/components'
import { useAuthContext } from 'src/context'
import { DeleteTariffModal, TariffModal } from 'src/screens/Tariffs/Modals'

interface LocationTariffsProps {
  tariffs?: ITariff[]
  onDataRefetch: () => void
}

export const LocationTariffs = ({ tariffs, onDataRefetch }: LocationTariffsProps) => {
  const { isAdmin, isAtLeastManager } = useAuthContext()

  const [deleteTariffModalOpen, setDeleteTariffModalOpen] = React.useState(false)
  const [tariffModalOpen, setTariffModalOpen] = React.useState<boolean>(false)

  const [tariffToDelete, setTariffToDelete] = React.useState<ITariff>()
  const [tariffToEdit, setTariffToEdit] = React.useState<ITariff>()

  const handleDelete = async (tariff: ITariff) => {
    setTariffToDelete(tariff)
    setDeleteTariffModalOpen(true)
  }

  const actions: TableAction<ITariff>[] = []

  if (isAtLeastManager)
    actions.push({
      label: 'Edit',
      onClick: (item) => {
        setTariffToEdit(item)
        setTariffModalOpen(true)
      },
      type: 'edit',
    })

  if (isAdmin) actions.push({ label: 'Delete', onClick: handleDelete, type: 'delete' })

  return (
    <div>
      <Condition when={isAdmin}>
        <x.div mt="10px" mb="20px" w="100%" display="flex">
          <Button text="+ Add tariff" variant="accent" onClick={() => setTariffModalOpen(true)} />
        </x.div>
      </Condition>
      {tariffs && tariffs.length === 0 && (
        <EmptyState
          title="There are no tariffs currently set up"
          subtitle="Tariffs are a great way for you to set and track prices on remote tariffs deployed in locations. Start by adding a new tariff. This will then appear here."
          icon={<TariffsIcon />}
          buttonProps={{
            text: 'Create a tariff',
            variant: 'accent',
            onClick: () => setTariffModalOpen(true),
            style: { h: '50px' },
          }}
        />
      )}
      {tariffs && tariffs.length > 0 && (
        <Table
          items={tariffs}
          headers={[
            { key: 'name' },
            {
              customKey: 'rates',
              align: 'center',
              format: (_, tariff?: ITariff) => String(tariff?.rates?.length),
            },
            {
              customKey: 'type',
              render: (_t, tariff?: ITariff) => (
                <Badge
                  text={_.capitalize(tariff?.type) as string}
                  variant={tariff?.type === 'regular' ? 'yellow' : 'purple'}
                />
              ),
            },
          ]}
          actions={isAtLeastManager && actions}
        />
      )}
      <TariffModal
        isOpen={tariffModalOpen}
        tariff={tariffToEdit}
        onSubmitSuccess={onDataRefetch}
        onClose={() => {
          setTariffModalOpen(false)
          setTariffToEdit(undefined)
          onDataRefetch()
        }}
      />
      <DeleteTariffModal
        tariff={tariffToDelete as ITariff}
        isOpen={deleteTariffModalOpen}
        onClose={() => setDeleteTariffModalOpen(false)}
        onDelete={onDataRefetch}
      />
    </div>
  )
}
