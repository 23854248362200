import React from 'react'

export const useUpdateEffect = (effect: React.EffectCallback, deps?: React.DependencyList | undefined) => {
  const didMount = React.useRef(false)

  React.useEffect(() => {
    if (didMount.current) effect()
    else didMount.current = true
    // eslint-disable-next-line
  }, deps)
}
