import { v4 } from 'uuid'

export const genUUID = (): string => v4()

export const genRandomNumber = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min

export const clearEmpties = (o) => {
  if (!o || Object.entries(o).length === 0) {
    return
  }

  const clone = { ...o }

  for (const [key, value] of Object.entries(o)) {
    if (value === null || value === undefined || value === '') {
      delete clone[key]
    }

    if (typeof value === 'object') {
      const temp = clearEmpties(value)
      if (!temp || Object.entries(temp).length === 0) delete clone[key]
    }
  }

  return clone
}

export function genQueryParams<T>(fields: { [Property in keyof Partial<T>]: boolean | any }): string {
  const cleanedFields = clearEmpties(fields)
  return encodeURIComponent(JSON.stringify(cleanedFields))
}
