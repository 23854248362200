import { SelectProps } from 'antd'

import { Error } from '../Error'
import { Label } from '../Label'

import * as S from './MultiSelect.styled'

type MultiSelectProps = {
  value?: string[]
  label: string | React.ReactNode
  error?: string
  onSelect: (value: string) => void
  placeholder?: string | React.ReactNode
  options: SelectProps<any>['options']
}

const filterOption = (input, option) => {
  // Filter by label
  return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
}

export const MultiSelect = ({ label, error, placeholder, value, options, onSelect }: MultiSelectProps) => {
  return (
    <S.$Container>
      <Label>{label}</Label>
      <S.$Select
        mode="multiple"
        allowClear
        placeholder={placeholder}
        options={options}
        value={value}
        filterOption={filterOption}
        onChange={(value) => onSelect(value)}
      />
      <Error visible={!!error}>{error}</Error>
    </S.$Container>
  )
}
