import styled from '@xstyled/styled-components'

export const $Trigger = styled.divBox`
  text-decoration: underline;
  color: ${'blue'};
  cursor: pointer;

  :hover {
    color: ${'blueDark'};
  }
`
