import PowerIcon from '@mui/icons-material/PowerSettingsNew'
import { DeviceAction } from 'src/typings/device'
import { Button } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

interface RebootPaymentDeviceButtonProps {
  deviceId: string
  deviceName: string
}

export const RebootPaymentDeviceButton = ({ deviceId, deviceName }: RebootPaymentDeviceButtonProps) => {
  const [{ fetching: rebooting }, reboot] = useAxios<{ action: DeviceAction }>({
    method: 'POST',
    url: BE_PATHS.DEVICE_ACTION(deviceId, 'reboot-payment-terminal'),
    timeout: 10000,
  })
  const toast = useToast()

  return (
    <Button
      icon={<PowerIcon fontSize="inherit" />}
      style={{
        marginLeft: 'auto',
        borderRadius: '50%',
        padding: 1,
        minWidth: '24px',
        w: '24px',
        maxWidth: '24px',
        minHeight: '24px',
        h: '24px',
        maxHeight: '24px',
        fontSize: '18px',
      }}
      variant="secondary"
      loading={rebooting}
      disabled={rebooting}
      loaderSize={24}
      onClick={async () => {
        try {
          await reboot()

          toast(`Device: ${deviceName} \nResult: Payment terminal reboot initiated`, {
            variant: 'success',
            style: { whiteSpace: 'pre-line' },
          })
        } catch (error: any) {
          const reason = error?.response?.data?.message || 'Unknown'

          toast(`Device: ${deviceName} \nResult: Failed to initiate rebooting payment terminal \nReason: ${reason}`, {
            variant: 'error',
            style: { whiteSpace: 'pre-line' },
          })
        }
      }}
    />
  )
}
