import { Breadcrumbs, Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import { useNavigate } from 'react-router-dom'

interface BreadcrumbItem {
  title: string
  href: string
}

export interface BreadcrumbProps {
  items?: BreadcrumbItem[]
}

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
  const navigate = useNavigate()

  return (
    <Breadcrumbs>
      {items?.map((item, idx) => (
        <Anchor key={idx} onClick={() => navigate(item.href)}>
          <x.span fontSize="14px" color="accent">
            {item.title}
          </x.span>
        </Anchor>
      ))}
    </Breadcrumbs>
  )
}
