import { Tag, TagProps } from 'src/components'

interface DeviceStatusTagProps extends Partial<TagProps> {
  label: string
  value: string
}

export const DeviceStatusTag = ({ label, value, ...rest }: DeviceStatusTagProps) => {
  return (
    <Tag
      {...rest}
      style={{ border: 'none', marginBottom: '5px', minWidth: '250px' }}
      text={
        <span>
          <strong>{label}: </strong>
          {value}
        </span>
      }
    />
  )
}
