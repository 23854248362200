import { Field, FieldProps } from 'formik'

import { Error } from '../Error'
import { InputText } from '../InputText'
import { Label } from '../Label'

import * as S from './InputField.styled'

export interface InputFieldProps<T> {
  placeholderName?: string
  placeholder?: string
  label?: string
  icon?: JSX.Element
  disabled?: boolean
  type?: string
  name?: keyof T | string
  nestedName?: string
  error?: boolean | string
  onChange?: (value: any) => void
  onBlur?: () => React.FocusEventHandler<HTMLInputElement>
  autoFocus?: boolean
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const getInputTextState = (hasError: boolean, hasValue: boolean) => {
  if (hasError) return 'error'
  if (!hasError && hasValue) return 'success'
  return 'default'
}

export default function InputField<T>({
  placeholderName,
  placeholder,
  label,
  icon,
  name,
  nestedName,
  type,
  onChange,
  disabled = false,
  autoFocus = false,
  error,
  inputProps,
}: InputFieldProps<T>) {
  return (
    <Field name={name || nestedName} type={type} placeholder={'Input'}>
      {({ field, meta }: FieldProps) => {
        const hasError = !!(meta.touched && meta.error)
        const hasValue = !!field.value

        return (
          <S.$Container>
            <Label variant={!hasError && hasValue ? 'success' : 'default'}>{label}</Label>
            <InputText
              autoFocus={autoFocus}
              disabled={disabled}
              icon={icon}
              inputProps={inputProps}
              name={field.name}
              key={field.name}
              value={field.value ?? ''}
              onBlur={field.onBlur}
              onChange={(e) => {
                field.onChange(e)
                onChange?.(e.target.value)
              }}
              placeholder={placeholder || `The ${placeholderName}'s ${label?.toLowerCase()}`}
              type={type}
              state={getInputTextState(hasError, hasValue)}
            />

            <Error visible={hasError}>{meta.error || error}</Error>
          </S.$Container>
        )
      }}
    </Field>
  )
}
