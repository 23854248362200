import { x } from '@xstyled/styled-components'

import { Button } from '../Button'

interface ErrorFallbackProps {
  error: any
  resetErrorBoundary: () => void
}

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  return (
    <x.div
      display="flex"
      w="100vw"
      h="100vh"
      flexDirection="column"
      role="alert"
      alignItems="center"
      justifyContent="center"
    >
      <x.div maxWidth="500px" display="flex" flexDirection="column" alignItems="center">
        <x.p m="0px">Something went wrong:</x.p>
        <pre>{error.message}</pre>
        <Button variant="accent" onClick={resetErrorBoundary} text="Try again" />
      </x.div>
    </x.div>
  )
}
