import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Table = styled.tableBox`
  table-layout: fixed;

  &[data-with-heading='true'] {
    tr:first-child {
      h4 {
        color: dark;
      }
    }
  }

  tr:not(:last-child) {
    border-bottom: 1px solid grayFaded;
  }

  th:first-child,
  td:first-child {
    padding-left: 24px;

    ${Styles.screens.small`
      paddding-left: unset;
		`}
  }

  tr,
  td,
  th {
    font-size: 12px;
    padding: 16px 8px;
  }

  ${Styles.screens.small`
    tr,
    td,
    th {
			padding: 15px;
    }
		th {
			text-transform: capitalize;
		}
	`}
`

export const $TableRow = styled.tr`
  border-top: 1px solid;
  border-color: grey12;
`

export const $TableSummaryRow = styled($TableRow)`
  border-top: 1px solid;
  border-color: grey12;
  background-color: dark;
  color: light;
`

export const $Heading = styled.divBox`
  padding: 24px 24px 8px 24px;
  font-size: 18px;
  font-weight: 600;
`

export const $ActionsContainer = styled.tdBox`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const $Action = styled.buttonBox`
  all: unset;

  cursor: pointer;

  &:focus,
  &:focus-visible {
    outline: none;
    path {
      fill: accent;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:last-child) {
    margin-right: 14px;
  }

  svg {
    font-size: 24px;
    display: flex;
    align-self: center;
    color: grayer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: dark;
    }
  }
`

export const $HeadingText = styled.divBox``

export const $Cell = styled.tdBox``
export const $CellValue = styled.divBox``
