import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData } from 'src/hooks'
import { genQueryParams, sortBy } from 'src/utils'

export const usePermits = ({
  searchTerm,
  page = 0,
  limit = 20,
}: {
  searchTerm?: string
  page?: number
  limit?: number
}): [UseAxiosData<IPermit[]>, () => Promise<void>] => {
  const { organisation } = useOrganisationContext()
  const [permits, setPermits] = React.useState<IPermit[]>([])
  const [hasMore, setHasMore] = React.useState<boolean>(false)

  const [{ data: permitsData, fetching }, refetchPermits] = useAxios<Paginated<IPermit>>({
    url: BE_PATHS.PERMITS,
    params: {
      filter: genQueryParams({ organisationId: organisation?.id }),
      page,
      limit,
    },
    pause: !organisation,
    runOnInit: false,
  })

  React.useEffect(() => {
    if (!permitsData) return
    if (permitsData?.page !== 0) return

    setPermits(permitsData?.results || [])
    setHasMore(permitsData?.hasMore || false)
  }, [permitsData])

  const handleRefetchPermits = React.useCallback(async () => {
    const response = await refetchPermits({
      params: {
        filter: genQueryParams({
          organisationId: organisation?.id,
          OR: [{ vrm: { startsWith: searchTerm?.toUpperCase() } }, { reference: { startsWith: searchTerm } }],
        }),
        page,
        limit,
      },
    })

    if (!response) return

    if (page === 0) {
      const newPermits = response?.results || []

      const sortedPermits = newPermits.sort(sortBy('createdAt', 'DESC'))

      setPermits(sortedPermits)
      setHasMore(response?.hasMore || false)
      return
    }

    const sortedPermits = [...permits, ...(response?.results || [])].sort(sortBy('createdAt', 'DESC'))

    setPermits(sortedPermits)
    setHasMore(response?.hasMore || false)

    // eslint-disable-next-line
  }, [refetchPermits, page, organisation, searchTerm])

  React.useEffect(() => {
    handleRefetchPermits()

    //eslint-disable-next-line
  }, [page, limit, organisation, searchTerm])

  return [{ data: permits, fetching, hasMore }, handleRefetchPermits]
}
