import styled, { system, SystemProps } from '@xstyled/styled-components'
import * as RadixPopover from '@radix-ui/react-popover'

export const Trigger = styled(RadixPopover.Trigger).attrs(() => ({ as: RadixPopover.Trigger, tabIndex: -1 }))`
  background-color: transparent;
`

export const Content = styled(RadixPopover.Content)<RadixPopover.PopperContentProps & SystemProps>`
  z-index: 10;
  background-color: ${'white'};
  padding: 10px;
  border-radius: 5px;
  box-shadow: light;

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  transform-origin: var(--radix-popover-content-transform-origin);
  animation: scaleIn 0.09s ease-out;

  ${system}
`
