import { Tooltip } from '@mantine/core'
import {
  DeviceStatusTag,
  OpenRelayButton,
  PingDeviceButton,
  RebootPaymentDeviceButton,
} from 'src/screens/Devices/components'

const getPeripheralType = (type: string) => {
  if (type === 'printer') return 'Printer'
  if (type === 'relay') return 'Relay'
  if (type === 'payment-device') return 'Payment Device'
  return 'Other'
}

const mapLogLevelToVariant = (logLevel?: ILog['level']) => {
  if (logLevel === 'info') return 'green'
  if (logLevel === 'warn') return 'yellow'
  if (logLevel === 'error') return 'red'
  return 'dark'
}

interface DeviceTagProps {
  deviceId: string
  deviceName: string
  peripherals: PeripheralHealthData[]
  error?: string
  isHealthy: boolean
  kioskVersion?: string
  peripheralsOk: boolean
  kioskStatus: string
}

export default function DeviceTag({
  deviceId,
  deviceName,
  peripherals,
  kioskVersion,
  kioskStatus,
  isHealthy,
}: DeviceTagProps) {
  if (!isHealthy) {
    return (
      <Tooltip label="Ping kiosk" position="right" style={{ marginLeft: 'auto' }}>
        <DeviceStatusTag
          key={deviceId}
          label={kioskVersion ? `Kiosk (${kioskVersion})` : 'Kiosk'}
          value="Unknown"
          variant="red"
          button={<PingDeviceButton deviceName={deviceName} deviceId={deviceId} />}
        />
      </Tooltip>
    )
  }

  return (
    <>
      <Tooltip label="Ping kiosk" position="right" style={{ marginLeft: 'auto' }}>
        <DeviceStatusTag
          key={deviceId}
          label={kioskVersion ? `Kiosk (${kioskVersion})` : 'Kiosk'}
          value={kioskStatus}
          variant="green"
          button={<PingDeviceButton deviceName={deviceName} deviceId={deviceId} />}
        />
      </Tooltip>
      {peripherals?.map((p: PeripheralHealthData, idx: number) => {
        return (
          <DeviceStatusTag
            key={`${p.type}-${idx}`}
            label={getPeripheralType(p.type)}
            value={p.type !== 'relay' ? p.message || '' : 'Performing as expected'}
            variant={p.type !== 'relay' ? mapLogLevelToVariant(p.level) : 'green'}
            button={
              (p.type === 'payment-device' && (
                <Tooltip label="Reboot payment terminal" position="right" style={{ marginLeft: 'auto' }}>
                  <RebootPaymentDeviceButton deviceId={deviceId} deviceName={deviceName} />
                </Tooltip>
              )) ||
              (p.type === 'relay' && (
                <Tooltip label="Open relay" position="right" style={{ marginLeft: 'auto' }}>
                  <OpenRelayButton deviceName={deviceName} deviceId={deviceId} />
                </Tooltip>
              ))
            }
            style={{ marginTop: '10px' }}
          />
        )
      })}
    </>
  )
}
