import { Switch } from '@mantine/core'
import { Field, FieldProps } from 'formik'
import _ from 'lodash'
import React from 'react'

import * as S from './ToggleField.styled'

export interface ToggleFieldProps {
  name?: string
  label?: string
  disabled?: boolean
  checked?: boolean
  onChange?: (checked: boolean) => void
}

export const ToggleField = ({
  label,
  name,
  checked: overrideChecked,
  onChange,
  disabled = false,
}: ToggleFieldProps) => {
  const [isChecked, setIsChecked] = React.useState<boolean | undefined>(overrideChecked)

  React.useEffect(() => {
    setIsChecked(overrideChecked)
  }, [overrideChecked])

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const checked = !!field.value && !_.isObject(field.value)

        return (
          <S.$Container>
            <Switch
              label={label}
              checked={isChecked || checked}
              disabled={disabled}
              onChange={(event) => {
                onChange?.(event.currentTarget.checked)
                if (!name) return
                form.setFieldValue(name, event.currentTarget.checked)
              }}
            />
          </S.$Container>
        )
      }}
    </Field>
  )
}
