import React from 'react'
import { Condition, EmptyState } from 'src/components'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useDebounce } from 'src/hooks'
import * as TablerIcons from 'tabler-icons-react'

import { BarriersTable } from './components'
import { useBarriers } from './hooks'

export function Barriers() {
  const { fetchingOrgData, organisation } = useOrganisationContext()

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)

  const [{ data: barriers = [], fetching: fetchingBarriers }, refetchBarriers] = useBarriers({
    searchTerm: debouncedSearchTerm,
  })

  React.useEffect(() => {
    if (!organisation?.id) return

    refetchBarriers()
  }, [organisation?.id])

  return (
    <Page
      searchPlaceholder="Search barriers by name"
      loading={!organisation && (fetchingOrgData || fetchingBarriers)}
      onSearch={setSearchTerm}
    >
      <Shell title="Barriers" subtitle="Release barriers" loading={!barriers && fetchingOrgData}>
        {!fetchingOrgData && barriers && barriers.length === 0 && (
          <EmptyState
            title="No barriers added yet"
            subtitle="Start by adding a new barrier by location. These will then appear here."
            icon={<TablerIcons.BarrierBlock size="60" />}
          />
        )}

        <Condition when={barriers.length > 0}>
          <BarriersTable barriers={barriers} loading={fetchingBarriers} />
        </Condition>
      </Shell>
    </Page>
  )
}
