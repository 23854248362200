import { useDidUpdate } from '@mantine/hooks'
import { x } from '@xstyled/styled-components'
import _ from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment'
import React from 'react'
import { Badge, Button, Condition, Modal, MultiSelect, RangePicker, Row, SelectDropdown, Tag } from 'src/components'
import { BE_PATHS } from 'src/constants'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { useAxios } from 'src/hooks'
import { ProviderIcon } from 'src/screens/shared/components'
import { Mapper, genQueryParams } from 'src/utils'

import { mapProviderToSource } from './providerMapper'

const TransactionType = ({ type }: { type: ITransaction['type'] }) => {
  return (
    <Tag
      variant={Mapper.mapTransactionTypeToColor(type)}
      style={{
        h: '24px',
        fontWeight: '500',
        textTransform: 'none',
        border: 'none',
        bg: 'transparent',
        p: 0,
      }}
    >
      {Mapper.mapTransactionTypeToLabel(type)}
    </Tag>
  )
}

export const TransactionSource = ({ provider }: { provider: IProviderSlug }) => {
  return (
    <Row>
      <ProviderIcon provider={provider} size={16} />
      <x.span ml="8px" mt="4px">
        {mapProviderToSource(provider)}
      </x.span>
    </Row>
  )
}

const getFiltersCount = (filters: TransactionFilter) => _.size(Object.values(filters).filter(Boolean))

type TransactionFiltersProps = {
  initialValues?: TransactionFilter
  maxDaysRange?: number
  filterSet?: TransactionFilterTypes[]
  onUpdate?: (filtersUpdatedAt: Date, filters: TransactionFilter) => void
  onReset?: (filtersUpdatedAt: Date, filters: TransactionFilter) => void
}

export const TransactionFilters = ({
  initialValues = {},
  maxDaysRange,
  filterSet,
  onUpdate,
  onReset,
}: TransactionFiltersProps) => {
  const { organisations, organisation } = useOrganisationContext()
  const { isAdmin } = useAuthContext()
  const [filtersCount, setFiltersCount] = React.useState<number>(getFiltersCount(initialValues))
  const [filtersModalOpen, setFiltersModalOpen] = React.useState<boolean>(false)
  const [filters, setFilters] = React.useState<TransactionFilter>({
    locationId: undefined,
    timestamp: undefined,
    status: undefined,
    type: undefined,
    providerId: undefined,
    deviceId: undefined,
    ...initialValues,
  })

  const selectedOrg: Organisation | undefined =
    organisations?.find(({ id }) => id === (filters?.organisationId ?? organisation?.id)) || organisation

  const [{ data: providers }] = useAxios<IProvider[]>({
    url: BE_PATHS.PROVIDERS,
    params: { filter: genQueryParams({ type: 'PAYMENT' }) },
  })

  const resetFilters = () => {
    const defaultFilters = {
      locationId: undefined,
      timestamp: undefined,
      type: undefined,
      status: undefined,
      providerId: undefined,
      ...initialValues,
    }

    setFilters(defaultFilters)
    onReset?.(new Date(), defaultFilters)
  }

  const locationsByOrganisation = selectedOrg?.locations?.map((location) => ({
    value: location.id,
    label: location.name,
  }))

  const hasLocationSelected = filters?.locationId && filters?.locationId?.in?.length > 0

  const devicesByLocation = selectedOrg?.devices
    ?.filter((device) => {
      const includesDeviceLocationId = filters?.locationId?.in?.includes(device.locationId)

      return device.organisationId === selectedOrg?.id && (!hasLocationSelected || includesDeviceLocationId)
    })
    ?.map((device) => ({ value: device.id, label: device.name }))

  return (
    <>
      <Button
        variant="primary"
        text={
          filtersCount > 0 ? (
            <x.div display="flex" alignItems="center">
              <x.span mr="5px">Filters</x.span>
              <Badge text={String(filtersCount)} variant="light" style={{ borderRadius: 5, padding: '2px 5px' }} />
            </x.div>
          ) : (
            'Filters'
          )
        }
        onClick={() => setFiltersModalOpen(true)}
      />
      <Modal
        isOpen={filtersModalOpen}
        title="Filters"
        onClose={() => setFiltersModalOpen(false)}
        confirmText="Apply"
        cancelText="Go back"
        variant="primary"
        onConfirm={() => {
          setFiltersCount(getFiltersCount(filters))
          setFiltersModalOpen(false)

          onUpdate?.(new Date(), filters)
        }}
        cardStyle={{ w: 'unset', backgroundColor: 'white' }}
        body={
          <x.div display="flex" flexDirection="column" minWidth="400px" maxW="1000px">
            <Condition when={isAdmin && filterSet?.includes('organisationId')}>
              <SelectDropdown
                label="Organisation"
                value={filters?.organisationId || organisation?.id}
                placeholder="Select Organisation"
                items={organisations?.map((org) => ({ value: org.id, label: org.name }))}
                onSelect={(selectedItems) => {
                  setFilters({
                    ...filters,
                    organisationId: selectedItems?.[0]?.value,
                    locationId: undefined,
                    deviceId: undefined,
                  })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('locationId')}>
              <MultiSelect
                label="Location"
                value={filters?.locationId?.in}
                placeholder="Select location"
                options={locationsByOrganisation}
                onSelect={(locationIds) => {
                  setFilters({ ...filters, locationId: { in: locationIds }, deviceId: undefined })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('deviceId') && devicesByLocation?.length}>
              <SelectDropdown
                label="Device"
                value={filters?.deviceId}
                placeholder="Select device"
                items={devicesByLocation}
                onSelect={(selectedItems) => {
                  const selectedItem = selectedItems?.[0]
                  setFilters({ ...filters, deviceId: selectedItem?.value })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('type')}>
              <MultiSelect
                label="Transaction type"
                value={filters?.type?.in}
                placeholder="Select type"
                options={[
                  { value: 'CHARGE', label: <TransactionType type="CHARGE" /> },
                  { value: 'CAPTURE', label: <TransactionType type="CAPTURE" /> },
                  { value: 'VOID', label: <TransactionType type="VOID" /> },
                  { value: 'REFUND', label: <TransactionType type="REFUND" /> },
                  { value: 'PREAUTH', label: <TransactionType type="PREAUTH" /> },
                  { value: 'AUTH', label: <TransactionType type="AUTH" /> },
                ]}
                onSelect={(types) => {
                  setFilters({ ...filters, type: { in: types } })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('status')}>
              <SelectDropdown
                label="Transaction status"
                value={filters?.status}
                placeholder="Select status"
                items={[
                  { value: 'ALL', label: 'All' },
                  { value: 'SUCCESSFUL', label: 'Successful' },
                  { value: 'PENDING', label: 'Pending' },
                  { value: 'CANCELLED', label: 'Cancelled' },
                  { value: 'DECLINED', label: 'Declined' },
                  { value: 'FAILED', label: 'Failed' },
                ]}
                onSelect={(selectedItems) => {
                  const selectedItem = selectedItems?.[0]
                  if (selectedItem?.value === 'ALL') {
                    setFilters({ ...(filters as TransactionFilter), status: undefined })
                    return
                  }
                  setFilters({ ...filters, status: selectedItem?.value })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('providerId')}>
              <SelectDropdown
                label="Transaction source"
                value={filters?.providerId}
                placeholder="Select source"
                items={providers?.map((provider) => ({
                  label: mapProviderToSource(provider.slug),
                  value: provider.id,
                  labelDisplay: <TransactionSource provider={provider.slug} />,
                }))}
                onSelect={(selectedItems) => {
                  const selectedItem = selectedItems?.[0]
                  setFilters({ ...filters, providerId: selectedItem?.value })
                }}
              />
            </Condition>
            <Condition when={filterSet?.includes('timestamp')}>
              <DateRangePicker
                initialValue={
                  filters?.timestamp ? [moment(filters?.timestamp?.gt), moment(filters?.timestamp?.lt)] : null
                }
                onChange={({ startDate, endDate }) => {
                  setFilters({
                    ...filters,
                    timestamp: { gt: startDate?.startOf('day').toISOString(), lt: endDate?.endOf('day').toISOString() },
                  })
                }}
                maxRange={maxDaysRange}
              />
            </Condition>
            <br />
            <Button variant="destructive" text="Reset all filters" onClick={resetFilters} />
          </x.div>
        }
      />
    </>
  )
}

type RangeValue = [Moment | null, Moment | null] | null

export const DateRangePicker = ({
  initialValue,
  onChange,
  maxRange,
}: {
  initialValue: RangeValue
  maxRange?: number
  onChange: ({ startDate, endDate }: { startDate?: Moment | null; endDate?: Moment | null }) => void
}) => {
  const [datesRange, setDatesRange] = React.useState<RangeValue>(null)
  const [value, setValue] = React.useState<RangeValue>(initialValue)
  const [temporaryValue, setTemporaryValue] = React.useState<RangeValue>(null)

  const shouldDisableDate = (current: Moment) => {
    if (!datesRange || maxRange === undefined) return false

    const tooLate = datesRange[0] && current.diff(datesRange[0], 'days') > maxRange
    const tooEarly = datesRange[1] && datesRange[1].diff(current, 'days') > maxRange

    return !!tooEarly || !!tooLate || current > moment().endOf('day')
  }

  const handleOnChange = (range: RangeValue) => {
    setValue(range)
    onChange({ startDate: range?.[0], endDate: range?.[1] })
  }

  const handleOnToggle = (open: boolean) => {
    if (open) {
      setTemporaryValue([null, null])
      setDatesRange([null, null])
    } else {
      setTemporaryValue(null)
    }
  }

  useDidUpdate(() => setValue(initialValue), [initialValue])

  return (
    <RangePicker
      label={`Date range (max ${maxRange} days)`}
      value={temporaryValue || value}
      allowEmpty={[false, false]}
      allowClear={false}
      disabledDate={shouldDisableDate}
      onChange={(range) => handleOnChange(range)}
      onCalendarChange={(val) => setDatesRange(val)}
      onOpenChange={handleOnToggle}
    />
  )
}
