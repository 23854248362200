import { PropsWithChildren } from 'react'
import { Badge, TagColor } from 'src/components'

interface SyncBadgeProps extends PropsWithChildren<{ variant: TagColor }> {}

export const SyncBadge = ({ variant, children }: SyncBadgeProps) => (
  <Badge style={{ minWidth: 110 }} variant={variant}>
    {children}
  </Badge>
)
