import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'
import { Modal } from 'src/components'

interface Props {
  isOpen: boolean
  camera?: ICamera
  locationId: string
  onDelete: (barrier: Partial<ICamera>) => void
  onClose: () => void
}

export const DeleteCameraModal = ({ isOpen, camera, locationId, onDelete, onClose }: Props) => {
  const [, deleteCamera] = useAxios<ICamera>({
    method: 'DELETE',
    url: `${BE_PATHS.LOCATION_CAMERAS(locationId)}/${camera?.id}`,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!camera) return
    await deleteCamera()
    onDelete(camera)
    onClose()
  }, [camera, deleteCamera, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The camera with name <strong>{camera?.name || 'This item'}</strong> will be permanently deleted. This action
          is irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
