import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Modal } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import { BarrierSchema } from './Barrier.schema'
import BarrierForm from './BarrierForm'

interface BarrierModalProps {
  isOpen: boolean
  barrier?: Partial<IBarrier>
  cameras?: Partial<ICamera>[]
  locationId: string
  onSubmitSuccess: (device: IBarrier) => void
  onClose: () => void
}

export const BarrierModal = ({ isOpen, barrier, cameras, locationId, onSubmitSuccess, onClose }: BarrierModalProps) => {
  const toast = useToast()
  const [, submitData] = useAxios<Partial<IBarrier>>({
    method: barrier?.name ? 'PUT' : 'POST',
    url: barrier?.id
      ? `${BE_PATHS.LOCATION_BARRIERS(locationId)}/${barrier?.id}`
      : BE_PATHS.LOCATION_BARRIERS(locationId),
  })

  const [formData, setFormData] = React.useState<Partial<IBarrier>>()

  const initialValues = {
    channel: 1,
    locationId,
    ...barrier,
  } as Partial<IBarrier>

  return (
    <Formik initialValues={initialValues} validationSchema={BarrierSchema} onSubmit={() => {}}>
      {({ errors, validateForm, setTouched }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 750 }}
            title={!barrier ? 'New barrier' : 'Edit barrier'}
            body={
              <BarrierForm
                barrier={barrier || initialValues}
                cameras={cameras}
                onChange={setFormData}
                isActive={isOpen}
                errors={errors}
              />
            }
            confirmText={!barrier ? 'Create' : 'Save'}
            variant="accent"
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                setTouched(Object.keys(validationErrors).reduce((acc, key) => ({ ...acc, [key]: true }), {}) as any)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newBarrier = await submitData({ body: _.omit(formData, 'id') })
                newBarrier && onSubmitSuccess(newBarrier as IBarrier)
                toast(`${formData?.name || 'Barrier'} has been saved`, { variant: 'success' })
                setFormData(undefined)
                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
