import { x } from '@xstyled/styled-components'
import { Field, FieldProps } from 'formik'

import { SelectDropdown, SelectDropdownItem } from './SelectDropdown'

export interface FormikSelectDropdownProps<T> {
  placeholderName?: string
  placeholder?: string
  label?: string
  icon?: JSX.Element
  type?: string
  disabled?: boolean
  multi?: boolean
  items?: SelectDropdownItem[]
  name?: keyof T | string
  nestedName?: string
  error?: string
  onChange?: (values?: SelectDropdownItem[]) => void
  onBlur?: () => React.FocusEventHandler<HTMLSelectElement>
}

export function FormikSelectDropdown<T>({
  placeholder,
  label,
  items,
  name,
  nestedName,
  multi = false,
  type,
  onChange,
  disabled = false,
  error,
}: FormikSelectDropdownProps<T>) {
  return (
    <Field name={name || nestedName} type={type} placeholder={'Input'}>
      {({ field, meta, form }: FieldProps) => {
        const hasError = !!(meta.touched && meta.error)

        return (
          <x.div w="100%" position="relative">
            <SelectDropdown
              disabled={disabled}
              placeholder={placeholder}
              multi={multi}
              label={label}
              items={items}
              error={hasError ? error || meta.error : undefined}
              onSelect={(selectedItems) => {
                const values = selectedItems?.map((item) => item.value)
                const finalValues = multi ? values : values?.[0]
                form.setFieldValue((name || nestedName) as string, finalValues, true)
                onChange?.(selectedItems)
              }}
              onBlur={() => form.setFieldTouched((name || nestedName) as string, true, true)}
              value={field.value}
            />
          </x.div>
        )
      }}
    </Field>
  )
}
