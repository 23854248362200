import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

export const getHealthyDevicesByHeartbeats = (devices: IDevice[], heartbeats: IDeviceHeartbeat[] = []) => {
  const healthyDeviceIds = heartbeats
    ?.filter(({ level, data }) => {
      const { alio, printer } = data ?? {}

      const hasAlio = !!alio
      const hasPrinter = !!printer

      const isKioskHealthy = level === 'info'
      const isAlioHealthy = hasAlio ? alio.level === 'info' : true
      const isPrinterHealthy = hasPrinter ? printer.level === 'info' : true

      return isKioskHealthy && isAlioHealthy && isPrinterHealthy
    })
    ?.map(({ deviceId }) => deviceId)

  const healthyDevices = devices?.filter(({ id }) => healthyDeviceIds?.includes(id))

  return healthyDevices
}
