import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

import { Modal } from '../../../components'

interface Props {
  isOpen: boolean
  tariff: ITariff
  onDelete: (tariff: Partial<ITariff>) => void
  onClose: () => void
}

export const DeleteTariffModal = ({ isOpen, tariff, onDelete, onClose }: Props) => {
  const [, deleteTariff] = useAxios<ITariff>({
    method: 'DELETE',
    url: `${BE_PATHS.LOCATIONS}/${tariff?.locationId}/tariffs/${tariff?.id}`,
  })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!tariff) return
    await deleteTariff()
    onDelete(tariff)
    onClose()
  }, [tariff, deleteTariff, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The tariff with name <strong>{tariff?.name || 'This item'}</strong> will be permanently deleted. This action
          is irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
