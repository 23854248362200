import * as yup from 'yup'

export const LocationSettingsSchema = yup.object().shape({
  name: yup.string().required('* Required'),
  address: yup.object().shape({
    city: yup.string().required('* Required'),
    street: yup.string().required('* Required'),
    number: yup.string().optional(),
    postcode: yup.string().required('* Required'),
  }),
  code: yup.string().required('* Required'),
})
