import { Tooltip } from '@mantine/core'
import { Button } from 'src/components'
import { BarrierBlock, BarrierBlockOff } from 'tabler-icons-react'

interface ToggleBarrierButtonProps {
  disabled?: boolean
  loading?: boolean
  onToggle: () => void
  type: 'ON' | 'OFF'
}
export const ToggleBarrierButton = ({ disabled, loading, onToggle, type }: ToggleBarrierButtonProps) => {
  const tooltipLabel = `${type === 'ON' ? 'Open' : 'Close'} barrier`

  const Icon = type === 'ON' ? BarrierBlock : BarrierBlockOff

  return (
    <Tooltip label={tooltipLabel} position="top" style={{ paddingLeft: '4px' }}>
      <Button
        icon={<Icon width={14} />}
        variant="secondary"
        loading={loading}
        disabled={disabled}
        onClick={onToggle}
        style={{ minHeight: '42px', maxHeight: '42px', minWidth: '42px', maxWidth: '42px' }}
      />
    </Tooltip>
  )
}
