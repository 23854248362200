import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 12px;
  width: 100%;
`

export const $DetailsContainer = styled.divBox`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

export const $Title = styled.divBox`
  font-size: 14px;
  font-weight: 600;
  color: grey100;
`

export const $Heading = styled.divBox`
  font-size: 14px;
  font-weight: 500;
  color: grey64;
`
