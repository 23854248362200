export const colors = {
  white: '#ffffff',

  grey2: '#f8f8f8',
  grey4: '#f1f1f1',
  grey8: '#eaeaea',
  grey12: '#e1e0e0',
  grey16: '#d8d8d8',
  grey24: '#adacac',
  grey48: '#929292',
  grey64: '#646464',
  grey82: '#3a3939',
  grey100: '#202121',

  yellow: '#fece0d',
  yellowDark: '#b49000',
  yellowDarker: '#886d00',
  orangeDim: '#da7c0259',
  brownDim: '#3f240152',
  brown: '#3f2401',
  greenFaded: '#0ec8361a',
  greenDim: '#0ec8367a',
  green: '#0ec837',
  greenDark: '#06a328',
  greenDarker: '#017c1b',
  lightGone: '#f6f6f60a',
  lightFaded: '#f6f6f612',
  lightDimmer: '#ffffff31',
  lightDim: '#ffffffb0',
  light: '#f6f6f6',
  lightGray: '#e2e2e2',
  greyFaded: '#c5c5c538',
  greyDim: '#c5c5c5a1',

  grey: '#c5c5c5',
  greyer: '#707070',
  darkGray: '#595858',
  darkerGray: '#404040',
  darkFaded: '#2f2f2fd5',
  darkDim: '#2f2f2f',
  dark: '#2f2f2f',
  darkerDim: '#141414ec',
  darker: '#141414',
  blueFaded: '#0095ff1e',
  blueDim: '#0095ff2e',
  blue: '#0094ff',
  blueDark: '#006bb8',
  blueDarker: '#00508a',
  purpleFaded: '#6f00ff18',
  purpleDim: '#6f00ff28',
  purple: '#7000ff',
  purpleDark: '#2a2a39',
  purpleDarker: '#31333f',
  purpleDarkDim: '#4144549f',
  purpleDarkFaded: '#4144541e',
  purpleDarkGone: '#41445412',
  dangerDim: '#eb092f55',
  magentaDim: '#e8095455',
  magenta: '#e80954',
  orange: '#ff9d00',
  danger: '#f04444',
  dangerDark: '#c50024',
  dangerDarker: '#96001b',
  warning: '#f6cf2a',
  success: '#02b848',
  teal: '#02b88d',

  tealPale: '#b4f4df',
  tealText: '#33755d',
  yellowPale: '#fef2c7',
  yellowText: '#a96738',
  redPale: '#fde2e1',
  redText: '#c85957',
  greenPale: '#e1f3e4',
  greenText: '#119e30',
  orangePale: '#fae7dc',
  orangeText: '#f0832a',

  secondaryBoder: '#e2e2e2',

  primary: '#f6f6f6',
  primaryHover: '#ededed',
  secondary: '#696969',
  secondaryHover: '#565656',
  accent: '#02b88d',
  accentHover: '#019e79',
  accentFaded: '#02b88d22',
}
