import React from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthContext, useOrganisationContext } from '../../context'
import { useClickOutside } from '../../hooks'
import { OrganisationSwitcher } from '../OrganisationSwitcher'
import { ProfileDropdown } from '../ProfileDropdown'
import { Tab, TabProps } from '../Tab'

import * as S from './Sidebar.styled'

interface SidebarProps {
  tabs: TabProps[]
  isOpen: boolean
  onClose: () => void
}

export default function Sidebar({ tabs, isOpen = false, onClose }: SidebarProps) {
  const { user } = useAuthContext()

  const { organisation } = useOrganisationContext()

  const [profileDropdownOpen, setProfileDropdownOpen] = React.useState(false)
  const location = useLocation()

  const { ref, isClickOutside, resetIsClickOutside } = useClickOutside()

  React.useEffect(() => {
    if (isClickOutside) {
      resetIsClickOutside()
      onClose()
    }
    // eslint-disable-next-line
  }, [isOpen, isClickOutside, resetIsClickOutside])

  const orgName = organisation?.name

  React.useEffect(() => {
    if (orgName) {
      localStorage.setItem('org.name', orgName as string)
    }
  }, [orgName, user])

  const isActive = (path: string) => {
    return location.pathname.split('/')[1] === path
  }

  return (
    <S.$Wrapper data-open={isOpen} ref={ref}>
      <S.$Container>
        <S.$TopArea>
          <OrganisationSwitcher />
        </S.$TopArea>
        <S.$CenterArea>
          {tabs.map(
            ({ text, icon, badgeNum, onClick, visible = true, basePath }) =>
              visible && (
                <Tab
                  key={`tab-${text?.toLowerCase()}`}
                  text={text}
                  icon={icon}
                  badgeNum={badgeNum}
                  onClick={onClick}
                  active={isActive(basePath)}
                  basePath={basePath}
                />
              ),
          )}
        </S.$CenterArea>
        <S.$BottomArea>
          <S.$LastTab onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}>
            <ProfileDropdown isOpen={profileDropdownOpen} onClose={() => setProfileDropdownOpen(false)} />
          </S.$LastTab>
        </S.$BottomArea>
      </S.$Container>
    </S.$Wrapper>
  )
}
