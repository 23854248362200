import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Wrapper = styled.divBox`
  background: transparent;
  height: calc(100vh - 70px);
  padding: 10px 30px;
  padding-bottom: 50px;
  width: 100%;
  overflow-y: auto;
  z-index: 1;

  ${Styles.screens.small`
		padding: 10px 20px;
	`}
`

export const $Container = styled.divBox`
  margin: auto;
`

export const $Loader = styled.divBox`
  width: 100%;
`

export const $Heading = styled.divBox`
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: center;

  ${Styles.screens.small`
		margin-bottom: 8px;
	`}
`

export const $HeadingLeft = styled.divBox`
  display: flex;
  flex-direction: column;
`

export const $HeadingRight = styled.divBox`
  display: flex;
  flex-direction: row;
  float: right;
`

export const $Title = styled.divBox`
  font-weight: 600;
  margin: 0 0 10px 0;
  color: dark;

  ${Styles.screens.small`
		margin: 0;
	`}
`

export const $Subtitle = styled.divBox`
  margin: 0 0 10px 0;
  font-weight: 600;
  font-size: 14px;
  color: lightGray;
`
