import { SnackbarProvider } from 'notistack'
import { createRef } from 'react'

import { Button } from '../components'

import { AuthProvider } from './AuthContext'
import { SidebarProvider } from './SidebarContext'
import { ClientCacheProvider } from './ClientCache'
import { OrganisationProvider } from './OrganisationContext'

export default function MainProvider({ children }: { children: Children }) {
  const notistackRef = createRef() as any
  const onClickDismiss = (key: any) => () => {
    notistackRef?.current.closeSnackbar(key)
  }

  return (
    <ClientCacheProvider>
      <AuthProvider>
        <OrganisationProvider>
          <SidebarProvider>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              className="notistack"
              classes={{
                variantSuccess: 'notistack-success',
                variantError: 'notistack-error',
                variantWarning: 'notistack-warning',
                variantInfo: 'notistack-info',
                root: 'notistack-root',
              }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              action={(key) => <Button text="Dismiss" variant="primary" onClick={onClickDismiss(key)} />}
            >
              {children}
            </SnackbarProvider>
          </SidebarProvider>
        </OrganisationProvider>
      </AuthProvider>
    </ClientCacheProvider>
  )
}
