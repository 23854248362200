import * as S from './Text.styled'

type TextProps = {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  children?: Children
} & SCProps<typeof S.$Text>

export const Text = ({ type, children, ...rest }: TextProps) => {
  return (
    <S.$Text data-type={type} as={type} {...rest}>
      {children}
    </S.$Text>
  )
}
