import axios from 'axios'
import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { BE_PATHS, FE_PATHS } from 'src/constants/paths'
import { SentryHelper } from 'src/helpers'

import Token from '../utils/Token'

interface AuthProviderResult {
  isAdmin: boolean | undefined
  isManager: boolean
  isAtLeastManager: boolean
  user: Undefinable<LoggedInUser>
  login: (token: string, onSuccess: (user: LoggedInUser) => void) => void
  logout: () => void
}

const AuthContext = React.createContext<AuthProviderResult>({} as any)

interface AuthProviderProps {
  children: Children
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = React.useState<Undefinable<LoggedInUser>>()
  const [isAdmin, setIsAdmin] = React.useState<boolean | undefined>(false)
  const [isManager, setIsManager] = React.useState<boolean>(false)

  const isAtLeastManager = isAdmin || isManager

  const navigate = useNavigate()
  const location = useLocation()

  const sendToLoginPage = React.useCallback(() => navigate(FE_PATHS.LOGIN), [navigate])

  const login = (token: string, onSuccess?: (user: LoggedInUser) => void) => {
    const parsedToken = Token.parseToken(token)
    const loggedInUser = parsedToken?.user
    setUser(loggedInUser)
    setIsAdmin(loggedInUser.type === 'ADMIN')
    setIsManager(loggedInUser.type === 'MANAGER')
    localStorage.setItem('token', token)
    sendToLoginPage()

    onSuccess?.(loggedInUser)
  }

  const logout = () => {
    setUser(undefined)
    setIsAdmin(undefined)
    localStorage.clear()
    sendToLoginPage()
  }

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token')

      if (!token) {
        sendToLoginPage()
        return
      }
      try {
        await axios.post(BE_PATHS.TOKEN, { token })
        const decodedToken = Token.parseToken(token)
        const loggedInUser = decodedToken?.user
        setUser(loggedInUser)
        setIsAdmin(loggedInUser.type === 'ADMIN')
        setIsManager(loggedInUser.type === 'MANAGER')
      } catch (e) {
        localStorage.clear()
        sendToLoginPage()
      }
    }

    if (location.pathname !== FE_PATHS.LOGIN) {
      verifyToken()
    }
  }, [location])

  React.useEffect(() => {
    const token = localStorage.getItem('token')

    if (!token) return

    const parsedToken = Token.parseToken(token)
    const loggedInUser = parsedToken?.user

    const isAnAdmin = loggedInUser.type === 'ADMIN'

    if (location.pathname === '/' || location.pathname === '') {
      navigate(isAnAdmin ? FE_PATHS.DASHBOARD : FE_PATHS.LOCATIONS)
    }
  }, [navigate, location])

  React.useEffect(() => {
    if (!user) return

    SentryHelper.setTags({
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.type,
      organisationId: user.organisationId,
    })
  }, [user])

  const value: AuthProviderResult = { user, login, logout, isAdmin, isManager, isAtLeastManager }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)
