import * as yup from 'yup'

export const CameraSchema = yup.object().shape({
  name: yup.string().required('* Required'),
  locationId: yup.string().required('* Required'),
  port: yup.number().required('* Required'),
  domain: yup.string().required('* Required'),
  provider: yup.string().required('* Required'),
  direction: yup.string().required('* Required'),
  type: yup.string().required('* Required'),
})
