import SensorDoor from '@mui/icons-material/Sensors'
import React from 'react'
import { Button } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

interface OpenRelayButtonProps {
  deviceName: string
  deviceId: string
}
export const OpenRelayButton = ({ deviceId, deviceName }: OpenRelayButtonProps) => {
  const toast = useToast()
  const [isOpening, setIsOpening] = React.useState<boolean>(false)

  const [, openRelay] = useAxios({
    url: BE_PATHS.DEVICE_ACTION(deviceId, 'open-relay'),
    runOnInit: false,
    method: 'POST',
  })

  return (
    <Button
      variant="secondary"
      icon={<SensorDoor fontSize="inherit" />}
      loading={isOpening}
      disabled={isOpening}
      onClick={async () => {
        setIsOpening(true)
        try {
          await openRelay()

          toast(`Relay opened successfully for ${deviceName}`, { variant: 'success' })
        } catch (error) {
          toast(`Failed to open relay for ${deviceName}`, { variant: 'error' })
        }
        setIsOpening(false)
      }}
      style={{
        marginLeft: 'auto',
        borderRadius: '50%',
        padding: 1,
        minWidth: '24px',
        w: '24px',
        maxWidth: '24px',
        minHeight: '24px',
        h: '24px',
        maxHeight: '24px',
        fontSize: '18px',
      }}
    />
  )
}
