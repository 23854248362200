import RemoveIcon from '@mui/icons-material/Remove'
import { x } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { Button, Condition, Flex, FormikRangePicker, RowFields } from 'src/components'

import { Interval } from '../Permit.schema'

interface DayIntervalsProps {
  day: DayOfTheWeek
  intervals: Interval[]
  onRemoveInterval: (index: number) => void
}

export const DayIntervals = ({ day, intervals, onRemoveInterval }: DayIntervalsProps) => {
  const formik = useFormikContext()

  const errors = formik.errors

  return (
    <x.div display="flex" flexDirection="column" gap="8px" p="10px 0px 10px 20px">
      {intervals?.map((interval, index) => {
        const { startTime, endTime } = interval ?? {}

        const mStartTime = moment(startTime, 'HH:mm:ss')
        const mEndTime = moment(endTime, 'HH:mm:ss')

        const hasValidTimes = mStartTime.isValid() && mEndTime.isValid()

        const error = errors?.[day]?.intervals?.[index]

        const field = `customTimes.${day}.intervals[${index}]`

        return (
          <RowFields>
            <x.div maxWidth="250px">
              <FormikRangePicker
                picker="time"
                minuteStep={5}
                name={field}
                onChange={(v) => {
                  const [t1, t2] = v ?? [undefined, undefined]

                  const newStartTime = t1 ? t1?.format('HH:mm:ss') : undefined
                  const newEndTime = t2 ? t2?.format('HH:mm:ss') : undefined

                  formik.setFieldValue(`${field}.startTime`, newStartTime, true)
                  formik.setFieldValue(`${field}.endTime`, newEndTime, true)
                }}
                onBlur={() => {
                  formik.setFieldTouched(`${field}.startTime`, true, true)
                  formik.setFieldTouched(`${field}.endTime`, true, true)
                }}
                defaultValue={hasValidTimes ? [mStartTime, mEndTime] : undefined}
                error={error}
                format="HH:mm"
                hideErrorWhenNull
              />
            </x.div>

            <Condition when={index > 0}>
              <Flex align="flex-end">
                <Button
                  icon={<RemoveIcon fontSize="inherit" />}
                  style={{
                    w: '24px',
                    h: '24px',
                    maxHeight: '24px',
                    maxWidth: '24px',
                    minHeight: '24px',
                    minWidth: '24px',
                    borderRadius: 5,
                    p: 0,
                    ml: '10px',
                    mt: '8px',
                  }}
                  variant="secondary"
                  onClick={() => onRemoveInterval(index)}
                />
              </Flex>
            </Condition>
          </RowFields>
        )
      })}
    </x.div>
  )
}
