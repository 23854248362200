const exists = (): boolean => {
  const token = localStorage.getItem('token')
  return !!token
}

interface ParsedToken {
  user: LoggedInUser
}

const parseToken = (token: string): ParsedToken => {
  const base64Payload = token.split('.')[1]
  const payload = atob(base64Payload)
  const parsedToken = JSON.parse(payload)
  return parsedToken as any as ParsedToken
}

const Token = {
  exists,
  parseToken,
}

export default Token
