import dayjs from 'dayjs'
import { TagColor } from 'src/components'

export const mapSessionStatusToBadgeVariant = (status: ISession['status']): TagColor => {
  switch (status) {
    case 'CHARGING':
      return 'blue'
    case 'FINISHING':
      return 'blue'
    case 'COMPLETED':
      return 'green'
    case 'ABANDONED':
      return 'red'
    case 'PAUSED':
      return 'yellow'
    case 'ARCHIVED':
      return 'grey'
    default:
      return 'grey'
  }
}

export const mapBookingStatusToBadgeVariant = (status: IBooking['status'], endDate: IBooking['endDate']): TagColor => {
  switch (status) {
    case 'CONFIRMED':
      if (dayjs(endDate).isBefore(dayjs())) return 'green'
      return 'blue'
    case 'COMPLETED':
      return 'green'
    case 'REJECTED':
      return 'red'
    case 'CANCELLED':
      return 'orange'
    case 'ARCHIVED':
      return 'grey'
    default:
      return 'grey'
  }
}

export const mapTransactionStatusToColor = (status: ITransaction['status']): TagColor => {
  switch (status) {
    case 'SUCCESSFUL':
      return 'green'
    case 'PENDING':
      return 'yellow'
    case 'DECLINED':
      return 'red'
    case 'CANCELLED':
      return 'orange'
    case 'FAILED':
      return 'purple'
    case 'ARCHIVED':
      return 'grey'
    default:
      return 'grey'
  }
}

export const mapTransactionTypeToColor = (type: ITransaction['type']): TagColor => {
  switch (type) {
    case 'PREAUTH':
    case 'AUTH':
      return 'purple'
    case 'CAPTURE':
      return 'orange'
    case 'CHARGE':
      return 'blue'
    case 'REFUND':
    case 'VOID':
      return 'red'
    default:
      return 'grey'
  }
}

export const mapTransactionTypeToLabel = (type: ITransaction['type']): string => {
  switch (type) {
    case 'PREAUTH':
      return 'Pre-Authorization'
    case 'AUTH':
      return 'Authorization'
    case 'CAPTURE':
      return 'Capture'
    case 'CHARGE':
      return 'Sale'
    case 'REFUND':
      return 'Refund'
    case 'VOID':
      return 'Void'
    default:
      return 'Unknown'
  }
}
