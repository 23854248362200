import { x } from '@xstyled/styled-components'
import React from 'react'
import { Card, Condition, EmptyState } from 'src/components'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import * as TablerIcons from 'tabler-icons-react'

import { BarrierEventsFilters, BarrierEventsTable } from './components'
import { useBarrierEvents } from './hooks'

const initialFilters: BarrierEventFilter = {}

export function BarrierEvents() {
  const { fetchingOrgData, organisation } = useOrganisationContext()

  const [page, setPage] = React.useState<number>(0)
  const [filters, setFilters] = React.useState<BarrierEventFilter>(initialFilters)

  const [{ data: barrierEvents = [], fetching: fetchingBarrierEvents, hasMore: hasMoreBarrierEvents }] =
    useBarrierEvents({ filters, page, limit: 20 })

  return (
    <Page loading={(!organisation && fetchingOrgData) || fetchingBarrierEvents}>
      <Shell title="BarrierEvents" subtitle="Release barrierEvents" loading={!barrierEvents && fetchingOrgData}>
        <Card>
          <BarrierEventsFilters
            initialValues={filters}
            onUpdate={(newFilters) => {
              setFilters(newFilters)
              setPage(0)
            }}
            onReset={() => setFilters(initialFilters)}
          />
        </Card>

        <x.div mt="20px">
          <Condition when={!fetchingBarrierEvents && barrierEvents?.length === 0}>
            <EmptyState
              title="No data to display"
              subtitle="Please refine your search"
              icon={<TablerIcons.Search size="60" />}
              style={{ marginTop: '20px', width: '100%', maxWidth: 'unset' }}
            />
          </Condition>

          <Condition when={barrierEvents?.length > 0}>
            <BarrierEventsTable
              barrierEvents={barrierEvents}
              loading={fetchingBarrierEvents}
              hasMoreItems={hasMoreBarrierEvents}
              onLoadMore={() => !fetchingBarrierEvents && setPage(page + 1)}
            />
          </Condition>
        </x.div>
      </Shell>
    </Page>
  )
}
