import { x } from '@xstyled/styled-components'
import * as TablerIcons from 'tabler-icons-react'
import React from 'react'
import { useAuthContext } from 'src/context'
import { EmptyState, Table, Button, Condition } from 'src/components'

import { BarrierModal } from './components/BarrierModal/BarrierModal'
import { DeleteBarrierModal } from './components/DeleteBarrierModal'

interface LocationBarriersProps {
  barriers?: IBarrier[]
  cameras?: ICamera[]
  locationId: string
  onDataRefetch: () => void
}

export const LocationBarriers = ({ barriers, cameras, locationId, onDataRefetch }: LocationBarriersProps) => {
  const { isAdmin } = useAuthContext()

  const [barrierModalOpen, setBarrierModalOpen] = React.useState<boolean>(false)
  const [deleteBarrierModalOpen, setDeleteBarrierModalOpen] = React.useState<boolean>(false)

  const [barrierToDelete, setBarrierToDelete] = React.useState<IBarrier>()
  const [barrierToEdit, setBarrierToEdit] = React.useState<IBarrier>()
  const handleDelete = async (device: IBarrier) => {
    setBarrierToDelete(device)
    setDeleteBarrierModalOpen(true)
  }

  return (
    <div>
      <Condition when={isAdmin}>
        <x.div mt="10px" mb="20px" w="100%" display="flex">
          <Button text="+ Add barrier" variant="accent" onClick={() => setBarrierModalOpen(true)} />
        </x.div>
      </Condition>
      {barriers && barriers?.length === 0 && (
        <EmptyState
          title="There are no barriers currently set up"
          subtitle="Barriers are a great way for you to remotely control parking lots blockades."
          icon={<TablerIcons.BarrierBlock size="60" />}
          buttonProps={
            isAdmin
              ? {
                  text: 'Add a barrier',
                  variant: 'accent',
                  onClick: () => setBarrierModalOpen(true),
                  style: { h: '50px' },
                }
              : undefined
          }
        />
      )}
      {barriers && barriers.length > 0 && (
        <Table
          items={barriers}
          headers={[
            { key: 'name' },
            {
              customKey: 'camera',
              format: (_, item) => cameras?.find((camera) => camera.id === item.cameraId)?.name ?? '-',
            },
            { key: 'domain' },
            { key: 'port' },
            {
              customKey: 'Pulse channel',
              format: (_, barrier) => barrier?.channel || '-',
              align: 'center',
            },
            {
              customKey: 'Toggle channel',
              format: (_, barrier) => barrier?.toggleChannel || '-',
              align: 'center',
            },
          ]}
          actions={
            isAdmin && [
              {
                label: 'Edit',
                onClick: (item: IBarrier) => {
                  setBarrierToEdit(item)
                  setBarrierModalOpen(true)
                },
                type: 'edit',
              },
              { label: 'Delete', onClick: handleDelete, type: 'delete' },
            ]
          }
        />
      )}
      <Condition when={isAdmin}>
        <BarrierModal
          isOpen={barrierModalOpen}
          barrier={barrierToEdit}
          cameras={cameras}
          locationId={locationId}
          onSubmitSuccess={async () => {
            await onDataRefetch()
          }}
          onClose={() => {
            setBarrierModalOpen(false)
            setBarrierToEdit(undefined)
          }}
        />
      </Condition>
      <Condition when={isAdmin}>
        <DeleteBarrierModal
          barrier={barrierToDelete as IBarrier}
          locationId={locationId}
          isOpen={deleteBarrierModalOpen}
          onClose={() => setDeleteBarrierModalOpen(false)}
          onDelete={async () => {
            await onDataRefetch()
          }}
        />
      </Condition>
    </div>
  )
}
