import { transformActiveTimeIntervalsToSchema } from 'src/screens/Permits/utils'

import { IPermitSchema } from '../../PermitForm'

export const transformPermitToFormData = (permit: IPermit): IPermitSchema => {
  const formData: IPermitSchema = {
    vrm: permit?.vrm,
    reference: permit?.reference,
    locationId: permit?.locationId,
    startDate: permit?.startDate,
    endDate: permit?.endDate,
    firstName: permit?.firstName,
    lastName: permit?.lastName,
    hasCustomTimes: !!permit?.activeTimeIntervalsByDays,
    customTimes: permit?.activeTimeIntervalsByDays
      ? transformActiveTimeIntervalsToSchema(permit?.activeTimeIntervalsByDays)
      : null,
  }

  return formData
}
