import { Anchor, Tabs } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Badge, Modal, ModalProps, Table, Text } from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { BE_PATHS, FE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'
import { convertToDuration, genQueryParams } from 'src/utils'
import * as TablerIcons from 'tabler-icons-react'

import { BookingSource, BookingTableHeaders, BookingType } from './components'

const Field = ({ label, value }: { label: string; value?: string | number | Date | JSX.Element | null }) => (
  <x.div display="flex" alignItems="center" mb="15px">
    <Text type="h6" w="100px" fontSize="12px">
      {label}:
    </Text>
    <Text type="h6" ml={10} fontSize="12px">
      {value || '—'}
    </Text>
  </x.div>
)

type BookingModalProps = {
  booking?: IBooking
} & Partial<ModalProps>

export const BookingModal = ({ booking: initialBooking, ...restModal }: BookingModalProps) => {
  const navigate = useNavigate()

  const [{ data: booking }] = useAxios<IFullBooking>({
    url: `${BE_PATHS.BOOKING(initialBooking?.id as string)}`,
    pause: !initialBooking || !initialBooking?.id,
    params: {
      include: genQueryParams<IFullBooking>({
        sessions: { include: { bay: true, location: true } },
        transactions: { include: { provider: true } },
        location: true,
      }),
    },
  })

  const sessionsTableHeaders = BookingTableHeaders.getSessionTableHeaders(navigate)
  const transactionsTableHeaders = BookingTableHeaders.getTransactionTableHeaders()

  return (
    <Modal
      title={<x.span>Booking #{booking?.reference}</x.span>}
      isOpen={!!booking && !!initialBooking}
      cardStyle={{ maxWidth: '1200px' }}
      {...restModal}
    >
      <x.div display="flex" flexDirection="column" w="100%">
        <x.div display="flex" flexDirection="row" w="100%" mb="20px">
          <x.div display="flex" flexDirection="column" w="300px">
            <Field
              label="Location"
              value={
                <Anchor onClick={() => navigate(FE_PATHS.LOCATION(booking?.locationId as string))}>
                  <x.span fontSize="12px" color="dark">
                    {booking?.location?.name}
                  </x.span>
                </Anchor>
              }
            />
            <Field label="Type" value={<BookingType type={booking?.type} />} />
            <Field label="Source" value={<BookingSource source={booking?.source} />} />
          </x.div>
          <x.div display="flex" flexDirection="column" w="300px" ml="150px">
            <Field label="VRM" value={booking?.vrm} />
            <Field label="Start date" value={dayjs(booking?.startDate).format(FORMATS.DATETIME)} />
            <Field label="End date" value={dayjs(booking?.endDate).format(FORMATS.DATETIME)} />
            <Field label="Duration" value={convertToDuration(booking?.startDate, booking?.endDate)} />
            <Field
              label="Cost"
              value={
                <Badge variant="grey" style={{ px: '10px' }}>
                  <x.span fontWeight="bold" fontSize="12px">{`£ ${booking?.cost}`}</x.span>
                </Badge>
              }
            />
          </x.div>
        </x.div>
        <x.div display="flex" flexDirection="column" w="100%" justifyContent="space-between" mb="20px" mt="10px">
          <Tabs variant="default" color="gray">
            <Tabs.Tab label="Sessions" icon={<TablerIcons.Devices size={12} />}>
              <Table items={booking?.sessions} headers={sessionsTableHeaders} />
            </Tabs.Tab>
            <Tabs.Tab label="Transactions" icon={<TablerIcons.Devices size={12} />}>
              <Table items={booking?.transactions} headers={transactionsTableHeaders} />
            </Tabs.Tab>
          </Tabs>
        </x.div>
      </x.div>
    </Modal>
  )
}
