import { Form, useFormikContext } from 'formik'
import moment from 'moment'
import {
  Condition,
  Divider,
  FormikRangePicker,
  FormikSelectDropdown,
  FormikSelectDropdownProps,
  InputField,
  InputFieldProps,
  RowFields,
  ToggleSettingSection,
} from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { useOrganisationContext } from 'src/context'
import { getDayjsDate } from 'src/utils'

import { CustomTimesForm, defaultCustomTimes } from './components'
import { IPermitSchema } from './Permit.schema'

const PermitInputField = (props: InputFieldProps<Partial<IPermitSchema>>) => InputField<Partial<IPermitSchema>>(props)
const PermitSelectDropdown = (props: FormikSelectDropdownProps<Partial<IPermitSchema>>) =>
  FormikSelectDropdown<Partial<IPermitSchema>>(props)

export const PermitForm = () => {
  const { locations } = useOrganisationContext()

  const formik = useFormikContext<IPermitSchema>()

  const values = formik.values
  const errors = formik.errors

  const location = locations?.find((loc) => loc.id === values?.locationId)

  const startDate = getDayjsDate({ date: values.startDate, tz: location?.timezone })?.format(FORMATS.DATETIMELONG)
  const endDate = getDayjsDate({ date: values.endDate, tz: location?.timezone })?.format(FORMATS.DATETIMELONG)

  const mStartDate = moment(startDate)
  const mEndDate = moment(endDate)

  const hasValidDates = values?.startDate && values?.endDate

  return (
    <Form onSubmit={formik.handleSubmit} style={{ minWidth: '100%' }}>
      <h3 style={{ fontWeight: 'bold' }}>Permit</h3>
      <Divider mb="20px" light />
      <PermitInputField
        placeholder="Reference"
        name="reference"
        label="Reference"
        type="text"
        error={errors?.reference}
        inputProps={{ maxLength: 100 }}
      />
      <RowFields>
        <PermitInputField
          placeholder="Enter the VRM"
          name="vrm"
          label="VRM"
          type="text"
          error={errors?.vrm}
          onChange={(value) => formik.setFieldValue('vrm', value?.toUpperCase().replace(/\s/g, ''))}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Space' || e.key === ' ') {
                e.preventDefault()
              }
            },
          }}
        />
        <PermitSelectDropdown
          placeholder="Select location"
          name="locationId"
          label="Location"
          multi={false}
          error={errors.locationId}
          items={locations?.map((location) => ({ label: location?.name, value: location?.id }))}
        />
      </RowFields>
      <RowFields>
        <PermitInputField
          placeholder="John"
          name="firstName"
          label="First name"
          type="text"
          error={errors?.firstName}
        />
        <PermitInputField placeholder="Doe" name="lastName" label="Last name" type="text" error={errors?.lastName} />
      </RowFields>

      <FormikRangePicker
        picker="date"
        label="Start and end date"
        name="startDate"
        showTime={{ minuteStep: 5 }}
        onChange={(v) => {
          const [startDate, endDate] = v ?? [undefined, undefined]

          formik.setFieldValue('startDate', startDate?.format('YYYY-MM-DDTHH:mm:ss'), true)
          formik.setFieldValue('endDate', endDate?.format('YYYY-MM-DDTHH:mm:ss'), true)
        }}
        onBlur={() => {
          formik.setFieldTouched('startDate', true, true)
          formik.setFieldTouched('endDate', true, true)
        }}
        defaultValue={hasValidDates ? [mStartDate, mEndDate] : undefined}
        error={errors?.startDate || errors?.endDate}
        format={FORMATS.DATETIMELONG}
      />

      <Divider mb={'20px'} light />
      <ToggleSettingSection
        title="Custom times"
        heading="This permit has custom times"
        name="hasCustomTimes"
        onChange={async (hasCustomTimes) => {
          if (!hasCustomTimes) {
            // We need to wait to update formik values otherwise validation won't work properly
            await new Promise((resolve) => setTimeout(resolve, 200))
            formik.setFieldValue('customTimes', defaultCustomTimes, true)
          }
        }}
      />
      <br />
      <Condition when={values?.hasCustomTimes}>
        <CustomTimesForm />
      </Condition>
    </Form>
  )
}
