import { x } from '@xstyled/styled-components'
import { isEqual } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Condition, Table } from 'src/components'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useToast } from 'src/hooks'

import { BookingModal, getBookingsTableHeaders } from './components'
import { BookingsFilters } from './components/BookingsFilters'
import { useBookings } from './hooks'

export const Bookings = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { organisation } = useOrganisationContext()
  const [activeBooking, setActiveBooking] = React.useState<IFullBooking>()

  const [page, setPage] = React.useState<number>(0)
  const [filters, setFilters] = React.useState<BookingFilter>()

  const [{ data: bookings = [], fetching: fetchingBookings, hasMore: hasMoreBookings }, refetchBookings] = useBookings(
    filters,
    page,
  )
  const tableHeaders = getBookingsTableHeaders(navigate, setActiveBooking)

  return (
    <Page loading={!organisation}>
      <Shell title="Bookings" style={{ minHeight: '100%' }}>
        <Card>
          <BookingsFilters
            initialValues={filters}
            onUpdate={(newFilters) => {
              if (isEqual(newFilters, filters)) {
                setPage(0)
                refetchBookings()
              } else {
                setPage(0)
                setFilters(newFilters)
              }
              toast('Filters applied', { variant: 'success' })
            }}
            onReset={() => {
              setPage(0)
              setFilters({})
              toast('Filters cleared', { variant: 'default' })
            }}
            loading={fetchingBookings}
          />
        </Card>

        <x.div mt="20px">
          <Condition when={!!organisation}>
            <Condition when={!!bookings}>
              <Table
                hasMoreItems={hasMoreBookings}
                onLoadMore={() => !fetchingBookings && setPage(page + 1)}
                loading={fetchingBookings}
                items={bookings}
                headers={tableHeaders}
                emptyState={{
                  emptyTitle: 'No data to display',
                  emptySubTitle: 'Please refine your search',
                }}
              />
            </Condition>
            <BookingModal booking={activeBooking} onClose={() => setActiveBooking(undefined)} />
          </Condition>
        </x.div>
      </Shell>
    </Page>
  )
}
