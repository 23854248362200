import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  max-width: 220px;
  padding: 5px 10px;
  align-items: center;
  justify-content: flex-start;
  background-color: primary;
  border-radius: 8px;
  border: 1px solid;
  border-color: grey12;
  text-align: left;
`

export const $Dot = styled.divBox`
  margin-right: 5px;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;

  &[data-variant='grey'] {
    background-color: grayer;
  }
  &[data-variant='red'] {
    background-color: danger;
  }
  &[data-variant='yellow'] {
    background-color: yellow;
  }
  &[data-variant='orange'] {
    background-color: orange;
  }
  &[data-variant='green'] {
    background-color: green;
  }
  &[data-variant='blue'] {
    background-color: blue;
  }
  &[data-variant='purple'] {
    background-color: purple;
  }
  &[data-variant='dark'] {
    background-color: dark;
  }
  &[data-variant='light'] {
    background-color: light;
  }
`

export const $Text = styled.spanBox`
  font-weight: 500;
  font-size: 12px;
`
