import { x } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import { InputField, RowFields } from 'src/components'
import { useAuthContext } from 'src/context'

export const LocationSettingsForm = () => {
  const { isAdmin } = useAuthContext()
  const { errors } = useFormikContext<Partial<ILocation>>()

  return (
    <x.div>
      <InputField placeholderName="location" name="name" label="Name" error={errors?.name} disabled={!isAdmin} />
      <RowFields>
        <InputField
          placeholderName="location"
          name="address.number"
          label="Street Number"
          disabled={!isAdmin}
          error={errors?.address?.['number']}
        />
        <InputField
          placeholderName="location"
          name="address.street"
          label="Street Name"
          disabled={!isAdmin}
          error={errors?.address?.['street']}
        />
      </RowFields>
      <RowFields>
        <InputField
          placeholderName="location"
          name="address.city"
          label="City"
          error={errors?.address?.['city']}
          disabled={!isAdmin}
        />
        <InputField
          placeholderName="location"
          name="address.postcode"
          label="Postcode"
          disabled={!isAdmin}
          error={errors?.address?.['postcode']}
        />
      </RowFields>
      <RowFields>
        <InputField placeholderName="location" name="code" label="Public Location Code" disabled />
        <x.div />
      </RowFields>
    </x.div>
  )
}
