import { Tooltip } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as TablerIcons from 'tabler-icons-react'
import { Badge, Condition, Tag, THeader } from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { FE_PATHS } from 'src/constants/paths'
import { convertToDuration, Mapper } from 'src/utils'
import { ProviderTableCellIcon } from 'src/screens/shared/components'
import { NavigateFunction } from 'react-router-dom'

export const getSessionTableHeaders = (navigate: NavigateFunction) => {
  const sessionsTableHeaders = [
    {
      key: 'type',
      render: (_v, item) => {
        if (item.type === 'charging')
          return (
            <Tooltip label="Charging session" openDelay={500}>
              <TablerIcons.Bolt size={20} color="#02b88d" />
            </Tooltip>
          )
        return (
          <Tooltip label={`${_.capitalize(item.type.split('_')?.[1])} parking session`} openDelay={500}>
            <TablerIcons.Parking size={20} color={item.type === 'parking_regular' ? '#0094ff' : '#ff9d00'} />
          </Tooltip>
        )
      },
    },
    { customKey: 'provider session', format: (_, session) => session.providerSessionId },
    {
      customKey: 'bay',
      render: (_, session) =>
        session?.bay ? (
          <Badge
            variant="grey"
            text={session?.bay?.name}
            style={{ w: 'fit-content', px: '7px', fontWeight: 'bold', cursor: 'pointer' }}
            onClick={() => navigate(`${FE_PATHS.LOCATION(session?.location?.id as string)}?bays=true`)}
          />
        ) : (
          '—'
        ),
    },
    {
      key: 'source',
      align: 'left',
      render: (_, session) => <ProviderTableCellIcon provider={session?.source} />,
    },
    { customKey: 'Start date', format: (_, session) => dayjs(session.startDate).format(FORMATS.DATETIME) },
    {
      customKey: 'End date',
      format: (_, session) => (session.endDate ? dayjs(session.endDate).format(FORMATS.DATETIME) : '—'),
    },
    {
      customKey: 'duration',
      format: (_, session) => (session.startDate ? convertToDuration(session.startDate, session?.endDate) : '—'),
    },
    {
      key: 'status',
      render: (_v, session) => (
        <Badge
          text={_.capitalize(session?.status || '')}
          style={{ w: '90px' }}
          textStyle={{ fontWeight: '500' }}
          variant={Mapper.mapSessionStatusToBadgeVariant(session.status)}
        />
      ),
    },
    {
      key: 'cost',
      align: 'left',
      render: (_, session) => (
        <x.div display="flex" alignItems="center">
          <x.span>£ {session.cost?.toFixed(2)}</x.span>
        </x.div>
      ),
    },
  ] as THeader<IFullSession>[]

  return sessionsTableHeaders
}

export const getTransactionTableHeaders = () => {
  const tableHeaders = [
    {
      key: 'reference',
      render: (_, transaction) => {
        return (
          <Badge variant="grey" style={{ w: '260px', maxWidth: 'unset', cursor: 'pointer' }}>
            <x.div display="flex" alignItems="center">
              <x.span ml="2px">{transaction.reference}</x.span>
            </x.div>
          </Badge>
        )
      },
    },
    {
      customKey: 'timestamp',
      format: (_, transaction) =>
        dayjs(transaction.createdAt || transaction?.booking?.createdAt).format(FORMATS.DATETIME),
    },
    {
      customKey: 'source',
      align: 'center',
      render: (_, transaction) => {
        if (transaction?.provider?.slug === 'ALIO') {
          return (
            <Tooltip label="Kiosk" openDelay={500}>
              <TablerIcons.DeviceDesktop size={20} />
            </Tooltip>
          )
        }

        if (transaction?.provider?.slug === 'NMI') {
          return (
            <Tooltip label="Mobile" openDelay={500}>
              <TablerIcons.DeviceMobile size={20} />
            </Tooltip>
          )
        }

        return (
          <Tooltip label="Server" openDelay={500}>
            <TablerIcons.Server2 size={20} />
          </Tooltip>
        )
      },
    },
    {
      key: 'type',
      align: 'center',
      render: (_t, transaction) => (
        <Tag
          variant={Mapper.mapTransactionTypeToColor(transaction.type)}
          style={{ w: '90px', fontWeight: '500', textTransform: 'none', border: 'none' }}
        >
          {transaction?.type === 'CHARGE' ? 'Sale' : _.capitalize(transaction?.type.toLowerCase())}
        </Tag>
      ),
    },
    {
      key: 'status',
      align: 'center',
      render: (_t, transaction) => (
        <>
          <Condition when={transaction?.status === 'SUCCESSFUL'}>
            <Badge
              text={_.capitalize(transaction?.status)}
              variant={Mapper.mapTransactionStatusToColor(transaction.status)}
            />
          </Condition>
          <Condition when={transaction?.status !== 'SUCCESSFUL'}>
            <Tooltip
              label={
                transaction?.error ? `${transaction?.error?.code}: ${transaction?.error?.message}` : 'Unknown reason'
              }
              position="left"
              style={{
                display: 'block',
              }}
              withArrow
              color="orange"
            >
              <Badge
                text={_.capitalize(transaction?.status)}
                variant={Mapper.mapTransactionStatusToColor(transaction.status)}
                style={{
                  cursor: 'help',
                }}
              />
            </Tooltip>
          </Condition>
        </>
      ),
    },
    {
      key: 'amount',
      format: (_, transaction) => (transaction.amount ? `£ ${transaction.amount.toFixed(2)}` : undefined),
    },
  ] as THeader<IFullTransaction>[]

  return tableHeaders
}
