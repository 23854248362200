import React from 'react'
import { useOrganisationContext } from 'src/context'
import { IDeviceHeartbeat } from 'src/typings/device'

import { HealthFilter } from '../types'
import { getDegradedDevicesByHeartbeats, getHealthyDevicesByHeartbeats, getWarningDevicesByHeartbeats } from '../utils'

import DeviceHealthCard from './DeviceHealthCard'
import * as S from './DeviceHealthFilter.styled'

interface HealthStatusFilterProps {
  heartbeats?: IDeviceHeartbeat[]
  value?: HealthFilter
  onChange: (deviceHealthFilter: HealthFilter | undefined) => void
}

export const DeviceHealthFilter = ({ heartbeats, value, onChange }: HealthStatusFilterProps) => {
  const { devices = [] } = useOrganisationContext()

  const healthyDevicesCount = React.useMemo(
    () => getHealthyDevicesByHeartbeats(devices, heartbeats).length,
    [devices, heartbeats],
  )

  const warningDevicesCount = React.useMemo(
    () => getWarningDevicesByHeartbeats(devices, heartbeats).length,
    [devices, heartbeats],
  )

  const degradedDevicesCount = React.useMemo(
    () => getDegradedDevicesByHeartbeats(devices, heartbeats).length,
    [devices, heartbeats],
  )

  return (
    <S.$DevicesStatusContainer>
      <DeviceHealthCard
        label="Total"
        devicesCount={devices?.length ?? 0}
        variant="default"
        active={!value}
        onClick={() => onChange(undefined)}
      />
      <DeviceHealthCard
        label="Healthy"
        devicesCount={healthyDevicesCount}
        variant="healthy"
        active={value === 'healthy'}
        onClick={() => onChange('healthy')}
      />
      <DeviceHealthCard
        label="Warning"
        devicesCount={warningDevicesCount}
        variant="warning"
        active={value === 'warning'}
        onClick={() => onChange('warning')}
      />
      <DeviceHealthCard
        label="Degraded"
        devicesCount={degradedDevicesCount}
        variant="degraded"
        active={value === 'degraded'}
        onClick={() => onChange('degraded')}
      />
    </S.$DevicesStatusContainer>
  )
}
