import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

import { TabStyles } from '../Tab'

export const $Wrapper = styled.divBox`
  background-color: grey82;
  color: dark;
  left: 0;
  width: 220px;
  height: 100vh;
  z-index: 5;

  transition: all 0.3s ease-in-out;

  &[data-open='true'] {
    left: 0px;
  }

  &[data-open='false'] {
    left: -250px;
  }

  ${Styles.screens.small`
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 5;
		
		&[data-open='true'] {
			box-shadow: 7px 0px 20px 4px #8c8c8c6b;
		}

	`}
`

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  height: 100vh;
`

export const $TopArea = styled.divBox`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
`

export const $CenterArea = styled.divBox`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: auto;
`

export const $BottomArea = styled.divBox`
  width: 220px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const $LastTab = styled(TabStyles.$Container)`
  border-top: 1px solid;
  border-color: grey64;
  background-color: grey84;
  padding: 30px 5px;
  width: 220px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0 !important;

  > * {
    width: 100%;

    color: light;
  }
`
