import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 8px 4px;
  font-weight: 600;
  max-width: 120px;
  font-size: 12px;

  &[data-variant='grey'] {
    background-color: grey8;
    color: grayer;
  }
  &[data-variant='red'] {
    background-color: redPale;
    color: redText;
  }
  &[data-variant='yellow'] {
    background-color: yellowPale;
    color: yellowText;
  }
  &[data-variant='orange'] {
    background-color: orangePale;
    color: orangeText;
  }
  &[data-variant='green'] {
    background-color: greenPale;
    color: greenText;
  }
  &[data-variant='blue'] {
    background-color: blueDim;
    color: blueDarker;
  }
  &[data-variant='purple'] {
    background-color: purpleDim;
    color: purple;
  }
  &[data-variant='dark'] {
    background-color: darkDim;
    color: light;
  }
  &[data-variant='light'] {
    background-color: light;
    color: dark;
  }
`

export const $Text = styled.spanBox`
  line-height: 12px;
`
