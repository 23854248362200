import React from 'react'

import * as S from './Label.styled'

export interface LabelProps {
  children: React.ReactNode
  variant?: 'default' | 'success' | 'warning' | 'error'
  xss?: SCProps<typeof S.$Label>
}

export const Label = ({ children, variant, xss }: LabelProps) => {
  return (
    <S.$Label data-success={variant === 'success'} data-error={variant === 'error'} {...xss}>
      {children}
    </S.$Label>
  )
}
