import { Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useNavigate } from 'react-router-dom'
import { Table } from 'src/components'
import { FE_PATHS } from 'src/constants'
import { FORMATS } from 'src/constants/formats'
import { useOrganisationContext } from 'src/context'
import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

import { mapDeviceTypeToLabel } from '../utils'

import DeviceBadge from './DeviceBadge'
import DeviceTag from './DeviceTag'

dayjs.extend(duration)
dayjs.extend(relativeTime)

interface DevicesHealthTableProps {
  devices?: IDevice[]
  heartbeats?: IDeviceHeartbeat[]
}

export const DevicesHealthTable = ({ devices, heartbeats }: DevicesHealthTableProps) => {
  const navigate = useNavigate()

  const { locations = [] } = useOrganisationContext()

  return (
    <Table
      items={devices}
      headers={[
        {
          customKey: 'device',
          render: (_, item) => {
            const location = locations.find((l) => l.id === item?.locationId)

            if (!location?.id) return <x.span>-</x.span>

            return (
              <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?devices=true')}>
                <x.span fontSize="12px" color="dark">
                  {item?.name}
                </x.span>
              </Anchor>
            )
          },
        },
        {
          customKey: 'type',
          align: 'center',
          render: (_, device) => {
            return <x.span>{mapDeviceTypeToLabel(device?.type)}</x.span>
          },
        },
        {
          customKey: 'location',
          render: (_, item) => {
            const location = locations.find((l) => l.id === item?.locationId)

            if (!location?.id) return <x.span>-</x.span>

            return (
              <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?devices=true')}>
                <x.span fontSize="12px" color="dark">
                  {location?.name}
                </x.span>
              </Anchor>
            )
          },
        },
        {
          customKey: 'health',
          align: 'center',
          render: (_, device) => {
            const health = heartbeats?.find((h) => h.deviceId === device?.id)
            if (!health) return <DeviceBadge mainOk={false} peripheralsOk={false} />

            const { alio, printer } = health?.data ?? {}

            const hasAlio = !!alio
            const hasPrinter = !!printer

            const isAlioHealthOk = hasAlio ? alio.level === 'info' : true
            const isPrinterHealthOk = hasPrinter ? printer.level === 'info' : true

            const isKioskHealthOk = health?.level === 'info'
            const peripheralsOk = isAlioHealthOk && isPrinterHealthOk

            return <DeviceBadge mainOk={isKioskHealthOk} peripheralsOk={peripheralsOk} />
          },
        },
        {
          customKey: 'status',
          align: 'center',
          render: (_, device) => {
            const health = heartbeats?.find((h) => h.deviceId === device?.id)
            const { level, data } = health || {}

            const alio = data?.alio
            const printer = data?.printer
            const kiosk = data?.kiosk

            return (
              <DeviceTag
                isHealthy={!!level && level !== 'error'}
                peripheralsOk={true}
                deviceId={device?.id}
                deviceName={device?.name || ''}
                kioskVersion={data?.kiosk?.version || 'unknown'}
                kioskStatus={kiosk?.message || 'Unknown'}
                peripherals={
                  device?.type === 'access_control'
                    ? [{ type: 'relay', level: 'info' }]
                    : ([
                        printer && { type: 'printer', level: printer?.level, message: printer?.message },
                        alio && { type: 'payment-device', level: alio?.level, message: alio?.message ?? 'n/a' },
                      ].filter(Boolean) as any)
                }
              />
            )
          },
        },
        {
          customKey: 'Uptime',
          align: 'left',
          format: (_x, device) => {
            const health = heartbeats?.find((h) => h.deviceId === device?.id)

            const uptime = health?.data?.kiosk?.uptime
            const lastContactWasMoreThan10MinutesAgo = dayjs(health?.timestamp).isBefore(dayjs().subtract(10, 'minute'))
            return uptime
              ? lastContactWasMoreThan10MinutesAgo
                ? 'N/A'
                : dayjs.duration(uptime, 'seconds').humanize()
              : 'Unknown'
          },
        },
        {
          customKey: 'Last contact',
          align: 'left',
          format: (_x, device) => {
            const health = heartbeats?.find((h) => h.deviceId === device?.id)
            return health?.timestamp ? dayjs(health?.timestamp).format(FORMATS.DATETIME) : 'Unknown'
          },
        },
      ]}
    />
  )
}
