import { colors } from '../../theme/colors'

import * as S from './Loader.styled'

type Color = keyof typeof colors

export interface LoaderProps {
  size?: number
  color?: Color
}

export default function Loader({ size = 32, color = 'accent' }: LoaderProps) {
  return (
    <S.$Container w={size} h={size} color={color}>
      <div />
      <div />
      <div />
      <div />
    </S.$Container>
  )
}
