import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

import { Modal } from '../../../components'

interface Props {
  isOpen: boolean
  location: ILocation
  onDelete: (location: Partial<ILocation>) => void
  onClose: () => void
}

export const DeleteLocationModal = ({ isOpen, location, onDelete, onClose }: Props) => {
  const [, deleteLocation] = useAxios<ILocation>({ method: 'DELETE', url: `${BE_PATHS.LOCATIONS}/${location?.id}` })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!location) return
    await deleteLocation()
    onDelete(location)
    onClose()
  }, [location, deleteLocation, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The location with name <strong>{location?.name || 'This item'}</strong> and <strong>ALL</strong> associated
          resources linked to it will be permanently deleted. This will also delete all sessions, bookings, bays,
          transactions, decodes, and devices associated with this location. This action is <strong>irreversible</strong>
          !
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
