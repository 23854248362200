import { IPermitSchema } from '../components/PermitForm'

export const transformActiveTimeIntervalsToSchema = (
  activeTimeIntervalsByDays: IPermit['activeTimeIntervalsByDays'],
): IPermitSchema['customTimes'] => {
  if (!activeTimeIntervalsByDays) return null

  const daysOfWeek: DayOfTheWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  const customTimes: IPermitSchema['customTimes'] = daysOfWeek.reduce((acc, day) => {
    const dayIntervalsStr = activeTimeIntervalsByDays[day]

    if (!dayIntervalsStr) {
      acc[day] = { enabled: false, intervals: null }
      return acc
    }

    acc[day] = {
      enabled: true,
      intervals: dayIntervalsStr?.split(',').map((intervalStr) => {
        const [startTime, endTime] = intervalStr.split('-')
        return { startTime, endTime }
      }),
    }

    return acc
  }, {} as NonNullable<IPermitSchema['customTimes']>)

  return customTimes
}
