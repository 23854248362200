import { x } from '@xstyled/styled-components'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { AdminArea, Button, Card, RowFields } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios, useToast } from 'src/hooks'

import { LocationConfigsForm } from './components'
import { LocationConfigsSchema } from './LocationConfigs.schema'

interface LocationConfigsProps {
  location?: Partial<ILocation>
  onDone: () => void
}

export const LocationConfigs = ({ location, onDone }: LocationConfigsProps) => {
  const toast = useToast()

  const [, submitData] = useAxios<Partial<ILocation>>({
    method: 'PUT',
    url: `${BE_PATHS.LOCATIONS}/${location?.id}`,
  })

  const initialValues = {
    configs: {
      supportsRegularParking: false,
      supportsEVCharging: false,
      extensionGracePeriodMinutes: 5,
      ...location?.configs,
    },
    evConfigs: {
      supportsPremiumParking: false,
      ...location?.evConfigs,
    },
    ...location,
  } as Partial<ILocation>

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LocationConfigsSchema}
      onSubmit={async (values: Partial<ILocation>) => {
        const transformedValues = LocationConfigsSchema.cast(values)

        const fieldsToOmit = ['id', 'devices', 'tariffs', 'bays', 'tariffId', 'organisationId', 'barriers', 'cameras']
        const data = _.omit(transformedValues, fieldsToOmit)

        try {
          await submitData({ body: data })
          toast('Changes saved', { variant: 'success' })
          onDone()
        } catch (error) {
          toast('Failed to save changes', { variant: 'error' })
        }
      }}
    >
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit} style={{ width: '100%', maxWidth: '650px' }}>
            <RowFields>
              <Card style={{ mt: '10px', p: '20px' }}>
                <LocationConfigsForm />

                <AdminArea>
                  <x.div display="flex" ml="auto" gap="10px" mt="40px">
                    <Button text="Cancel" variant="secondary" onClick={formik.resetForm} />
                    <Button
                      text="Save changes"
                      loading={formik.isSubmitting}
                      variant="accent"
                      onClick={async () => {
                        try {
                          formik.setSubmitting(true)
                          await formik.submitForm()
                        } catch (error) {
                          console.error(error)
                        } finally {
                          formik.setSubmitting(false)
                        }
                      }}
                    />
                  </x.div>
                </AdminArea>
              </Card>
            </RowFields>
          </Form>
        )
      }}
    </Formik>
  )
}
