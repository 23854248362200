import { Form, useFormikContext } from 'formik'
import React from 'react'

import {
  Divider,
  FormikSelectDropdown,
  FormikSelectDropdownProps,
  InputField,
  InputFieldProps,
} from '../../../components'

const UserInputField = (props: InputFieldProps<Partial<User>>) => InputField<Partial<User>>(props)
const UserSelectDropdown = (props: FormikSelectDropdownProps<Partial<User>>) =>
  FormikSelectDropdown<Partial<User>>(props)

interface UserFormProps {
  onChange?: (data: Partial<User>) => void
  errors: any
  user?: Partial<User>
  isActive?: boolean
}

export default function UserForm({ user, onChange, errors, isActive }: UserFormProps) {
  const formik = useFormikContext<Partial<User>>()

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(user ?? {}, false)
  }, [user, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ minWidth: '100%' }}>
      <h3 style={{ fontWeight: 'bold' }}>User authentication</h3>
      <Divider light />
      <br />
      <UserInputField
        placeholder="Enter an email"
        name="email"
        label="Email"
        type="text"
        disabled={!!user?.email}
        error={errors?.email}
      />
      <br />
      <UserInputField
        placeholder="Enter a password"
        name="password"
        label={user ? 'New password (optional)' : 'Password'}
        type="password"
        error={errors.password}
      />
      <br />
      <h3 style={{ fontWeight: 'bold' }}>User profile</h3>
      <Divider light />
      <br />
      <UserInputField placeholder="Enter the user's name" name="name" label="Name" type="text" error={errors.name} />
      <br />
      <UserSelectDropdown
        placeholder="Select user type"
        name="type"
        label="Type"
        multi={false}
        error={errors.type}
        items={[
          { label: 'User', value: 'USER' },
          { label: 'Manager', value: 'MANAGER' },
          { label: 'Admin', value: 'ADMIN' },
        ]}
      />
    </Form>
  )
}
