import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  height: 45px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  padding: 6px;
  font-size: 14px;

  transition: all 0.3s ease;

  input:checked {
    background-color: teal;
    border-color: teal;
  }
`
