import React from 'react'

import useDebounce from './useDebounce'

interface AnimationProps {
  enterDelay?: number
  exitDelay?: number
  entered?: boolean
  exited?: boolean
}

export const useAnimation = ({ enterDelay = 0, exitDelay = 0, entered }: AnimationProps) => {
  const ref = React.useRef<HTMLElement>(null)

  const debouncedIsEntered = useDebounce(entered, enterDelay)
  const debouncedIsExited = useDebounce(!entered, exitDelay)

  React.useEffect(() => {
    ref.current?.setAttribute('data-entering', String(entered))
    ref.current?.setAttribute('data-entered', String(debouncedIsEntered))
    ref.current?.setAttribute('data-exiting', String(!entered))
    ref.current?.setAttribute('data-exited', String(debouncedIsExited))
  }, [entered, debouncedIsEntered, debouncedIsExited, ref])

  return [ref]
}
