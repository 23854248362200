import _ from 'lodash'
import { Condition, Table } from 'src/components'
import { getFormattedTimestampInterval } from 'src/utils'

import { ITransactionsMetrics } from '../hooks'

export type GroupBy = 'location' | 'date'

interface MetricsTableProps {
  metrics: ITransactionsMetrics[] | undefined
  fetchingMetrics: boolean
  groupBy: GroupBy
  timestamp: { gt: string; lt: string }
}

export const MetricsTable = ({ fetchingMetrics, groupBy, metrics, timestamp }: MetricsTableProps) => {
  return (
    <Condition when={!!metrics}>
      <Table
        items={metrics}
        summary={metrics ? getTransactionsMetricsSummary(metrics) : undefined}
        heading={`Transactions by ${_.capitalize(groupBy)} (${getFormattedTimestampInterval(timestamp)})`}
        headers={[
          groupBy === 'location' ? { key: 'location', format: ({ name }) => name } : { key: 'date' },
          { key: 'count' },
          { key: 'sum', format: (sum: number) => `£ ${sum.toFixed(2)}` },
          { key: 'avg', format: (average: number) => `£ ${average.toFixed(2)}` },
          { key: 'min', format: (min: number) => `£ ${min.toFixed(2)}` },
          { key: 'max', format: (max: number) => `£ ${max.toFixed(2)}` },
        ]}
        loading={fetchingMetrics}
      />
    </Condition>
  )
}

const getTransactionsMetricsSummary = (metrics: ITransactionsMetrics[]): ITransactionsMetrics =>
  metrics?.reduce(
    (acc, cur) => {
      const { count, sum, min, max } = cur

      acc.count += count
      acc.sum += sum
      acc.avg = acc.sum / acc.count
      acc.min = Math.min(acc.min, min)
      acc.max = Math.max(acc.max, max)

      return acc
    },
    { location: { id: 'total', name: 'Total' }, count: 0, sum: 0, avg: 0, min: 0, max: 0 },
  )
