import { Button, ButtonProps } from '../Button'

import * as S from './EmptyState.styled'

interface EmptyStateProps {
  title: string
  subtitle: string
  icon: JSX.Element
  buttonProps?: ButtonProps
  style?: React.CSSProperties
}

export const EmptyState = ({ title, subtitle, icon, buttonProps, style }: EmptyStateProps) => {
  return (
    <S.$Card style={{ ...style }}>
      <S.$Icon>{icon}</S.$Icon>
      <S.$Title>{title}</S.$Title>
      <S.$Subtitle>{subtitle}</S.$Subtitle>
      {buttonProps && (
        <S.$ButtonContainer>
          <Button {...buttonProps} />
        </S.$ButtonContainer>
      )}
    </S.$Card>
  )
}
