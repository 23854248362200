import { x } from '@xstyled/styled-components'
import { Modal } from 'src/components'

import { EntryError } from './components'

interface ErrorsModalProps {
  isOpen: boolean
  onClose: () => void
  errors?: CSVEntryError[]
}

export const ErrorsModal = ({ isOpen, onClose, errors }: ErrorsModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 'fit-content' }}
      title="Your CSV contains the following errors"
      body={
        <x.div display="flex">
          <x.div display="flex" flexDirection="column" gap="20px">
            {errors?.map((error, idx) => (
              <EntryError key={`error-${idx}`} data={error} />
            ))}
          </x.div>
        </x.div>
      }
      onClose={onClose}
    />
  )
}
