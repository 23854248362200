import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: row;
`

export const $Body = styled.divBox`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  max-width: calc(100% - 220px);
  background-color: grey4;
  will-change: max-width;
  position: relative;

  ${Styles.screens.small`
		max-width: calc(100%);
	`}
`
