import React from 'react'
import { HashLoader } from 'react-spinners'

import * as S from './OverlaySpinner.styled'

type OverlaySpinnerProps = Partial<React.ComponentProps<typeof HashLoader>> & { opaque?: boolean }

export const OverlaySpinner = ({
  loading = true,
  color = '#02b88d',
  size = 80,
  opaque = false,
  ...rest
}: OverlaySpinnerProps) => {
  return (
    <S.$Container className="OverlaySpinner" data-loading={loading} data-opaque={opaque}>
      <HashLoader loading={loading} color={color} size={size} {...rest} />
    </S.$Container>
  )
}
