import _ from 'lodash'
import { Condition, Table } from 'src/components'
import { getFormattedTimestampInterval } from 'src/utils'

import { GroupBy, IEvReport } from '../EvReports'

interface EvReportsTableProps {
  reports: IEvReport[]
  fetchingReports: boolean
  groupBy: GroupBy
  timestamp: { gt: string; lt: string }
}

export const EvReportsTable = ({ reports, fetchingReports, groupBy, timestamp }: EvReportsTableProps) => {
  return (
    <Condition when={!!reports}>
      <Table
        items={reports}
        summary={reports ? getEvReportsSummary(reports) : undefined}
        heading={`EV Transactions by ${_.capitalize(groupBy)} (${getFormattedTimestampInterval(timestamp)})`}
        headers={[
          groupBy === 'location' ? { key: 'location', format: ({ name }) => name } : { key: 'date' },
          {
            customKey: 'Total Revenue',
            format: (_, { totalRevenue }) => `£ ${totalRevenue.toFixed(2)}`,
            align: 'center',
          },
          {
            customKey: 'Parking Revenue',
            format: (_, { parkingRevenue }) => `£ ${parkingRevenue.toFixed(2)}`,
            align: 'center',
          },
          {
            customKey: 'Charging Revenue',
            format: (_, { chargingRevenue }) => `£ ${chargingRevenue.toFixed(2)}`,
            align: 'center',
          },
          {
            customKey: 'Consumed KWh',
            format: (_, { consumedKWh }) => `${consumedKWh.toFixed(2)} KWh`,
            align: 'center',
          },
        ]}
        loading={fetchingReports}
      />
    </Condition>
  )
}

const getEvReportsSummary = (reports: IEvReport[]): IEvReport => {
  return reports?.reduce(
    (accumulator, currentReport) => {
      const { chargingRevenue, consumedKWh, parkingRevenue, totalRevenue } = currentReport

      const newChargingRevenue = accumulator.chargingRevenue + chargingRevenue
      const newConsumedKWh = accumulator.consumedKWh + consumedKWh
      const newParkingRevenue = accumulator.parkingRevenue + parkingRevenue
      const newTotalRevenue = accumulator.totalRevenue + totalRevenue

      accumulator.chargingRevenue = newChargingRevenue
      accumulator.consumedKWh = newConsumedKWh
      accumulator.parkingRevenue = newParkingRevenue
      accumulator.totalRevenue = newTotalRevenue

      return accumulator
    },
    {
      location: { id: 'total', name: 'Total' },
      chargingRevenue: 0,
      consumedKWh: 0,
      parkingRevenue: 0,
      totalRevenue: 0,
    },
  )
}
