import * as S from './RowFields.styled'

export default function RowFields({
  children,
  ...containerProps
}: { children: Children } & SCProps<typeof S.$Container>) {
  return (
    <S.$Container className="row-fields" {...containerProps}>
      {children}
    </S.$Container>
  )
}
