import { DatePicker as AntdDatePicker, DatePickerProps as AntdDatePickerProps } from 'antd'

import * as S from './DatePicker.styled'

export const DatePicker = (props: AntdDatePickerProps) => {
  return (
    <S.$Container>
      <AntdDatePicker className="date-picker" {...props} />
    </S.$Container>
  )
}
