import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('* Required'),
  type: yup.string().oneOf(['regular', 'premium'], 'Select either REGULAR or PREMIUM').required('* Required'),
  locationId: yup.string().optional(),
  rates: yup
    .array()
    .of(
      yup.object({
        label: yup.string().required('* Required'),
        duration: yup.number().required('* Required'),
        price: yup.number().required('* Required'),
      }),
    )
    .min(1)
    .required('* Required'),
})

export default schema
