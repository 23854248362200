import { x } from '@xstyled/styled-components'
import React from 'react'

export interface FlexProps {
  direction?: React.CSSProperties['flexDirection']
  justify?: React.CSSProperties['justifyContent']
  align?: React.CSSProperties['alignItems']
  gap?: React.CSSProperties['gap']
  children?: JSX.Element[] | Children | React.ReactFragment
  wrap?: React.CSSProperties['flexWrap']
  style?: SCProps<typeof x.div>
  onClick?: () => void
}

export default function Flex({ direction, gap, justify, align, wrap, children, style, onClick }: FlexProps) {
  return (
    <x.div
      onClick={onClick}
      display="flex"
      flexDirection={direction}
      justifyContent={justify}
      alignItems={align}
      flexWrap={wrap}
      gap={gap}
      {...style}
    >
      {children}
    </x.div>
  )
}
