import React from 'react'

const SidebarContext = React.createContext({})

interface SidebarProviderProps {
  children: Children
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  return <SidebarContext.Provider value={{}}>{children}</SidebarContext.Provider>
}

export const useSidebarContext = () => React.useContext(SidebarContext)
