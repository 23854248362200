import HealthyIcon from '@mui/icons-material/BlurCircular'
import WarningIcon from '@mui/icons-material/BlurOn'
import DegradedIcon from '@mui/icons-material/BlurOff'
import DefaultIcon from '@mui/icons-material/FilterTiltShift'

import * as S from './DeviceHealthCard.styled'

interface DeviceHealthCardProps {
  label: string
  devicesCount: number
  active?: boolean
  onClick?: () => void
  variant: 'healthy' | 'warning' | 'degraded' | 'default'
}

export default function DeviceHealthCard({ label, devicesCount, variant, onClick, active }: DeviceHealthCardProps) {
  return (
    <S.$Container onClick={onClick} data-active={active} data-variant={variant}>
      <S.$Icon data-variant={variant}>
        {variant === 'healthy' && <HealthyIcon fontSize="inherit" />}
        {variant === 'warning' && <WarningIcon fontSize="inherit" />}
        {variant === 'degraded' && <DegradedIcon fontSize="inherit" />}
        {variant === 'default' && <DefaultIcon fontSize="inherit" />}
      </S.$Icon>
      <div className="dashboard__devices-status-card__details">
        <S.$CardLabel>{label}</S.$CardLabel>
        <S.$CardCount>
          {devicesCount} <span>devices</span>
        </S.$CardCount>
      </div>
    </S.$Container>
  )
}
