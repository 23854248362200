import { Portal } from '@mui/base'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { useAnimation, useDebounce } from 'src/hooks'

import { Button, ButtonProps } from '../Button'
import { CardProps } from '../Card'
import { Condition } from '../Condition'

import * as S from './Modal.styled'

export interface ModalProps {
  title: string | JSX.Element
  body?: string | JSX.Element
  children?: Children
  isOpen?: boolean
  confirmText?: string
  cancelText?: string
  variant?: ButtonProps['variant']
  cardStyle?: CardProps['style']
  onConfirm?: () => void | Promise<void>
  onCancel?: () => void
  onClose?: () => void
  onOpen?: () => void
  onConfirmError?: (err?: any) => void
  hasErrors?: boolean
  style?: SCProps<typeof S.$Container>
}

export function Modal({
  title,
  body,
  isOpen = false,
  onConfirm,
  onCancel,
  onClose,
  onOpen,
  onConfirmError,
  confirmText = 'Okay',
  cancelText = 'Cancel',
  variant = 'destructive',
  cardStyle,
  children,
  hasErrors = false,
}: ModalProps) {
  const debouncedIsOpen = useDebounce(isOpen, 300)
  const [cardRef] = useAnimation({ entered: isOpen })
  const [isConfirming, setIsConfirming] = React.useState<boolean>(false)

  const [backdropRef] = useAnimation({ entered: isOpen })

  React.useEffect(() => {
    if (!isOpen) return
    onOpen?.()
  }, [isOpen])

  return (
    <Condition when={isOpen || debouncedIsOpen}>
      <Portal>
        <S.$Container>
          <S.$Card ref={cardRef} pointerEvents={isOpen ? 'auto' : 'none'} {...cardStyle}>
            <S.$Header>
              <S.$Title>{title}</S.$Title>
              <S.$Close fontSize={22} onClick={onClose} cursor="pointer">
                <CloseIcon fontSize="inherit" />
              </S.$Close>
            </S.$Header>
            <S.$Body>{body || children}</S.$Body>
            <S.$Footer>
              {onCancel && <Button text={cancelText} variant="secondary" onClick={onCancel || onClose} />}
              {onConfirm && (
                <Button
                  text={confirmText}
                  variant={variant}
                  loading={isConfirming}
                  onClick={async () => {
                    try {
                      setIsConfirming(true)
                      await onConfirm()
                    } catch (error) {
                      onConfirmError?.(error)
                    } finally {
                      setIsConfirming(false)
                    }
                  }}
                  disabled={hasErrors}
                />
              )}
            </S.$Footer>
          </S.$Card>
          <S.$Backdrop ref={backdropRef as any} onClick={onClose} pointerEvents={isOpen ? 'all' : 'none'} />
        </S.$Container>
      </Portal>
    </Condition>
  )
}
