import { DatePickerProps } from 'antd'
import { Field, FieldProps } from 'formik'

import { Error } from '../Error'
import { Label } from '../Label'

import { DatePicker } from './DatePicker'
import * as S from './FormikDatePicker.styled'

export interface FormikDatePickerProps<T> {
  label?: string
  disabled?: boolean
  type?: string
  name?: keyof T | string
  nestedName?: string
  error?: boolean | string
  onChange?: DatePickerProps['onChange']
  onBlur?: DatePickerProps['onBlur']
  autoFocus?: boolean
  value?: any
}

export function FormikDatePicker<T>({
  label,
  name,
  nestedName,
  type,
  onChange,
  onBlur,
  disabled = false,
  autoFocus = false,
  error,
  value,
  defaultValue,
  ...datePickerProps
}: FormikDatePickerProps<T> & DatePickerProps) {
  return (
    <Field name={name || nestedName} type={type} placeholder={'Input'}>
      {({ field, meta }: FieldProps) => {
        const hasError = !!(meta.touched && meta.error)
        const hasValue = !!field.value

        return (
          <S.$Container>
            <Label variant={!hasError && hasValue ? 'success' : 'default'}>{label}</Label>
            <DatePicker
              {...datePickerProps}
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? field?.value}
              defaultValue={defaultValue ?? undefined}
            />
            <Error visible={hasError}>{meta.error || error}</Error>
          </S.$Container>
        )
      }}
    </Field>
  )
}
