import * as yup from 'yup'

export const PermitEntryOverridesSchema = yup.object().shape({
  startDate: yup.string().nullable(true),
  endDate: yup.string().nullable(true),
  locationId: yup.string().required('Location is required'),
})

export interface IPermitEntryOverridesSchema {
  startDate: string | null
  endDate: string | null
  locationId: string
}
