import { ButtonProps } from '../Button'
import { Condition } from '../Condition'

import * as S from './Tag.styled'

export type TagColor = 'grey' | 'red' | 'yellow' | 'green' | 'blue' | 'purple' | 'dark' | 'orange' | 'light'

export interface TagProps {
  text?: string | JSX.Element | null
  variant?: TagColor
  button?: ButtonProps | boolean
  style?: SCProps<typeof S.$Container>
  children?: React.ReactNode
}

export default function Tag({ text = '', variant = 'grey', button, style, children }: TagProps) {
  return (
    <S.$Container data-variant={variant} {...style}>
      <S.$Dot mr="8px" data-variant={variant} />
      <Condition when={!children} fallback={children}>
        <S.$Text data-variant={variant}>{text}</S.$Text>
      </Condition>
      {button && button}
    </S.$Container>
  )
}
