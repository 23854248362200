import axios, { AxiosRequestConfig, Method } from 'axios'
import React, { useEffect } from 'react'
import { useClientCacheContext } from 'src/context'
import { clearEmpties } from 'src/utils'

type QueryString<T> = Partial<T> | Record<string, unknown>

export interface UseAxiosParams<T> {
  url?: string
  method?: Method
  params?: QueryString<T>
  runOnInit?: boolean
  pause?: boolean
  timeout?: number
  body?: any
  axiosOverrides?: AxiosRequestConfig
}

export interface UseAxiosData<T> {
  data?: T
  hasMore?: boolean
  setData?: (data: T | undefined, key?: string) => void
  fetching: boolean
  error?: boolean
}

function buildUrlWithParams<T>(url: string, params?: QueryString<T>): string {
  if (!params) return url

  const query = new URLSearchParams(clearEmpties(params)).toString()

  return `${url}?${query}`
}

export type UseAxiosFetch<T> = (params?: Partial<UseAxiosParams<T>>) => Promise<Nullable<T>>

export function useAxios<T>({
  url,
  method = 'GET',
  params,
  runOnInit = true,
  pause = false,
  axiosOverrides,
}: UseAxiosParams<T>): [UseAxiosData<T>, UseAxiosFetch<T>] {
  const { get, set } = useClientCacheContext()

  const urlWithParams = buildUrlWithParams(url || '', params)
  const defaultCacheKey = urlWithParams

  const fetching = React.useMemo(() => get<boolean>(`${urlWithParams}_fetching`), [get, urlWithParams])
  const error = React.useMemo(() => get<boolean>(`${urlWithParams}_error`), [get, urlWithParams])
  const data = React.useMemo(() => get<T>(urlWithParams), [get, urlWithParams, fetching, error])

  const setData = React.useCallback(
    (data: T | undefined, key?: string) => {
      if (method !== 'GET') return

      set(key || defaultCacheKey, data)
    },
    [set, url, runOnInit, pause, defaultCacheKey, method],
  )

  const setFetching = React.useCallback(
    (fetching: boolean, key: string) => {
      if (!key) return

      set(`${key}_fetching`, fetching)
    },
    [set, url, runOnInit, pause],
  )

  const setError = React.useCallback(
    (error: boolean, key: string) => {
      if (!key) return

      set(`${key}_error`, error)
    },
    [set, url, runOnInit, pause],
  )

  const makeRequest = async ({
    url: urlOverride = url,
    body: bodyOverride,
    method: methodOverride,
    params: paramsOverride,
    axiosOverrides: axiosOverridesExtra,
    timeout: timeoutOverride = 15000,
  }: Partial<UseAxiosParams<T>> = {}): Promise<Nullable<T>> => {
    if (pause) return await new Promise((resolve) => setTimeout(resolve, 500))

    const finalParams = paramsOverride || params

    const overrideUrlWithParams = buildUrlWithParams(urlOverride || '', finalParams)
    const overrideCacheKey = overrideUrlWithParams

    try {
      setFetching(true, overrideCacheKey)
      const source = axios.CancelToken.source()

      const timeout = setTimeout(() => {
        source.cancel()
      }, timeoutOverride)

      const request = axios({
        method: methodOverride || method,
        data: bodyOverride || undefined,
        url: overrideUrlWithParams,
        cancelToken: source.token,
        ...(axiosOverridesExtra || axiosOverrides || {}),
      })

      const { data: res } = await request
      clearTimeout(timeout)
      setError(false, overrideCacheKey)

      if (!methodOverride || methodOverride === 'GET') {
        setData(res, overrideCacheKey)
      }
      return res
    } catch (err: any) {
      setError(true, overrideCacheKey)
      throw err // TODO this is not handled wherever the function is used
    } finally {
      setFetching(false, overrideCacheKey)
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()

    if (pause) {
      source.cancel()
      return
    }

    const getData = async () => {
      const cacheKey = urlWithParams

      try {
        setFetching(true, cacheKey)

        if (url && runOnInit && (method === 'GET' || method === 'get')) {
          const request = axios({ method: method, url, cancelToken: source.token, params, ...axiosOverrides })

          const { data: res } = await request
          setData(res, cacheKey)
        }

        setError(false, cacheKey)
      } catch (err: any) {
        setError(true, cacheKey)
      } finally {
        setFetching(false, cacheKey)
      }
    }

    getData()

    return () => {
      source.cancel()
    }

    // eslint-disable-next-line
  }, [method, url, runOnInit, pause])

  return [{ data, setData, fetching, error }, makeRequest as UseAxiosFetch<T>]
}
