import { Anchor } from '@mantine/core'
import ViewIcon from '@mui/icons-material/AspectRatio'
import DownloadDevicesIcon from '@mui/icons-material/BrowserUpdated'
import OrganisationsIcon from '@mui/icons-material/CorporateFare'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { Button, Condition, EmptyState, Flex, Table } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { IDevice } from 'src/typings/device'
import { genQueryParams } from 'src/utils'

import { DeleteOrgModal, OrganisationModal, ViewOrganisationModal } from './Modals'

export function Organisations() {
  const { organisations = [], fetchingOrgData, refetchOrgData } = useOrganisationContext()
  const toast = useToast()
  const [searchOrganisations, setSearchOrganisations] = React.useState<Organisation[]>()

  const [organisationModalOpen, setOrganisationModalOpen] = React.useState<boolean>(false)
  const [viewOrganisationModalOpen, setViewOrganisationModalOpen] = React.useState<boolean>(false)
  const [delOrgModalOpen, setDelOrgModalOpen] = React.useState(false)

  const [organisationToEdit, setOrganisationToEdit] = React.useState<Organisation>()
  const [organisationToView, setOrganisationToView] = React.useState<Organisation>()

  const [, fetchDevicesExport] = useAxios<IDevice>({ url: `${BE_PATHS.DEVICES}/export`, runOnInit: false })

  const openOrganisationModal = (org?: Organisation) => {
    org && setOrganisationToEdit(org)
    setOrganisationModalOpen(true)
  }

  const [organisationToDelete, setOrganisationToDelete] = React.useState<Organisation>()

  const handleDelete = async (organisation: Organisation) => {
    setOrganisationToDelete(organisation)
    setDelOrgModalOpen(true)
  }

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setSearchOrganisations([
        ...organisations.filter(({ name }) => {
          if (name?.toLowerCase().includes(searchTerm?.toLowerCase())) return true
          return false
        }),
      ])
    } else {
      setSearchOrganisations(undefined)
    }
  }

  return (
    <Page
      searchPlaceholder="Search for organisations (name)"
      onSearch={handleSearch}
      endSlot={
        <Condition when={organisations.length > 0}>
          <Button text="+ Add" onClick={() => openOrganisationModal()} variant="accent" />
        </Condition>
      }
    >
      <Shell title="Organisations" subtitle="" pt="30px">
        {!fetchingOrgData && organisations.length === 0 && (
          <EmptyState
            title="There are no organisations currently set up"
            subtitle="Organisations are a great way for you to track the status and remote functionality of equipment deployed on field. Start by adding a new organisation. This will then appear here."
            icon={<OrganisationsIcon />}
            buttonProps={{
              text: 'Create a organisation',
              variant: 'accent',
              onClick: () => openOrganisationModal(),
              style: { h: '50px' },
            }}
          />
        )}
        {organisations.length > 0 && (
          <Table
            items={searchOrganisations || organisations}
            headers={[
              {
                customKey: 'organisation',
                align: 'left',
                render: (_, org) => (
                  <Anchor
                    onClick={() => {
                      setOrganisationToView(org)
                      setViewOrganisationModalOpen(true)
                    }}
                  >
                    <x.span fontSize="12px" color="dark">
                      {org?.name}
                    </x.span>
                  </Anchor>
                ),
              },
              {
                customKey: 'locations',
                align: 'center',
                render: (_, item: Organisation) => {
                  return <Flex direction="column">{item?.locations?.length || '—'}</Flex>
                },
              },
              {
                customKey: 'devices',
                align: 'center',
                render: (_, item: Organisation) => {
                  return <Flex direction="column">{item?.devices?.length || '—'}</Flex>
                },
              },
              {
                customKey: 'users',
                align: 'center',
                render: (_, item: Organisation) => {
                  return <Flex direction="column">{item?.users?.length || '—'}</Flex>
                },
              },
            ]}
            actions={[
              {
                label: 'View',
                onClick: (item) => {
                  setOrganisationToView(item)
                  setViewOrganisationModalOpen(true)
                },
                icon: <ViewIcon />,
              },
              {
                label: 'Download devices',
                onClick: async (item) => {
                  toast('Downloading devices...', { variant: 'default' })
                  const data = await fetchDevicesExport({
                    params: { filter: genQueryParams({ organisationId: item?.id }) },
                  })

                  if (!data) return

                  const url = window.URL.createObjectURL(new Blob([data as any]))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', 'firmware_remote_devices.txt')
                  document.body.appendChild(link)
                  link.click()
                },
                icon: <DownloadDevicesIcon />,
              },
              {
                label: 'Edit',
                onClick: (item) => openOrganisationModal(item),
                type: 'edit',
              },
              { label: 'Delete', onClick: handleDelete, type: 'delete' },
            ]}
          />
        )}
        <OrganisationModal
          isOpen={organisationModalOpen}
          organisation={organisationToEdit}
          onSubmitSuccess={() => refetchOrgData()}
          onClose={() => {
            setOrganisationModalOpen(false)
            setOrganisationToEdit(undefined)
          }}
        />
        <ViewOrganisationModal
          isOpen={viewOrganisationModalOpen}
          organisation={organisationToView as Organisation}
          onClose={() => setViewOrganisationModalOpen(false)}
        />
        <DeleteOrgModal
          isOpen={delOrgModalOpen}
          onClose={() => setDelOrgModalOpen(false)}
          organisation={organisationToDelete as Organisation}
          onDelete={() => refetchOrgData()}
        />
      </Shell>
    </Page>
  )
}
