import CellTowerIcon from '@mui/icons-material/CellTower'
import { useSnackbar } from 'notistack'
import { Button } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'

interface PingDeviceButtonProps {
  deviceName: string
  deviceId: string
}
export const PingDeviceButton = ({ deviceName, deviceId }: PingDeviceButtonProps) => {
  const [{ fetching: pingingDevice }, pingDevice] = useAxios({
    url: BE_PATHS.DEVICE_HEALTH(deviceId),
    runOnInit: false,
  })

  const { enqueueSnackbar } = useSnackbar()

  return (
    <Button
      icon={<CellTowerIcon fontSize="inherit" />}
      style={{
        marginLeft: 'auto',
        borderRadius: '50%',
        padding: 1,
        minWidth: '24px',
        w: '24px',
        maxWidth: '24px',
        minHeight: '24px',
        h: '24px',
        maxHeight: '24px',
        fontSize: '18px',
      }}
      variant="secondary"
      loading={pingingDevice}
      loaderSize={24}
      loaderColor="blue"
      disabled={pingingDevice}
      onClick={async () => {
        try {
          await pingDevice()
          enqueueSnackbar(`Successfully connected to kiosk ${deviceName}.`, { variant: 'success' })
        } catch (error) {
          enqueueSnackbar(`Failed to connect to kiosk ${deviceName}.`, { variant: 'error' })
        }
      }}
    />
  )
}
