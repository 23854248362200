import { useInterval } from '@mantine/hooks'
import BayIcon from '@mui/icons-material/SettingsInputSvideo'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { Button, Condition, EmptyState, Table } from 'src/components'
import { useAuthContext } from 'src/context'

import { BayModal, DeleteBayModal } from './components'
import { useBayTableHeaders } from './components/getTableHeaders'

interface LocationBaysProps {
  bays?: IBay[]
  locationId: string
  onDataRefetch: () => void
}

export const LocationBays = ({ bays, locationId, onDataRefetch }: LocationBaysProps) => {
  const { isAdmin } = useAuthContext()

  const [bayModalOpen, setBayModalOpen] = React.useState<boolean>(false)
  const [deleteBayModalOpen, setDeleteBayModalOpen] = React.useState<boolean>(false)

  const [bayToEdit, setBayToEdit] = React.useState<IBay>()
  const [bayToDelete, setBayToDelete] = React.useState<IBay>()

  const bayTableHeaders = useBayTableHeaders(locationId)

  const interval = useInterval(onDataRefetch, 5000)

  React.useEffect(() => {
    interval.start()

    return interval.stop

    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Condition when={isAdmin}>
        <x.div mt="10px" mb="20px" w="100%" display="flex">
          <Button text="+ Add bay" variant="accent" onClick={() => setBayModalOpen(true)} />
        </x.div>
      </Condition>
      {bays && bays?.length === 0 && (
        <EmptyState
          title="There are no bays currently set up"
          subtitle="Bays are a great way for you to configure and monitor the status and remote functionality of equipment deployed in various locations."
          icon={<BayIcon />}
        />
      )}
      {bays && bays.length > 0 && (
        <Table
          items={bays}
          headers={bayTableHeaders}
          actions={
            isAdmin
              ? [
                  {
                    type: 'edit',
                    label: 'Edit',
                    onClick: (bay: IBay) => {
                      setBayToEdit(bay)
                      setBayModalOpen(true)
                    },
                  },
                  {
                    type: 'delete',
                    label: 'Delete',
                    onClick: (bay: IBay) => {
                      setBayToDelete(bay)
                      setDeleteBayModalOpen(true)
                    },
                  },
                ]
              : undefined
          }
        />
      )}
      <Condition when={isAdmin}>
        <BayModal
          isOpen={bayModalOpen}
          bay={bayToEdit}
          onSubmitSuccess={async () => {
            await onDataRefetch()
          }}
          onClose={() => {
            setBayModalOpen(false)
            setBayToEdit(undefined)
          }}
        />
      </Condition>
      <Condition when={isAdmin}>
        <DeleteBayModal
          bay={bayToDelete as IBay}
          isOpen={deleteBayModalOpen}
          onClose={() => setDeleteBayModalOpen(false)}
          onDelete={async () => {
            await onDataRefetch()
          }}
        />
      </Condition>
    </div>
  )
}
