import styled from '@xstyled/styled-components'
import { Select, SelectProps } from 'antd'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`

export const $Select = styled(Select)<SelectProps>`
  &&& {
    .ant-select-selector {
      width: 100%;
      border: 1px solid;
      border-color: grey12;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      line-height: 24px;
      padding: 6px 12px;
      min-height: 32px;
      transition: all 0.3s ease-in-out;
    }

    .ant-select-selection-search {
      margin-inline-start: 0px;
    }
  }
`
