import PermitAccountIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import { Button, Condition, EmptyState, Flex } from 'src/components'
import { FE_PATHS } from 'src/constants'
import { Page, Shell } from 'src/containers'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { useDebounce, useRedirect, useToast } from 'src/hooks'

import { DeletePermitModal, PermitModal, PermitsTable } from './components'
import { usePermits } from './hooks'

export function Permits() {
  const { fetchingOrgData, organisation } = useOrganisationContext()
  const { isAdmin, isManager } = useAuthContext()
  const isAtLeastManager = isAdmin || isManager

  const toast = useToast()

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)

  const [focusedPermit, setFocusedPermit] = React.useState<IPermit>()
  const [isPermitModalOpen, setIsPermitModalOpen] = React.useState<boolean>(false)

  const [isDeletePermitModalOpen, setIsDeletePermitModalOpen] = React.useState<boolean>(false)

  const [page, setPage] = React.useState<number>(0)

  const [{ data: permits = [], fetching: fetchingPermits, hasMore: hasMorePermits }, refetchPermits] = usePermits({
    page,
    searchTerm: debouncedSearchTerm,
  })

  const redirectToImport = useRedirect(FE_PATHS.PERMITS_IMPORT)

  React.useEffect(() => {
    if (!organisation?.id) return

    refetchPermits()
  }, [organisation?.id])

  return (
    <Page
      searchPlaceholder="Search permits by reference or VRM"
      onSearch={(search) => {
        setSearchTerm(search)
        setPage(0)
      }}
      loading={fetchingOrgData || fetchingPermits}
      endSlot={
        <Flex gap="10px">
          {isAdmin && <Button text="Import" onClick={redirectToImport} variant="secondary" />}
          {isAtLeastManager && <Button text="+ Add" onClick={() => setIsPermitModalOpen(true)} variant="accent" />}
        </Flex>
      }
    >
      <Shell title="Permits" subtitle="Manage permits and their permissions" loading={!permits && fetchingOrgData}>
        {!fetchingOrgData && !fetchingPermits && permits && permits.length === 0 && (
          <EmptyState
            title="No permits added yet"
            subtitle="Start by adding a new permit. These will then appear here."
            icon={<PermitAccountIcon />}
            {...(isAtLeastManager && {
              buttonProps: {
                text: 'Create permit',
                variant: 'accent',
                onClick: () => setIsPermitModalOpen(true),
                style: { h: '50px' },
              },
            })}
          />
        )}

        <Condition when={!!permits && permits.length > 0}>
          <PermitsTable
            permits={permits}
            loading={fetchingPermits}
            hasMoreItems={hasMorePermits}
            onLoadMore={() => !fetchingPermits && setPage(page + 1)}
            onEdit={(item) => {
              setFocusedPermit(item)
              setIsPermitModalOpen(true)
            }}
            onDelete={(item) => {
              setFocusedPermit(item)
              setIsDeletePermitModalOpen(true)
            }}
          />
        </Condition>

        <PermitModal
          isOpen={isPermitModalOpen}
          permit={focusedPermit}
          onSubmitSuccess={async () => {
            await refetchPermits()
            setFocusedPermit(undefined)
          }}
          onClose={() => {
            setIsPermitModalOpen(false)
            setFocusedPermit(undefined)
          }}
        />

        <DeletePermitModal
          isOpen={isDeletePermitModalOpen}
          onClose={() => setIsDeletePermitModalOpen(false)}
          permit={focusedPermit as IPermit}
          onDelete={async (permit) => {
            await refetchPermits()

            toast(`Permit ${permit.reference} deleted successfully`, { variant: 'success' })

            setFocusedPermit(undefined)
            setIsDeletePermitModalOpen(false)
          }}
        />
      </Shell>
    </Page>
  )
}
