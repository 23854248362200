import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const $Tab = styled.divBox`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 0;
  color: darkGray;
  width: 220px;
  cursor: pointer;

  transition: all 0.3s ease;

  &[data-compact='true'] {
    padding: 0;
  }

  ${Styles.screens.small`
		padding: 20px 15px 10px 15px;
	`}
`

export const $CompanyContainer = styled.divBox`
  font-weight: 600;
`

export const $CompanyLogo = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 20px;
  overflow: hidden;
  min-height: 50px;
  min-width: 50px;
  overflow: hidden;

  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
  }

  &[data-compact='true'] {
    min-width: 26px;
    min-height: 26px;

    img {
      width: 26px;
      height: 26px;
    }
  }
`

export const $UserType = styled.h3Box`
  color: lightGray;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`

export const $CompanyAccount = styled.h4Box`
  color: light;
  font-size: 16px;

  &[data-compact='true'] {
    font-size: 12px;
    color: light;
  }
`

export const $Icons = styled.divBox`
  display: flex;
  flex-direction: column;

  svg {
    margin: -5px 0;
  }
`
