import { Form, FormikErrors, useFormikContext } from 'formik'
import React from 'react'
import { Divider, FormikSelectDropdown, FormikSelectDropdownProps, InputField, InputFieldProps } from 'src/components'

const BarrierInputField = (props: InputFieldProps<Partial<IBarrier>>) => InputField<Partial<IBarrier>>(props)
const CameraSelectDropdown = (props: FormikSelectDropdownProps<Partial<ICamera>>) =>
  FormikSelectDropdown<Partial<ICamera>>(props)

interface BarrierFormProps {
  onChange?: (data: Partial<IBarrier>) => void
  barrier?: Partial<IBarrier>
  cameras?: Partial<ICamera>[]
  isActive?: boolean
  errors?: FormikErrors<Partial<IBarrier>>
}

export default function BarrierForm({ barrier, cameras, onChange, isActive, errors }: BarrierFormProps) {
  const formik = useFormikContext<Partial<IBarrier>>()

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(barrier ?? {}, false)
    // eslint-disable-next-line
  }, [location, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>DETAILS</strong>
      </h4>
      <Divider light />
      <br />
      <BarrierInputField placeholder="The barrier's name" name="name" label="Name" error={errors?.name} />
      <CameraSelectDropdown
        placeholder="Select the camera"
        name="cameraId"
        error={errors?.cameraId}
        label="Camera"
        multi={false}
        // TODO: display only barriers that are not in use maybe?
        items={cameras?.map((camera) => ({ label: camera?.name || '', value: camera?.id || '' }))}
      />
      <BarrierInputField placeholder="The barrier's domain" name="domain" label="Domain" error={errors?.domain} />
      <BarrierInputField placeholder="The barrier's port" type="number" name="port" label="Port" error={errors?.port} />
      <BarrierInputField
        placeholder="The barrier's pulse channel"
        name="channel"
        type="number"
        label="Pulse channel"
        error={errors?.channel}
      />
      <BarrierInputField
        placeholder="The barrier's toggle channel"
        name="toggleChannel"
        type="number"
        label="Toggle channel"
        error={errors?.toggleChannel}
      />
      <BarrierInputField
        placeholder="The barrier's username"
        name="username"
        label="Username"
        error={errors?.username}
      />
      <BarrierInputField
        placeholder="The barrier's password"
        name="password"
        label="Password"
        type="password"
        error={errors?.password}
      />
    </Form>
  )
}
