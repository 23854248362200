import styled from '@xstyled/styled-components'
import * as RadixCheckbox from '@radix-ui/react-checkbox'

export const Container = styled.divBox`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`

export const Label = styled.labelBox`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;

  &[data-state='unchecked'] {
    > * {
      color: grey48;
    }
  }
`

export const Indicator = styled(RadixCheckbox.Indicator)``

export const Root = styled(RadixCheckbox.Root)`
  padding: 0px;
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;

  appearance: none;
  background-color: transparent;
  border: 1px solid;
  border-color: grey16;
  border-radius: 3px;
  outline: none;
  transition: background-color 0.14s ease-in-out, border-color 0.14s ease-in-out;

  :focus {
    outline: none;
  }

  &[data-state='checked'] {
    border-color: accent;
    background-color: accent;

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: ${'white'};
    }
  }
`
