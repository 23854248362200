import { Condition } from '../Condition'
import { TagColor } from '../Tag'

import * as S from './Badge.styled'
interface BadgeProps {
  text?: JSX.Element | string | null
  variant: TagColor
  style?: SCProps<typeof S.$Container>
  textStyle?: SCProps<typeof S.$Text>
  children?: Children
  onClick?: () => void
}

export default function Badge({ text, variant, style, textStyle, children, onClick }: BadgeProps) {
  return (
    <S.$Container data-variant={variant} {...style} onClick={onClick}>
      <Condition when={!children} fallback={children}>
        <S.$Text {...textStyle}>{text}</S.$Text>
      </Condition>
    </S.$Container>
  )
}
