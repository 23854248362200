import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { Condition, Divider, FormikDatePicker, SelectDropdown, Text } from 'src/components'
import { BE_PATHS, FE_PATHS } from 'src/constants'
import { FORMATS } from 'src/constants/formats'
import { Page, Shell } from 'src/containers'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'
import { getISOString } from 'src/utils'

import { CSVUploader, ErrorsModal, ImportError, PermitsProcessor } from './components'
import { IPermitEntryOverridesSchema, PermitEntryOverridesSchema } from './ImportOverrides.schema'
import * as S from './PermitsImport.styled'

export const PermitsImport = () => {
  const { organisation, fetchingOrgData, locations } = useOrganisationContext()
  const { user } = useAuthContext()

  const toast = useToast()

  const [file, setFile] = React.useState<File>()

  const [entries, setEntries] = React.useState<PermitEntry[]>()
  const [entryErrors, setEntryErrors] = React.useState<CSVEntryError[]>()

  const [isErrorsModalOpen, setIsErrorsModalOpen] = React.useState<boolean>(false)

  const hasErrors = !!entryErrors && entryErrors.length > 0

  const [, parseCsv] = useAxios<ParsePermitsCSVResponse>({ url: BE_PATHS.PARSE_CSV, method: 'POST', runOnInit: false })

  const updateEntries = React.useCallback(
    (update: Partial<PermitEntry & IPermitEntryOverridesSchema>) => {
      const newEntries = entries?.map((entry) => {
        const location = locations?.find((loc) => loc.id === update?.locationId)
        const timezone = location?.timezone ?? 'Europe/London'

        const startDate = update.startDate ?? entry.startDate
        const endDate = update.endDate ?? entry.endDate

        const isDateValid = (date: string) => dayjs(date).isValid()

        const permitEntry: Partial<IPermit> = {
          ...entry,
          locationId: update?.locationId,
          startDate: isDateValid(startDate) ? getISOString({ date: startDate, tz: timezone }) : undefined,
          endDate: isDateValid(endDate) ? getISOString({ date: endDate, tz: timezone }) : undefined,
          creatorId: user?.id,
          organisationId: organisation?.id,
          activeTimeIntervalsByDays: null,
        }

        return permitEntry
      })

      return newEntries
    },
    [entries, user?.id, locations, organisation?.id],
  )

  const resetImport = () => {
    setEntries(undefined)
    setEntryErrors(undefined)
    setFile(undefined)
  }

  return (
    <Page
      breadcrumb={{
        items: [
          { title: 'Permits', href: FE_PATHS.PERMITS },
          { title: 'Import', href: FE_PATHS.PERMITS_IMPORT },
        ],
      }}
      loading={fetchingOrgData}
    >
      <Shell maxWidth="950px">
        <S.Section mt="20px">
          <Text type="h3">Upload a file</Text>
          <Text type="p" color="grey48" mb="20px">
            Upload a CSV file to import permits
          </Text>
          <x.div display="flex" flexDirection="column" gap="4px">
            <CSVUploader
              file={file}
              onFileUpload={async (newFile) => {
                setEntries(undefined)
                setEntryErrors(undefined)
                setFile(newFile)

                const fileText = await newFile.text()

                try {
                  const result = await parseCsv({ body: { csvText: fileText } })
                  setEntries(result?.entries)
                  setEntryErrors(result?.errors)
                } catch (error) {
                  toast("Couldn't parse CSV. Please try again later.", { variant: 'error' })
                }
              }}
            />
            <Condition when={hasErrors}>
              <ImportError onShowErrors={() => setIsErrorsModalOpen(true)} />
              <ErrorsModal
                isOpen={isErrorsModalOpen}
                onClose={() => setIsErrorsModalOpen(false)}
                errors={entryErrors}
              />
            </Condition>
          </x.div>
        </S.Section>

        <Condition when={entries && !hasErrors}>
          <Formik<IPermitEntryOverridesSchema>
            initialValues={{
              locationId: '',
              startDate: moment(dayjs().format('YYYY-MM-DDT00:00:00')).toString(),
              endDate: moment('2038-01-01T00:00:00').toString(),
            }}
            validationSchema={PermitEntryOverridesSchema}
            onSubmit={() => {}}
          >
            {({ values, touched, errors, isValid, setFieldValue, setFieldTouched }) => {
              return (
                <>
                  <Divider light my="30px" />

                  <S.Section mt="20px">
                    <Text type="h3">Update all entries</Text>
                    <Text type="p" color="grey48" mb="20px">
                      Update all permit entries with the following values
                    </Text>
                    <x.div
                      display="flex"
                      gap="30px"
                      bg="white"
                      p="20px"
                      border="1px solid"
                      borderColor="grey16"
                      borderRadius="10px"
                    >
                      <SelectDropdown
                        label="Location"
                        items={locations?.map((location) => ({ label: location.name, value: location.id })) ?? []}
                        onSelect={(selected) => {
                          const locationId = selected?.[0]?.value
                          setFieldValue('locationId', locationId, true)
                        }}
                        value={values?.locationId}
                        disabled={!locations || locations.length === 0}
                        error={touched.locationId && errors?.locationId ? 'Please select a location' : undefined}
                        onBlur={() => setFieldTouched('locationId', true)}
                      />
                      <FormikDatePicker
                        picker="date"
                        showTime={{ minuteStep: 5 }}
                        allowClear
                        name="startDate"
                        label="Start Date"
                        value={values.startDate ? moment(values.startDate) : undefined}
                        onChange={(date) => {
                          setFieldValue('startDate', date)
                        }}
                        onBlur={() => setFieldTouched('startDate', true, true)}
                        error={errors?.startDate}
                        format={FORMATS.DATETIMELONG}
                      />
                      <FormikDatePicker
                        picker="date"
                        showTime={{ minuteStep: 5 }}
                        allowClear
                        name="endDate"
                        label="End Date"
                        value={values.endDate ? moment(values.endDate) : undefined}
                        onChange={(date) => {
                          setFieldValue('endDate', date)
                        }}
                        onBlur={() => setFieldTouched('endDate', true, true)}
                        error={errors?.endDate}
                        format={FORMATS.DATETIMELONG}
                      />
                    </x.div>
                  </S.Section>

                  <Condition when={!!values?.locationId}>
                    <Divider light my="30px" />

                    <S.Section mt="20px">
                      <Text type="h3">Submit permits</Text>
                      <Text type="p" color="grey48" mb="20px">
                        Submit the permits for processing
                      </Text>
                      <PermitsProcessor
                        disabled={!isValid}
                        permits={
                          updateEntries({
                            locationId: values.locationId,
                            startDate: values.startDate ?? undefined,
                            endDate: values.endDate ?? undefined,
                          }) as PermitEntry[]
                        }
                        onSubmitError={(error) => {
                          const unknownErrorMessage = 'Failed processing permits. Please try again later'
                          const invalidCsvMessage = 'Please fix your CSV file and try again'
                          const message = error?.response?.status === 400 ? invalidCsvMessage : unknownErrorMessage

                          toast(message, { variant: 'error' })
                        }}
                        onSubmitSuccess={() => {
                          toast('Permits have been successfully imported', { variant: 'success' })
                        }}
                        onDone={resetImport}
                        onRetry={resetImport}
                      />
                    </S.Section>
                  </Condition>
                </>
              )
            }}
          </Formik>
        </Condition>
      </Shell>
    </Page>
  )
}
