import styled from '@xstyled/styled-components'

import { CardStyled } from '../Card'

export const $Card = styled(CardStyled.$Container)`
  margin: auto;
  margin-top: 40px;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  padding: 30px;

  svg {
    font-size: 60px;
    color: accent;
  }
`

export const $Icon = styled.divBox`
  margin-bottom: 20px;
`

export const $Title = styled.h2Box`
  color: dark;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin: 5px 0;
`

export const $Subtitle = styled.h3Box`
  color: darkGray;
  font-size: 14px;
  text-align: center;
  margin: 0;
  max-width: 550px;
`

export const $ButtonContainer = styled.divBox`
  margin-top: 20px;
  font-size: 12px;
`
