import styled from '@xstyled/styled-components'

export const $Label = styled.labelBox`
  color: grey100;
  position: relative;
  font-size: 12px;
  font-weight: 600;

  transition: all 0.3s ease;

  &[data-error='true'] {
    color: danger;
  }

  &[data-disabled='true'] {
    color: grey24;
  }
`
