import React from 'react'

import { Loader } from '../Loader'
import { LoaderProps } from '../Loader/Loader'

import * as S from './Button.styled'

export interface ButtonProps {
  text?: string | JSX.Element
  onClick?: () => void
  variant?: 'primary' | 'secondary' | 'accent' | 'destructive'
  disabled?: boolean
  loading?: boolean
  loaderColor?: LoaderProps['color']
  loaderSize?: LoaderProps['size']
  fontSize?: number | string
  icon?: React.ReactElement
  type?: 'submit' | 'reset' | 'button'
  iconPosition?: 'before' | 'after'
  style?: SCProps<typeof S.$Container>
}

export const Button = ({
  text,
  onClick,
  variant = 'primary',
  disabled = false,
  loading,
  fontSize,
  loaderSize = 5,
  loaderColor = 'white',
  icon,
  type = 'button',
  iconPosition = 'before',
  style,
}: ButtonProps) => {
  return (
    <S.$Container
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      variant={disabled ? 'disabled' : variant}
      {...style}
    >
      <S.$Content>
        {loading && <Loader size={loaderSize} color={loaderColor} />}
        {!loading && (
          <>
            {icon && iconPosition === 'before' && icon}
            {text && (
              <S.$Text
                ml={!!icon && iconPosition === 'before' ? '5px' : '0'}
                mr={!!icon && iconPosition === 'after' ? '5px' : '0'}
                mb="0px"
                fontSize={fontSize}
              >
                {text}
              </S.$Text>
            )}
            {icon && iconPosition === 'after' && icon}
          </>
        )}
      </S.$Content>
    </S.$Container>
  )
}
