import React from 'react'

interface WindowSize {
  width: number
  height: number
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: window?.innerWidth,
    height: window?.innerHeight,
  })

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window?.innerWidth, height: window?.innerHeight })
    }

    window?.addEventListener('resize', handleResize)

    handleResize()

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  return windowSize as WindowSize
}
