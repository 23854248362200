import { Badge } from '../../../components'

interface DeviceBadgeProps {
  mainOk: boolean
  peripheralsOk: boolean
}

export default function DeviceBadge({ mainOk, peripheralsOk }: DeviceBadgeProps) {
  if (mainOk && peripheralsOk) {
    return <Badge text="Healthy" variant="green" />
  }
  if (!mainOk) {
    return <Badge text="Degraded" variant="red" />
  }
  if (mainOk && !peripheralsOk) {
    return <Badge text="Warning" variant="yellow" />
  }

  return <Badge text="Unknown" variant="red" />
}
