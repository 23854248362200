import { x } from '@xstyled/styled-components'
import _ from 'lodash'
import React from 'react'
import { Popover } from 'src/components'

import * as S from './ActiveTimesPopover.styled'

interface ActiveTimesPopoverProps {
  openOnHover?: boolean
  intervals: NonNullable<IPermit['activeTimeIntervalsByDays']>
}

type DayInterval = { day: DayOfTheWeek; intervals: string[] }

const intervalsToArray = (intervals: NonNullable<IPermit['activeTimeIntervalsByDays']>): DayInterval[] => {
  const daysOfTheWeek = Object.keys(intervals)

  const dayIntervals = daysOfTheWeek.map((dayOfTheWeek) => {
    const dayInterval = intervals?.[dayOfTheWeek as DayOfTheWeek]

    if (!dayInterval) return null

    const formattedIntervals = dayInterval?.split(',').map((interval) => {
      const [start, end] = interval.split('-')

      const [startHours, startMinutes] = start.split(':')
      const [endHours, endMinutes] = end.split(':')

      return `${startHours}:${startMinutes} - ${endHours}:${endMinutes}`
    })

    return { day: dayOfTheWeek, intervals: formattedIntervals }
  })

  return dayIntervals.filter(Boolean) as DayInterval[]
}

export const ActiveTimesPopover = ({ openOnHover, intervals }: ActiveTimesPopoverProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger onMouseEnter={() => openOnHover && setIsOpen(true)}>
        <S.$Trigger>Times</S.$Trigger>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content onMouseLeave={() => openOnHover && setIsOpen(false)} minWidth={220}>
          {intervalsToArray(intervals).map((interval) => (
            <x.div display="flex" w="100%" justifyContent="space-between">
              <x.div flex={1} fontWeight="semibold">
                {_.capitalize(interval?.day)}
              </x.div>
              <x.div flex={2} display="flex" flexDirection="column" gap="2px" alignItems="flex-end">
                {interval.intervals?.map((interval) => (
                  <x.div>{interval}</x.div>
                ))}
              </x.div>
            </x.div>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
