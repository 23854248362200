import { defaultTheme } from '@xstyled/styled-components'

import { colors } from './colors'

export const theme = {
  ...defaultTheme,
  screens: {
    _: 0,
    small: '640px',
    medium: '768px',
    large: '1024px',
    xlarge: '1280px',
    xxlarge: '1536px',
  },
  colors,
  fontSizes: {
    xsmall: '12px',
    small: '12px',
    medium: '14px',
    large: '18px',
    xlarge: '24px',
    xxlarge: '30px',
    h1: '36px',
    h2: '30px',
    h3: '24px',
    h4: '20px',
    h5: '18px',
    h6: '14px',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  shadows: {
    light: '0px 2px 6px rgba(16, 17, 68, 0.1)',
    normal: '0px 4px 10px rgba(0, 0, 0, 0.4)',
  },
  zIndices: {
    exosphere: 100000,
    stratosphere: 10000,
    toast: 10,
    dialog: 2,
    overlay: 1,
  },
} as const

export type Theme = typeof theme
