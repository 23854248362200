import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData, UseAxiosFetch } from 'src/hooks'
import { clearEmpties, genQueryParams } from 'src/utils'

export interface ITransactionsMetrics {
  date?: string
  location: { id: ILocation['id']; name: ILocation['name'] }
  count: number
  sum: number
  avg: number
  min: number
  max: number
}

export const useTransactionMetrics = (
  filters: TransactionFilter,
  groupBy: string,
  organisationId: string,
): [UseAxiosData<ITransactionsMetrics[]>, UseAxiosFetch<ITransactionsMetrics[]>] => {
  const { organisation } = useOrganisationContext()

  const [{ data = [], fetching }, fetchMetrics] = useAxios<ITransactionsMetrics[]>({
    url: BE_PATHS.TRANSACTIONS_METRICS(organisationId),
    params: {
      ...(filters && { filter: genQueryParams({ ...clearEmpties(filters), organisationId: null }) }),
      groupBy,
    },
    runOnInit: false,
  })

  React.useEffect(() => {
    if (!organisation?.id) return

    fetchMetrics()

    // eslint-disable-next-line
  }, [organisation?.id, filters, groupBy])

  return [{ data, fetching }, fetchMetrics]
}
