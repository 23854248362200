import { IDevice } from 'src/typings/device'

export const mapDeviceTypeToLabel = (deviceType?: IDevice['type']) => {
  switch (deviceType) {
    case 'access_control':
      return 'Access Control'
    case 'pay_on_entry':
      return 'Pay on Entry'
    case 'pay_on_exit':
      return 'Pay on Exit'
    default:
      return 'Other'
  }
}
