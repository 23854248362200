import { Tooltip } from '@mantine/core'
import { Button } from 'src/components'
import { LockOpen as ReleaseIcon } from 'tabler-icons-react'

interface ReleaseBarrierButtonProps {
  onRelease: () => void
  disabled?: boolean
  loading?: boolean
}
export const ReleaseBarrierButton = ({ onRelease, disabled, loading }: ReleaseBarrierButtonProps) => {
  return (
    <Tooltip label="Release barrier" position="top">
      <Button
        icon={<ReleaseIcon width={14} />}
        variant="secondary"
        loading={loading}
        disabled={disabled}
        onClick={onRelease}
        style={{ minHeight: '42px', maxHeight: '42px', minWidth: '42px', maxWidth: '42px' }}
      />
    </Tooltip>
  )
}
