import { x } from '@xstyled/styled-components'
import React from 'react'

import { Modal } from '../../../components'
import { OrganisationView } from '../Organisation/OrganisationView'

interface Props {
  isOpen: boolean
  organisation: Partial<Organisation>
  onClose: () => void
}

export const ViewOrganisationModal = ({ isOpen, organisation, onClose }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      title={
        <x.div display="flex" alignItems="center">
          <x.img
            src={organisation?.logo as string}
            alt={organisation?.name}
            mr="10px"
            width="40px"
            height="40px"
            borderRadius="5px"
          />
          <x.div m="auto">{organisation?.name || 'Organisation'}</x.div>
        </x.div>
      }
      body={<OrganisationView organisation={organisation} />}
      onConfirm={() => {}}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
