import * as yup from 'yup'

const DaySchema = yup
  .object()
  .nullable(true)
  .shape({
    enabled: yup.boolean().required('* Required').default(true),
    intervals: yup
      .array()
      .nullable(true)
      .of(
        yup.object().shape({
          startTime: yup.string().required('* Required').nullable(true),
          endTime: yup.string().required('* Required').nullable(true),
        }),
      ),
  })

export const CustomTimesSchema = yup.object().nullable().shape({
  monday: DaySchema,
  tuesday: DaySchema,
  wednesday: DaySchema,
  thursday: DaySchema,
  friday: DaySchema,
  saturday: DaySchema,
  sunday: DaySchema,
})

export const PermitSchema = yup.object().shape({
  vrm: yup.string().required('* Required'),
  locationId: yup.string().required('* Required'),
  reference: yup.string().min(1).max(100).required('* Required'),
  firstName: yup.string().min(1).max(100).required('* Required'),
  lastName: yup.string().min(1).max(100).required('* Required'),
  startDate: yup.string().required('* Required'),
  endDate: yup.string().required('* Required'),
  hasCustomTimes: yup.boolean().required('* Required').default(false),
  customTimes: CustomTimesSchema,
})

export type Interval = {
  startTime: string
  endTime: string
}

export type ICustomTimesSchema = {
  [key in DayOfTheWeek]: {
    enabled: boolean
    intervals: Interval[] | null
  }
}

export type IPermitSchema = {
  reference: string
  vrm: string
  locationId: string
  firstName: string
  lastName: string
  startDate: string
  endDate: string
  hasCustomTimes: boolean
  customTimes: Nullable<ICustomTimesSchema>
}
