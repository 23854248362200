import { Anchor } from '@mantine/core'
import LocationIcon from '@mui/icons-material/LocationOn'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Condition, EmptyState, Table } from 'src/components'
import { FE_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useAuthContext, useOrganisationContext } from 'src/context'

import { DeleteLocationModal, LocationModal } from './Modals'

export function Locations() {
  const navigate = useNavigate()

  const { isAdmin } = useAuthContext()
  const { devices, locations, fetchingOrgData, refetchOrgData } = useOrganisationContext()

  const [deleteLocationModalOpen, setDeleteLocationModalOpen] = React.useState(false)
  const [locationModalOpen, setLocationModalOpen] = React.useState<boolean>(false)

  const [locationToDelete, setLocationToDelete] = React.useState<IFullLocation>()

  const [searchLocation, setSearchLocation] = React.useState<IFullLocation[]>()

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setSearchLocation(
        locations?.filter(({ name, address }) => {
          if (name?.toLowerCase().includes(searchTerm?.toLowerCase())) return true
          if (address.city?.toLowerCase().includes(searchTerm?.toLowerCase())) return true
          return false
        }),
      )
    } else {
      setSearchLocation(undefined)
    }
  }

  const handleDelete = async (location: IFullLocation) => {
    setLocationToDelete(location)
    setDeleteLocationModalOpen(true)
  }

  return (
    <Page
      searchPlaceholder="Search for locations (name or city)"
      onSearch={handleSearch}
      endSlot={
        <Condition when={isAdmin}>
          {locations && locations.length > 0 && (
            <Button text="+ Add" onClick={() => setLocationModalOpen(true)} variant="accent" />
          )}
        </Condition>
      }
    >
      <Shell title="Location" pt="30px">
        {!fetchingOrgData && locations && locations.length === 0 && (
          <EmptyState
            title="There are no locations currently set up"
            subtitle="Locations provide metadata, such as location code, tariffs and more, to all linked device."
            icon={<LocationIcon />}
            buttonProps={
              isAdmin
                ? {
                    text: 'Create a location',
                    variant: 'accent',
                    onClick: () => setLocationModalOpen(true),
                    style: { h: '50px' },
                  }
                : undefined
            }
          />
        )}
        {locations && locations.length > 0 && (
          <Table
            items={searchLocation || locations}
            headers={[
              {
                key: 'name',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id))}>
                    <x.span fontSize="12px" color="dark">
                      {location?.name}
                    </x.span>
                  </Anchor>
                ),
              },
              { customKey: 'city', format: (_, item: IFullLocation) => item.address.city },
              { customKey: 'Location code', align: 'center', format: (_, item: IFullLocation) => item.code },
              {
                customKey: 'devices',
                align: 'center',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?devices=true')}>
                    <x.span fontSize="12px" color="dark">
                      {devices?.filter((device) => location?.id === device?.locationId).length.toString()}
                    </x.span>
                  </Anchor>
                ),
              },
              {
                customKey: 'tariffs',
                align: 'center',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?tariffs=true')}>
                    <x.span fontSize="12px" color="dark">
                      {location?.tariffs?.length.toString()}
                    </x.span>
                  </Anchor>
                ),
              },
              {
                customKey: 'bays',
                align: 'center',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?bays=true')}>
                    <x.span fontSize="12px" color="dark">
                      {location?.bays?.length.toString()}
                    </x.span>
                  </Anchor>
                ),
              },
              {
                customKey: 'barriers',
                align: 'center',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?barriers=true')}>
                    <x.span fontSize="12px" color="dark">
                      {location?.barriers?.length.toString()}
                    </x.span>
                  </Anchor>
                ),
              },
              {
                customKey: 'cameras',
                align: 'center',
                render: (_, location: IFullLocation) => (
                  <Anchor onClick={() => navigate(FE_PATHS.LOCATION(location?.id) + '?cameras=true')}>
                    <x.span fontSize="12px" color="dark">
                      {location?.cameras?.length.toString()}
                    </x.span>
                  </Anchor>
                ),
              },
            ]}
            actions={
              isAdmin && [
                /* 
                  TODO: Add copy to clipboard functionality 
                  NOTES: needs implementation of specific BFF for backoffice
                */
                {
                  label: 'Edit',
                  onClick: (location) => {
                    navigate(FE_PATHS.LOCATION(location.id))
                  },
                  type: 'edit',
                },
                { label: 'Delete', onClick: handleDelete, type: 'delete' },
              ]
            }
          />
        )}
        {isAdmin && (
          <LocationModal
            isOpen={locationModalOpen}
            onSubmitSuccess={() => refetchOrgData()}
            onClose={() => setLocationModalOpen(false)}
          />
        )}
        {isAdmin && (
          <DeleteLocationModal
            location={locationToDelete as IFullLocation}
            isOpen={deleteLocationModalOpen}
            onClose={() => setDeleteLocationModalOpen(false)}
            onDelete={() => refetchOrgData()}
          />
        )}
      </Shell>
    </Page>
  )
}
