import { useFileUploadHandlers } from 'src/hooks'

import * as S from './CSVUploader.styled'

interface CSVUploaderProps {
  file?: File
  onFileUpload: (file: File) => void
}

export const CSVUploader = ({ file, onFileUpload }: CSVUploaderProps) => {
  const { handleFileSelect } = useFileUploadHandlers()

  return (
    <S.Container>
      <S.FileName>{file?.name ?? 'Please select a CSV file'}</S.FileName>
      <S.UploadButton
        text="Upload"
        onClick={() => handleFileSelect({ accept: '.csv', onFileLoad: (file) => onFileUpload(file) })}
      />
    </S.Container>
  )
}
