import styled, { css, system } from '@xstyled/styled-components'
import { variant } from 'src/utils/style'

const variants = {
  none: {},
  primary: css`
    outline: none !important;

    background-color: secondary;
    color: primary;

    &:hover {
      background-color: secondaryHover;
    }
  `,
  secondary: css`
    outline: none !important;
    border: 1px solid;
    border-color: grey8;

    background-color: white;
    color: dark;

    &:hover {
      background-color: white;
      color: secondaryHover;
    }
  `,
  accent: css`
    outline: none !important;

    background-color: accent;
    color: primary;

    &:hover {
      background-color: accentHover;
    }
  `,
  disabled: css`
    outline: none !important;

    background-color: grey;
    color: primary;

    &:hover {
      background-color: grey;
    }
  `,
  destructive: css`
    outline: none !important;

    background-color: danger;
    color: primary;

    &:hover {
      background-color: dangerDark;
    }
  `,
}

const buttonVariant = variant({
  default: 'primary',
  variants,
})
type ButtonVariantProps = { readonly variant?: keyof typeof variants }

export const $Container = styled.buttonBox<ButtonVariantProps>`
  padding: 6px 12px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  font-size: 14px;

  color: secondary;
  background-color: primary;
  font-family: 'UberMoveLight', sans-serif;
  outline: none !important;

  transition: all 0.3s ease-in-out;

  && {
    ${buttonVariant}
    ${system}
  }

  :disabled {
    background-color: 'grey';
  }
`

export const $Content = styled.divBox`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const $Text = styled.pBox`
  font-family: 'UberMoveLight', sans-serif;
  white-space: nowrap;
`
