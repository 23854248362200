import * as S from './Row.styled'

export interface RowProps {
  style?: SCProps<typeof S.$Row>
  children: Children
}

export const Row = ({ style, children }: RowProps) => {
  return <S.$Row {...style}>{children}</S.$Row>
}
