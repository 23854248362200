import { Condition, Table, TableProps } from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { getDayjsDate } from 'src/utils'

import { ActiveTimesPopover } from './ActiveTimesPopover'

type PermitsTableProps = {
  permits: IPermit[]
  onEdit: (permit: IPermit) => void
  onDelete: (permit: IPermit) => void
} & Pick<TableProps<IPermit>, 'hasMoreItems' | 'loading' | 'loadingPlaceholder' | 'onLoadMore'>

export const PermitsTable = ({ permits, onEdit, onDelete, ...tableProps }: PermitsTableProps) => {
  const { locations } = useOrganisationContext()
  const { isAtLeastManager } = useAuthContext()

  return (
    <Table
      {...tableProps}
      items={permits}
      headers={[
        {
          customKey: 'Location',
          type: 'string',
          format: (_, item) => locations?.find((loc) => loc.id === item.locationId)?.name,
        },
        { key: 'vrm', type: 'string' },
        {
          customKey: 'Timezone',
          format: (_, item) => locations?.find((loc) => loc.id === item.locationId)?.timezone ?? 'Europe/London',
        },
        {
          customKey: 'Start Date',
          type: 'string',
          format: (_, item) => {
            const location = locations?.find((loc) => loc.id === item.locationId)
            return getDayjsDate({ date: item.startDate, tz: location?.timezone })?.format(FORMATS.DATETIMELONG)
          },
        },
        {
          customKey: 'End Date',
          type: 'string',
          format: (_, item) => {
            const location = locations?.find((loc) => loc.id === item.locationId)
            return getDayjsDate({ date: item.endDate, tz: location?.timezone })?.format(FORMATS.DATETIMELONG)
          },
        },
        {
          customKey: 'Custom Times',
          type: 'string',
          render: (_, item) => (
            <Condition when={!!item.activeTimeIntervalsByDays} fallback={<div>No</div>}>
              <ActiveTimesPopover
                openOnHover
                intervals={item.activeTimeIntervalsByDays as NonNullable<IPermit['activeTimeIntervalsByDays']>}
              />
            </Condition>
          ),
        },
        { key: 'reference', type: 'string' },
      ]}
      actions={[
        { label: 'Edit', onClick: onEdit, type: 'edit', hide: () => !isAtLeastManager },
        { label: 'Delete', onClick: onDelete, type: 'delete', hide: () => !isAtLeastManager },
      ]}
    />
  )
}
