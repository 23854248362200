import { Condition } from '../Condition'

import * as S from './Tab.styled'

export interface TabProps {
  key?: string
  visible?: boolean
  text?: string
  icon?: JSX.Element | string
  badgeNum?: number
  active?: boolean
  onClick: () => void
  children?: Children
  basePath: string
}

export const Tab = ({ text, icon, badgeNum, onClick, active, children }: TabProps) => {
  return (
    <S.$Container onClick={onClick} data-active={active}>
      <Condition when={!children} fallback={children}>
        <S.$Icon>{icon}</S.$Icon>
        <S.$Text data-active={active}>{text}</S.$Text>
        {badgeNum && <S.$Badge>{badgeNum}</S.$Badge>}
      </Condition>
    </S.$Container>
  )
}
