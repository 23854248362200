import { Tag } from 'src/components'

interface BookingTypeProps {
  type: BookingType | undefined
}

export const BookingType = ({ type }: BookingTypeProps) => {
  const style = { border: 'none', ml: '-10px' }

  if (type === 'park_and_charge') {
    return <Tag variant="purple" text="Park & Charge" style={style} />
  }

  if (type === 'parking') {
    return <Tag variant="blue" text="Park Only" style={style} />
  }

  if (type === 'charging') {
    return <Tag variant="green" text="Charge Only" style={style} />
  }

  if (type === 'unknown') {
    return <Tag variant="dark" text="Unknown" style={style} />
  }

  return <Tag variant="dark" text="Unknown" style={style} />
}
