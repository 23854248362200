import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`

export const $Field = styled.divBox`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid;
  border-color: grey12;
  padding: 6px;
  font-size: 14px;
  color: dark;

  transition: all 0.3s ease;

  :hover {
    border-color: grey24;
  }

  &[data-success='true']:focus-within {
    border-color: accentHover;

    label {
      color: accentHover;
    }
  }

  &[data-error='true'] {
    border-color: danger;
  }

  &[data-disabled='true'] {
    border-color: grey8;
    background-color: grey4;
    cursor: not-allowed;
  }
`

export const $Value = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
`

export const $Input = styled.inputBox`
  width: 100%;
  border: none;
  padding: 6px;
  background-color: transparent;
  font-size: 12px;

  &:disabled {
    color: grey48;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }
`

export const $Icon = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: grayer;

  transition: all 0.3s ease;
`
