import { x } from '@xstyled/styled-components'
import { useAuthContext, useOrganisationContext } from 'src/context'
import { useNavigate } from 'react-router-dom'
import { FE_PATHS } from 'src/constants/paths'

import { SelectDropdown } from '../SelectDropdown'

import * as S from './OrganisationSwitcher.styled'

interface CompanyTabProps {
  name?: string
  img?: string
  userType?: string
  compact?: boolean
}

const CompanyTab = ({ name, userType, img, compact = false }: CompanyTabProps) => {
  return (
    <S.$Container>
      <S.$Tab data-compact={compact}>
        <S.$CompanyLogo data-with-logo={!!img || !name} data-compact={compact}>
          {img && <img src={img} alt="Company logo" />}
          {!img && <x.span>{name?.substring(0, 1)?.toUpperCase()}</x.span>}
        </S.$CompanyLogo>
        <S.$CompanyContainer>
          <S.$CompanyAccount data-compact={compact}>{name}</S.$CompanyAccount>
          {name && userType && <S.$UserType>{userType}</S.$UserType>}
        </S.$CompanyContainer>
      </S.$Tab>
    </S.$Container>
  )
}

export const OrganisationSwitcher = () => {
  const { user, isAdmin } = useAuthContext()
  const { organisation, setSelectedOrganisationId, organisations } = useOrganisationContext()
  const navigate = useNavigate()

  const allowedOrganisation = organisations?.filter((org) => user?.organisationId === org?.id)

  const organisationOptions = (isAdmin ? organisations : [allowedOrganisation])?.map((organisation) => ({
    value: organisation?.id,
    label: organisation?.name,
    labelDisplay: <CompanyTab name={organisation?.name} img={organisation?.logo as string} compact />,
  }))

  return (
    <SelectDropdown
      disabled={!isAdmin}
      placeholder="Select Organisation"
      items={organisationOptions}
      onSelect={async (selectedItems) => {
        const [selectedItem] = selectedItems || []
        if (!selectedItem || !selectedItem?.value || !organisations) return
        const newSelectedOrgId = String(selectedItem?.value)

        localStorage.setItem('org.id', newSelectedOrgId)
        setSelectedOrganisationId?.(newSelectedOrgId)

        navigate(FE_PATHS.DASHBOARD, { replace: true })
      }}
      theme="dark"
      trigger={
        <CompanyTab
          name={organisation?.name}
          img={organisation?.logo as string}
          userType={user?.type === 'ADMIN' ? 'Admin' : ''}
        />
      }
    />
  )
}
