import { IPermitSchema } from '../components/PermitForm'

export const transformActiveTimeIntervalsToObject = (
  activeTimeIntervalsByDays?: IPermitSchema['customTimes'],
): IPermit['activeTimeIntervalsByDays'] => {
  if (!activeTimeIntervalsByDays) return null

  const daysOfWeek: DayOfTheWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  const activeTimeIntervalsByDaysObj: IPermit['activeTimeIntervalsByDays'] = daysOfWeek.reduce((acc, day) => {
    const dayIntervals = activeTimeIntervalsByDays[day]

    if (!dayIntervals || dayIntervals.enabled === false || !dayIntervals?.intervals) {
      acc[day] = null
      return acc
    }

    acc[day] = dayIntervals?.intervals?.map((interval) => `${interval.startTime}-${interval.endTime}`).join(',')

    return acc
  }, {} as NonNullable<IPermit['activeTimeIntervalsByDays']>)

  const hasAtLeastOneDayEnabled = Object.values(activeTimeIntervalsByDaysObj).some((day) => day !== null)
  if (!hasAtLeastOneDayEnabled) return null

  return activeTimeIntervalsByDaysObj
}
