import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

export const getDegradedDevicesByHeartbeats = (devices: IDevice[], heartbeats: IDeviceHeartbeat[] = []) => {
  const devicesIdsWithDegradedHeartbeats = heartbeats
    ?.filter(({ level, data }) => {
      const { alio, printer } = data ?? {}

      const hasAlio = !!alio
      const hasPrinter = !!printer

      const isKioskDegraded = level === 'error'
      const isAlioDegraded = hasAlio ? alio.level === 'error' : false
      const isPrinterDegraded = hasPrinter ? printer.level === 'error' : false

      return isKioskDegraded || isAlioDegraded || isPrinterDegraded
    })
    .map(({ deviceId }) => deviceId)

  const deviceIdsWithoutHeartbeats = devices
    ?.filter((device) => {
      const isMissingHeartbeat = !heartbeats?.find(({ deviceId }) => deviceId === device.id)

      return isMissingHeartbeat
    })
    .map(({ id }) => id)

  const degradedDeviceIds = [...devicesIdsWithDegradedHeartbeats, ...deviceIdsWithoutHeartbeats]
  const degreadedDevices = devices?.filter(({ id }) => degradedDeviceIds?.includes(id))

  return degreadedDevices
}
