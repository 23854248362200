import { Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import _ from 'lodash'
import { NavigateFunction } from 'react-router-dom'
import { Badge, Condition, THeader } from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { FE_PATHS } from 'src/constants/paths'
import { ProviderTableCellIcon } from 'src/screens/shared/components'
import { Mapper } from 'src/utils'

import { BookingType } from './BookingModal/components'

export const getBookingsTableHeaders = (navigate: NavigateFunction, setActiveBooking: (booking: IBooking) => void) =>
  [
    {
      key: 'reference',
      render: (_b, booking) => {
        return (
          <Badge variant="grey" style={{ w: '90px', cursor: 'pointer' }} onClick={() => setActiveBooking(booking)}>
            <x.div display="flex" alignItems="center">
              <x.span fontSize="12px">{booking.reference}</x.span>
            </x.div>
          </Badge>
        )
      },
    },
    { key: 'vrm' },
    {
      customKey: 'type',
      render: (_b, booking) => {
        return <BookingType type={booking?.type} />
      },
    },
    {
      customKey: 'location',
      render: (_b, booking) => {
        return (
          <Anchor onClick={() => navigate(FE_PATHS.LOCATION(booking.locationId))}>
            <x.span fontSize="12px" color="dark">
              {booking?.location?.name}
            </x.span>
          </Anchor>
        )
      },
    },
    {
      key: 'source',
      align: 'left',
      render: (_b, booking) => <ProviderTableCellIcon provider={booking.source} />,
    },
    { customKey: 'Start date', format: (_b, booking) => dayjs(booking.startDate).format(FORMATS.DATETIME) },
    { customKey: 'End date', format: (_b, booking) => dayjs(booking.endDate).format(FORMATS.DATETIME) },
    {
      key: 'status',
      render: (_b, booking) => (
        <Badge
          text={_.capitalize(
            booking.status === 'CONFIRMED' && dayjs(booking.endDate).isAfter(dayjs()) ? 'ONGOING' : booking.status,
          )}
          style={{ w: '90px' }}
          variant={Mapper.mapBookingStatusToBadgeVariant(booking.status, booking.endDate)}
        />
      ),
    },
    {
      key: 'cost',
      align: 'left',
      render: (_b, booking) => (
        <Condition when={booking.cost} fallback="—">
          <x.div display="flex" alignItems="center">
            <x.span>£ {booking.cost?.toFixed(2)}</x.span>
          </x.div>
        </Condition>
      ),
    },
  ] as THeader<IFullBooking>[]
