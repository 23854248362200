import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: light;
  overflow: hidden;
  overflow-x: auto;
`
