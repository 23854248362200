import { x } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { Button, Checkbox, Condition, Text } from 'src/components'

interface DayCheckBoxProps {
  day: DayOfTheWeek
  isEnabled: boolean
  onAddNewInterval?: () => void
  onCheckedChange?: (checked: boolean) => void
}

export const DayCheckbox = ({ day, isEnabled, onAddNewInterval, onCheckedChange }: DayCheckBoxProps) => {
  const formik = useFormikContext()

  const field = `customTimes.${day}.enabled`

  return (
    <x.div display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" w={1}>
      <Checkbox
        id={day}
        checked={isEnabled}
        onCheckedChange={(checked) => {
          formik.setFieldValue(field, checked, true)
          onCheckedChange?.(checked)
        }}
        label={<Text type="p">{_.capitalize(day)}</Text>}
      />
      <Condition when={isEnabled}>
        <Button
          variant="secondary"
          onClick={onAddNewInterval}
          text="+"
          style={{
            w: '24px',
            h: '24px',
            maxHeight: '24px',
            maxWidth: '24px',
            minHeight: '24px',
            minWidth: '24px',
            borderRadius: 5,
            p: 0,
          }}
        />
      </Condition>
    </x.div>
  )
}
