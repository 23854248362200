import { Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'

import { Modal } from '../../../components'
import getUserSchema from '../User/User.schema'
import UserForm from '../User/UserForm'

interface Props {
  isOpen: boolean
  user?: User
  onSubmitSuccess: (user: Partial<User>) => void
  onClose: () => void
}

export const UserModal = ({ isOpen, user, onSubmitSuccess, onClose }: Props) => {
  const toast = useToast()
  const { selectedOrganisationId } = useOrganisationContext()
  const [, submitData] = useAxios<Partial<User>>({
    method: user ? 'PUT' : 'POST',
    url: user?.id ? `${BE_PATHS.USERS}/${user.id}` : BE_PATHS.USERS,
    runOnInit: false,
  })

  const [formData, setFormData] = React.useState<Partial<User>>()

  const initialValues = user || {}
  const isCreate = !user
  const schema = getUserSchema(isCreate)

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={() => {}}>
      {({ errors, validateForm }) => {
        return (
          <Modal
            style={{ w: '95%', maxWidth: 600 }}
            title={isCreate ? 'New user' : 'Edit user'}
            body={<UserForm user={user} onChange={setFormData} errors={errors} isActive={isOpen} />}
            confirmText={isCreate ? 'Create' : 'Save'}
            variant="accent"
            hasErrors={Object.keys(errors).length > 0}
            cardStyle={{ backgroundColor: 'white' }}
            onConfirm={async () => {
              try {
                const validationErrors = await validateForm(formData)

                if (Object.keys(validationErrors).length > 0) {
                  toast(`Form validation has failed, please complete required fields`, { variant: 'error' })
                  return
                }

                const newUser = await submitData({
                  body: _.omit({ ...formData, organisationId: selectedOrganisationId }, 'id', 'organisation'),
                })
                newUser && onSubmitSuccess(newUser)
                toast(`${formData?.name || 'User'} has been saved`, { variant: 'success' })
                setFormData(undefined)

                onClose()
              } catch (error) {
                toast(`Something went wrong, please try again.`, { variant: 'error' })
              }
            }}
            onClose={onClose}
            isOpen={isOpen}
          />
        )
      }}
    </Formik>
  )
}
