import ConnectedIcon from '@mui/icons-material/ConnectedTv'
import { FormikContextType } from 'formik'
import { useSnackbar } from 'notistack'
import { IDevice } from 'src/typings/device'

import { Button } from '../../../../components'
import { BE_PATHS } from '../../../../constants/paths'
import { useAxios } from '../../../../hooks'

interface ToastParams {
  deviceName?: string
  isSuccess: boolean
}

interface PingDeviceButtonProps {
  formik: FormikContextType<Partial<IDevice>>
}

export const PingDeviceButton = ({ formik }: PingDeviceButtonProps) => {
  const { name, remoteIp = '', remotePort = 0 } = formik.values

  const [{ fetching: pinging }, pingDevice] = useAxios({
    url: BE_PATHS.DEVICE_PING(remoteIp, remotePort),
    runOnInit: false,
  })
  const { enqueueSnackbar } = useSnackbar()

  const dispatchToast = ({ deviceName, isSuccess }: ToastParams): void => {
    enqueueSnackbar(`Connection to ${deviceName} has ${isSuccess ? 'been established!' : 'failed!'}`, {
      variant: isSuccess ? 'success' : 'error',
    })
  }

  return (
    <Button
      text="Ping"
      style={{
        h: '40px',
        w: '80px',
        marginRight: 10,
        bg: 'grey.24',
      }}
      variant="secondary"
      type="button"
      icon={<ConnectedIcon fontSize="medium" style={{ marginRight: 5 }} />}
      loading={pinging}
      loaderColor="dark"
      disabled={pinging || formik.isSubmitting || !remoteIp || !remotePort}
      onClick={async () => {
        if (remoteIp && remotePort) {
          try {
            await pingDevice()
            dispatchToast({ deviceName: name, isSuccess: true })
          } catch (error) {
            dispatchToast({ deviceName: name, isSuccess: false })
          }
        }
      }}
    />
  )
}
