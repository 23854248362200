import { Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Table, TableProps } from 'src/components'
import { FE_PATHS } from 'src/constants'
import { useOrganisationContext } from 'src/context'
import { convertToDuration } from 'src/utils'

type BarrierEventsTableProps = {
  barrierEvents: IBarrierEvent[]
} & Pick<TableProps<IBarrierEvent>, 'loading' | 'hasMoreItems' | 'onLoadMore'>

export const BarrierEventsTable = ({ barrierEvents, ...tableProps }: BarrierEventsTableProps) => {
  const { locations } = useOrganisationContext()
  const navigate = useNavigate()

  const getLocationNameById = (locationId: string) => locations?.find((loc) => loc.id === locationId)?.name

  return (
    <Table
      {...tableProps}
      items={barrierEvents}
      headers={[
        {
          customKey: 'Location',
          render: (_, barrierEvent) => (
            <Anchor onClick={() => navigate(FE_PATHS.LOCATION(barrierEvent?.locationId))}>
              <x.span fontSize="12px" color="dark">
                {getLocationNameById(barrierEvent?.locationId)}
              </x.span>
            </Anchor>
          ),
        },
        {
          customKey: 'Barrier',
          render: (_, barrierEvent) => (
            <Anchor onClick={() => navigate(FE_PATHS.LOCATION(barrierEvent?.locationId) + '?barriers=true')}>
              <x.span fontSize="12px" color="dark">
                {barrierEvent?.barrier?.name}
              </x.span>
            </Anchor>
          ),
        },
        { key: 'vrm' },
        {
          customKey: 'Entry read',
          format: (_, barrierEvent) =>
            barrierEvent?.data?.entryDate ? dayjs(barrierEvent?.data?.entryDate).format('DD/MM/YYYY HH:mm') : '-',
        },
        {
          customKey: 'Exit read',
          format: (_, barrierEvent) => dayjs(barrierEvent?.createdAt).format('DD/MM/YYYY HH:mm'),
        },
        {
          customKey: 'Duration',
          format: (_, barrierEvent) => {
            if (!barrierEvent?.data?.entryDate) return '-'
            if (!barrierEvent?.createdAt) return '-'

            const duration = convertToDuration(barrierEvent?.data?.entryDate, barrierEvent?.createdAt)

            return duration
          },
        },
        { key: 'released', render: (_, barrierEvent) => <div>{barrierEvent?.released ? 'Yes' : 'No'}</div> },
        { customKey: 'Status message', format: (_, barrierEvent) => barrierEvent?.message ?? '-' },
      ]}
    />
  )
}
