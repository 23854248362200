import { Tabs } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Condition, ExportCsvButton, Row, Table, TableAction } from 'src/components'
import { BE_PATHS } from 'src/constants/paths'
import { Page, Shell } from 'src/containers'
import { useOrganisationContext } from 'src/context'
import { useAxios, useCopyToClipboard, useToast } from 'src/hooks'
import { BookingModal } from 'src/screens/Bookings/components'
import { clearEmpties, genQueryParams, getFormattedTimestampInterval } from 'src/utils'
import * as TablerIcons from 'tabler-icons-react'

import { TransactionFilters, getTransactionTableHeaders } from './components'
import { useTransactions } from './hooks'
import { MAX_TRANSACTIONS_RANGE_DAYS } from './transactions.constants'

dayjs.extend(timezone)

const initialFilters: TransactionFilter = {
  timestamp: {
    gt: dayjs().subtract(7, 'days').startOf('day').toISOString(),
    lt: dayjs().endOf('day').toISOString(),
  },
}

const sanitizeSearchTerm = (term: string) => {
  // Remove invalid characters: anything that isn't a lowercase letter, digit, or hyphen
  let sanitized = term.replace(/[^0-9a-z-]/g, '')

  // Remove leading hyphens
  sanitized = sanitized.replace(/^-+/g, '')

  // Replace multiple consecutive hyphens with a single hyphen
  sanitized = sanitized.replace(/--+/g, '-')

  // Return the sanitized term
  return sanitized
}

export const Transactions = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const copyToClipboard = useCopyToClipboard()
  const { organisation } = useOrganisationContext()

  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [page, setPage] = React.useState<number>(0)
  const [selectedBooking, setSelectedBooking] = React.useState<IBooking>()
  const [filters, setFilters] = React.useState<TransactionFilter>(initialFilters)

  const [
    { data: transactions = [], fetching: fetchingTransactions, hasMore: hasMoreTransactions, setData: setTransactions },
  ] = useTransactions({ ...(searchTerm ? { searchTerm } : { filters }), page })

  const [{ fetching: fetchingCSV }, fetchTransactionsCSV] = useAxios<any>({
    url: BE_PATHS.TRANSACTIONS_CSV(filters?.organisationId ?? organisation?.id),
    params: {
      ...(filters && { filter: genQueryParams({ ...clearEmpties({ ...filters, organisationId: null }) }) }),
      tz: dayjs.tz.guess(),
    },
    runOnInit: false,
  })

  const [{ fetching: settingStatus }, setStatus] = useAxios<{ status: TransactionStatus }>({
    method: 'PUT',
    url: BE_PATHS.TRANSACTIONS,
  })

  const tableHeaders = getTransactionTableHeaders({
    navigate,
    onSelectBooking: setSelectedBooking,
    onSelectTransaction: (transaction) => copyToClipboard(transaction.reference),
  })

  const tableActions = [
    {
      label: '',
      tooltip: 'Set as refunded',
      icon: <TablerIcons.ReceiptRefund />,
      hide: (transaction) => transaction.status !== 'PENDING' || transaction.type !== 'REFUND',
      onClick: async (transaction) => {
        try {
          await setStatus({ url: `${BE_PATHS.TRANSACTIONS}/${transaction.id}`, body: { status: 'SUCCESSFUL' } })

          const updatedTransactions = transactions?.map((t) =>
            t.id === transaction.id ? { ...t, status: 'SUCCESSFUL' } : t,
          ) as IFullTransaction[]

          setTransactions?.(updatedTransactions)

          toast(`Transaction set as refunded. However, this action did not process the refund!`, {
            variant: 'info',
            transitionDuration: { enter: 500, exit: 500, appear: 30000 },
          })
          toast(`Please make sure to refund the transaction MANUALLY!`, {
            variant: 'warning',
            transitionDuration: { enter: 500, exit: 500, appear: 30000 },
          })
        } catch (error) {
          toast("Couldn't process. Please try again", { variant: 'error' })
        }
      },
    },
  ] as TableAction<IFullTransaction>[]

  useDidUpdate(() => setPage(0), [organisation?.id, filters, searchTerm])

  return (
    <Page
      searchPlaceholder="Search transactions by reference"
      onSearch={setSearchTerm}
      searchValue={searchTerm}
      sanitizer={sanitizeSearchTerm}
      loading={!organisation || fetchingCSV || settingStatus}
      endSlot={
        <Row>
          <ExportCsvButton
            filters={filters}
            onClick={fetchTransactionsCSV}
            text="Export Transactions"
            disabled={fetchingCSV || fetchingTransactions || !transactions?.length}
            organisationId={organisation?.id}
            filename="Transactions"
          />
          <TransactionFilters
            initialValues={initialFilters}
            maxDaysRange={MAX_TRANSACTIONS_RANGE_DAYS}
            filterSet={['organisationId', 'locationId', 'deviceId', 'type', 'providerId', 'timestamp']}
            onUpdate={(_updatedAt, updatedFilters) => {
              setFilters(updatedFilters)
              toast('Filters applied', { variant: 'success' })
            }}
            onReset={() => toast('Filters cleared', { variant: 'default' })}
          />
        </Row>
      }
    >
      <Shell>
        <Condition when={!!organisation}>
          <Tabs
            variant="pills"
            onTabChange={(_tabIdx, tabKey) => {
              setPage(0)

              if (tabKey === 'ALL') {
                setFilters({ ...(filters as TransactionFilter), status: undefined })
                return
              }
              setFilters({ ...(filters as TransactionFilter), status: tabKey })
            }}
          >
            <Tabs.Tab tabKey="ALL" label="All" icon={<TablerIcons.ClearAll size={14} />} />
            <Tabs.Tab
              tabKey="SUCCESSFUL"
              label="Successful"
              icon={<TablerIcons.CircleCheck size={14} color="#02b88d" />}
            />
            <Tabs.Tab tabKey="PENDING" label="Pending" icon={<TablerIcons.Clock size={14} color="#fece0d" />} />
            <Tabs.Tab
              tabKey="CANCELLED"
              label="Cancelled"
              icon={<TablerIcons.CreditCard size={14} color="#ff9d00" />}
            />
            <Tabs.Tab
              tabKey="DECLINED"
              label="Declined"
              icon={<TablerIcons.CreditCardOff size={14} color="#f04444" />}
            />
            <Tabs.Tab tabKey="FAILED" label="Failed" icon={<TablerIcons.AlertTriangle size={14} color="#7000ff" />} />
          </Tabs>
        </Condition>

        <x.div h="10px" />
        <Condition when={!!organisation}>
          <Table
            items={transactions}
            heading={
              searchTerm
                ? `Showing results for '${searchTerm}'`
                : `Transactions ${getFormattedTimestampInterval(filters?.timestamp)}`
            }
            headers={tableHeaders}
            actions={tableActions}
            loading={fetchingTransactions}
            hasMoreItems={hasMoreTransactions}
            onLoadMore={() => !fetchingTransactions && setPage((page) => page + 1)}
          />
          <BookingModal booking={selectedBooking} onClose={() => setSelectedBooking(undefined)} />
        </Condition>
      </Shell>
    </Page>
  )
}
