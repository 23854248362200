import React from 'react'

import * as S from './Error.styled'

export interface ErrorProps {
  children: React.ReactNode
  visible?: boolean
  xss?: SCProps<typeof S.$Error>
}

export const Error = ({ children, visible, xss }: ErrorProps) => {
  return (
    <S.$Error data-visible={visible} {...xss}>
      {children}
    </S.$Error>
  )
}
