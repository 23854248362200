import React from 'react'

interface ClientCacheContextProps {
  set: <T>(key: string, value: T) => void
  get: <T>(key: string) => T
  remove: (key: string) => void
  clear: () => void
}

const ClientCacheContext = React.createContext({} as ClientCacheContextProps)

interface ClientCacheProviderProps {
  children: Children
}

export const ClientCacheProvider = ({ children }: ClientCacheProviderProps) => {
  const [cache, setCache] = React.useState({})

  const set = (key: string, value: any) => {
    setCache((prevCache) => ({ ...prevCache, [key]: value }))
  }

  const get = (key: string) => {
    return cache[key]
  }

  const remove = (key: string) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache }
      delete newCache[key]
      return newCache
    })
  }

  const clear = () => {
    setCache({})
  }

  return (
    <ClientCacheContext.Provider
      value={{
        set,
        get,
        remove,
        clear,
      }}
    >
      {children}
    </ClientCacheContext.Provider>
  )
}

export const useClientCacheContext = () => React.useContext(ClientCacheContext)
