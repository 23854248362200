import React from 'react'
import { useAuthContext } from 'src/context'

import { Condition } from '../Condition'

interface AdminAreaProps {
  children: Children
  fallback?: React.ReactNode | JSX.Element
}

export const AdminArea = ({ children, fallback }: AdminAreaProps) => {
  const { isAdmin } = useAuthContext()
  return (
    <Condition when={isAdmin} fallback={fallback}>
      {children}
    </Condition>
  )
}
