import { Tooltip } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import _ from 'lodash'
import { ProviderIcon } from 'src/screens/shared/components'

interface BookingSourceProps {
  source?: BookingSource
}

export const BookingSource = ({ source }: BookingSourceProps) => {
  return (
    <Tooltip label={_.capitalize(source)} openDelay={500}>
      <x.div cursor="pointer">
        <x.div display="flex" alignItems="center">
          <ProviderIcon provider={source} size={20} />
          <x.span fontSize="14px" ml="10px">
            {_.capitalize(source)}
          </x.span>
        </x.div>
      </x.div>
    </Tooltip>
  )
}
