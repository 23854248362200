import { x } from '@xstyled/styled-components'
import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { Condition } from 'src/components'

import { ICustomTimesSchema, IPermitSchema } from '../Permit.schema'

import { DayCheckbox } from './DayCheckbox'
import { DayIntervals } from './DayIntervals'

const days: DayOfTheWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
const defaultInterval = { startTime: '00:00:00', endTime: '23:59:59' }
const defaultDay = { enabled: true, intervals: [defaultInterval] }
export const defaultCustomTimes = days.reduce((acc, day) => ({ ...acc, [day]: defaultDay }), {} as ICustomTimesSchema)

export const CustomTimesForm = () => {
  const formik = useFormikContext<IPermitSchema>()

  const customTimes = (formik.values?.customTimes ?? defaultCustomTimes) as ICustomTimesSchema

  React.useEffect(() => {
    formik.setValues({ ...formik.values, customTimes })
  }, [])

  return (
    <x.div display="flex" flexDirection="column" gap="20px">
      {days.map((day) => (
        <FieldArray
          name={`customTimes.${day}.intervals`}
          render={(arrayHelpers) => {
            const isEnabled = customTimes?.[day]?.enabled
            const intervals = customTimes?.[day]?.intervals ?? []
            const hasIntervals = intervals?.length > 0

            const addNewInterval = () => arrayHelpers.push({ startTime: '00:00:00', endTime: '23:59:59' })
            const removeInterval = (index: number) => arrayHelpers.remove(index)

            return (
              <x.div>
                <DayCheckbox
                  day={day}
                  isEnabled={isEnabled}
                  onAddNewInterval={addNewInterval}
                  onCheckedChange={(dayIsEnabled) => {
                    if (!dayIsEnabled) {
                      formik.setFieldValue(`customTimes.${day}.intervals`, null, true)
                    } else {
                      formik.setFieldValue(`customTimes.${day}.intervals`, [defaultInterval], true)
                    }
                  }}
                />
                <Condition when={isEnabled && hasIntervals}>
                  <DayIntervals day={day} intervals={intervals} onRemoveInterval={removeInterval} />
                </Condition>
              </x.div>
            )
          }}
        />
      ))}
    </x.div>
  )
}
