import { transformActiveTimeIntervalsToObject } from 'src/screens/Permits/utils'

import { IPermitSchema } from '../../PermitForm'

export const transformFormDataToPermit = (formData: IPermitSchema): Partial<IPermit> => {
  return {
    vrm: formData?.vrm,
    reference: formData?.reference,
    startDate: formData?.startDate,
    endDate: formData?.endDate,
    firstName: formData?.firstName,
    lastName: formData?.lastName,
    locationId: formData?.locationId,
    activeTimeIntervalsByDays: formData?.hasCustomTimes
      ? transformActiveTimeIntervalsToObject(formData?.customTimes)
      : null,
  }
}
