import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  position: absolute;
  z-index: 12;
  inset: 0;
  opacity: 1;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const $Card = styled.divBox`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: light;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: 700px;
  max-height: 95vh;
  z-index: 13;
  opacity: 0;
  border-radius: 20px;
  transform: scale(0.9);
  margin: 20px;
  padding: 20px 30px;

  ${Styles.screens.small`
		padding: 15px;
	`}

  transition: all 0.2s ease-in-out;

  &[data-entering='true'] {
    opacity: 1;
    transform: scale(1);
  }

  &[data-exiting='true'] {
    opacity: 0;
    transform: scale(0.9);
  }
`

export const $Backdrop = styled.divBox`
  position: absolute;
  inset: 0;
  height: 100vh;
  width: 100vw;
  background-color: #4f4f4f75;
  z-index: 11;
  opacity: 0;

  transition: opacity 0.2s ease-in-out;

  &[data-entering='true'] {
    opacity: 1;
  }

  &[data-exiting='true'] {
    opacity: 0;
  }
`

export const $Header = styled.divBox`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding-bottom: 30px;
`

export const $Title = styled.divBox`
  font-weight: bold;
  font-size: 20px;
`

export const $Close = styled.divBox`
  background-color: grey8;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  transition: background-color 0.2s ease-in-out;

  :hover {
    background-color: grey12;
  }

  > svg {
    font-size: 20px;
  }

  path {
    fill: #828282;
  }
`

export const $Body = styled.divBox`
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  max-height: 75vh;
  width: 100%;
  overflow-y: auto;

  ${Styles.screens.small`
		padding: 0 10px;
	`}
`

export const $Footer = styled.divBox`
  background-color: grayFaded;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button:last-child {
    margin-left: 8px;
  }
`
