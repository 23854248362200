import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useAxios } from 'src/hooks'
import { IDevice } from 'src/typings/device'

import { Modal } from '../../../components'

interface Props {
  isOpen: boolean
  device: IDevice
  onDelete: (device: Partial<IDevice>) => void
  onClose: () => void
}

export const DeleteDeviceModal = ({ isOpen, device, onDelete, onClose }: Props) => {
  const [, deleteDevice] = useAxios<IDevice>({ method: 'DELETE', url: BE_PATHS.DEVICES })

  const handleDeleteConfirm = React.useCallback(async () => {
    if (!device) return
    await deleteDevice({ url: `${BE_PATHS.DEVICES}/${device?.id}` })
    onDelete(device)
    onClose()
  }, [device, deleteDevice, onDelete, onClose])

  return (
    <Modal
      isOpen={isOpen}
      cardStyle={{ maxWidth: 450 }}
      title="Are you sure?"
      body={
        <h4>
          The device with name <strong>{device?.name || 'This item'}</strong> will be permanently deleted. This action
          is irreversible!
        </h4>
      }
      onConfirm={handleDeleteConfirm}
      confirmText="Delete"
      onClose={onClose}
    />
  )
}
