import { Anchor, Tooltip } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import dayjs from 'dayjs'
import _ from 'lodash'
import { Badge, Condition, Tag, THeader } from 'src/components'
import { FORMATS } from 'src/constants/formats'
import { FE_PATHS } from 'src/constants/paths'
import { Mapper } from 'src/utils'
import { useOrganisationContext } from 'src/context'
import { ProviderTableCellIcon } from 'src/screens/shared/components'
import { NavigateFunction } from 'react-router-dom'

export const getTransactionTableHeaders = ({
  navigate,
  onSelectBooking,
  onSelectTransaction,
}: {
  navigate: NavigateFunction
  onSelectBooking?: (booking: IBooking) => void
  onSelectTransaction?: (transaction: ITransaction) => void
}) => {
  const { devices } = useOrganisationContext()

  return [
    {
      key: 'reference',
      render: (_, transaction) => {
        const hasReference = transaction?.reference === 'N/A' ? null : transaction?.reference

        return (
          <Tooltip label={hasReference ? 'Click to copy' : 'Not available'} position="right">
            <Badge
              variant="grey"
              style={{ w: '260px', maxWidth: 'unset', cursor: 'pointer' }}
              {...(hasReference && { onClick: () => onSelectTransaction?.(transaction) })}
            >
              <x.div display="flex" alignItems="center">
                <x.span>{transaction?.reference}</x.span>
              </x.div>
            </Badge>
          </Tooltip>
        )
      },
    },
    {
      customKey: 'booking reference',
      render: (_, transaction) => {
        return (
          <Condition when={!!transaction?.booking?.reference} fallback="—">
            <Badge
              variant="grey"
              style={{ w: '90px', cursor: 'pointer' }}
              onClick={() => onSelectBooking?.(transaction?.booking as IBooking)}
            >
              <x.div display="flex" alignItems="center">
                <x.span fontSize="12px">{transaction?.booking?.reference}</x.span>
              </x.div>
            </Badge>
          </Condition>
        )
      },
    },
    {
      key: 'vrm',
      render: (_, transaction) => {
        return <x.span>{transaction?.booking?.vrm}</x.span>
      },
    },
    {
      customKey: 'location',
      render: (_, transaction) => {
        const locationId = transaction?.booking?.locationId as string

        return (
          <Anchor onClick={() => navigate(FE_PATHS.LOCATION(locationId))}>
            <x.span fontSize="12px" color="dark">
              {transaction?.booking?.location?.name}
            </x.span>
          </Anchor>
        )
      },
    },
    {
      customKey: 'device',
      render: (_, transaction) => {
        if (transaction?.booking?.source !== 'KIOSK') return 'N/A'

        const locationId = transaction?.booking?.locationId as string

        return (
          <Anchor onClick={() => navigate(`${FE_PATHS.LOCATION(locationId)}?devices=true`)}>
            <x.span fontSize="12px" color="dark">
              {devices?.find((device) => device.id === transaction?.deviceId)?.name}
            </x.span>
          </Anchor>
        )
      },
    },
    {
      customKey: 'timestamp',
      format: (_, transaction) => dayjs(transaction.createdAt || transaction?.createdAt).format(FORMATS.DATETIME),
    },
    {
      customKey: 'source',
      align: 'left',
      render: (_, transaction) => <ProviderTableCellIcon provider={transaction?.provider?.slug as IProviderSlug} />,
    },
    {
      key: 'type',
      render: (_t, transaction) => (
        <Tag
          variant={Mapper.mapTransactionTypeToColor(transaction.type)}
          style={{ w: '90px', fontWeight: '500', textTransform: 'none', border: 'none', bg: 'transparent' }}
        >
          {Mapper.mapTransactionTypeToLabel(transaction.type)}
        </Tag>
      ),
    },
    {
      key: 'status',
      render: (_t, transaction) => (
        <>
          <Condition when={transaction?.status === 'SUCCESSFUL'}>
            <Badge
              text={_.capitalize(transaction?.status)}
              variant={Mapper.mapTransactionStatusToColor(transaction.status)}
            />
          </Condition>
          <Condition when={transaction?.status !== 'SUCCESSFUL'}>
            <Tooltip
              label={
                transaction?.error ? `${transaction?.error?.code}: ${transaction?.error?.message}` : 'Unknown reason'
              }
              position="left"
              style={{
                display: 'block',
              }}
              withArrow
              color="orange"
            >
              <Badge
                text={_.capitalize(transaction?.status)}
                variant={Mapper.mapTransactionStatusToColor(transaction.status)}
                style={{
                  cursor: 'help',
                }}
              />
            </Tooltip>
          </Condition>
        </>
      ),
    },
    {
      key: 'amount',
      format: (_, transaction) => (transaction.amount ? `£ ${transaction.amount.toFixed(2)}` : undefined),
    },
  ] as THeader<IFullTransaction>[]
}
