import CheckIcon from '@mui/icons-material/Check'
import ArrowDown from '@mui/icons-material/KeyboardArrowDown'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { x } from '@xstyled/styled-components'
import _ from 'lodash'
import React from 'react'

import { Condition } from '../Condition'
import { Label, LabelProps } from '../Label'

import * as S from './SelectDropdown.styled'

export interface SelectDropdownItem {
  label: string
  labelDisplay?: string | JSX.Element
  value: any
  valueDisplay?: string | number | JSX.Element
}

export interface SelectDropdownProps {
  placeholder?: string | JSX.Element
  items?: SelectDropdownItem[]
  value?: any | any[]
  multi?: boolean
  label?: string
  labelProps?: LabelProps
  onSelect?: (values?: SelectDropdownItem[]) => void
  onBlur?: () => React.FocusEventHandler<HTMLSelectElement> | void
  disabled?: boolean
  trigger?: JSX.Element
  theme?: 'light' | 'dark'
  error?: string
}

export const SelectDropdown = ({
  value,
  placeholder,
  items,
  multi,
  label,
  labelProps,
  disabled,
  onSelect,
  onBlur,
  trigger,
  theme = 'light',
  error,
}: SelectDropdownProps) => {
  const selectedItems = items?.filter((item) => {
    if (multi) return value?.includes(item.value)
    return _.isEqual(value, item.value)
  })

  const hasValue = value != null

  const selectedValue = hasValue ? selectedItems?.map((item) => item.valueDisplay || item.label) : null
  const selectedValues = hasValue ? selectedValue?.join(', ') : null

  return (
    <Dropdown.Root onOpenChange={(open) => open === false && onBlur?.()}>
      <S.$Container>
        <Condition when={!!label}>
          <Label {...labelProps}>{label}</Label>
        </Condition>
        <Dropdown.Trigger asChild>
          <x.div>
            {!trigger && (
              <S.$DropdownTrigger data-error={!!error} data-disabled={disabled} className="SelectDropdown__Trigger">
                <x.div display="flex" flexDirection="column" w="100%">
                  <x.div display="flex" alignItems="center" justifyContent="space-between" w="100%">
                    <x.span display="flex" alignItems="center">
                      {(multi ? selectedValues : selectedValue) || <x.span color="greyer">{placeholder}</x.span>}
                    </x.span>
                    <x.div ml="auto" fontSize={20}>
                      <ArrowDown fontSize="inherit" />
                    </x.div>
                  </x.div>
                </x.div>
              </S.$DropdownTrigger>
            )}
            {trigger}
          </x.div>
        </Dropdown.Trigger>
        <x.div opacity={error ? 1 : 0} fontSize={12} color="danger" w="fit-content" fontWeight={600}>
          {error || '_'}
        </x.div>
      </S.$Container>

      {!disabled && (
        <Dropdown.Portal>
          <S.DropdownContent asChild side="bottom" align="start">
            <S.$DropdownMenu themeBg={theme}>
              {items?.map((item, i) => {
                const isItemChecked = !!selectedItems?.find(({ value }) => item.value === value)

                return (
                  <React.Fragment key={`item-${item.label}-${i}`}>
                    <Dropdown.CheckboxItem
                      asChild
                      style={{ outline: 'none' }}
                      checked={isItemChecked}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          if (multi) {
                            onSelect?.([...(selectedItems || []), item])
                          } else {
                            onSelect?.([item])
                          }
                        } else {
                          onSelect?.(selectedItems?.filter((selectedItem) => selectedItem.value !== item.value))
                        }
                      }}
                    >
                      <S.$DropdownMenuItem data-checked={isItemChecked} themeBg={theme}>
                        <Dropdown.ItemIndicator>
                          <CheckIcon fontSize="inherit" />
                        </Dropdown.ItemIndicator>
                        <x.span ml={2}>{item?.labelDisplay || item.label}</x.span>
                      </S.$DropdownMenuItem>
                    </Dropdown.CheckboxItem>
                  </React.Fragment>
                )
              })}
            </S.$DropdownMenu>
          </S.DropdownContent>
        </Dropdown.Portal>
      )}
    </Dropdown.Root>
  )
}
