import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;

  align-items: center;
  transition: all 0.3s ease;
  position: relative;

  ${Styles.screens.small`
    flex-wrap: wrap;
  `}
`

export const $FileAttributes = styled.divBox`
  margin-left: 15px;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  ${Styles.screens.small`
    margin-top: 20px;
  `}
`

export const $FileName = styled.divBox`
  font-weight: 800;
`

export const $FileSize = styled.divBox`
  font-weight: 400;
  color: grayer;
`

export const $ImagePreview = styled.divBox`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid;
  border-color: grey12;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  object-fit: cover;
  transition: all 0.3s ease;

  img {
    object-fit: cover;
  }

  &:hover {
    border: 1px solid grayDim;
    opacity: 0.5;
    filter: grayscale(100%) brightness(70%) contrast(110%);
  }
`

export const $ImagePlaceholder = styled.divBox`
  min-width: 100px;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid transparent;
  border-color: grey12;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  font-size: 20px;

  > * {
    transition: all 0.2s ease-in-out;
    color: grayer;
  }

  &:hover {
    border-color: grey24;

    > * {
      color: dark;
    }
  }

  &:active {
    border-color: dark;

    > * {
      color: dark;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    border-color: grayer;
    cursor: not-allowed;
  }
`
