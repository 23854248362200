import styled from '@xstyled/styled-components'
import { Button } from 'src/components'

export const Container = styled.divBox`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  padding: 10px;

  background-color: white;

  border: 1px solid;
  border-color: grey16;
  border-radius: 10px;

  max-width: 500px;
`

export const FileName = styled.divBox`
  font-size: 14px;
`

export const UploadButton = styled(Button)``
