import { x } from '@xstyled/styled-components'
import { useFormikContext } from 'formik'
import React from 'react'
import {
  Condition,
  Divider,
  FormikSelectDropdown,
  InputField,
  RowFields,
  SelectDropdown,
  ToggleSettingSection,
} from 'src/components'
import { useAuthContext } from 'src/context'

export const LocationConfigsForm = () => {
  const { isAdmin } = useAuthContext()
  const { values, setValues, errors, setFieldValue } = useFormikContext<ILocation>()

  const isPayOnExitLocation = values.configs?.defaultPaymentCollectionType === 'ON_EXIT'

  React.useEffect(() => {
    if (isPayOnExitLocation) {
      setValues({
        ...values,
        configs: {
          ...values.configs,
          extensionGracePeriodMinutes: 0,
        } as LocationConfigs,
      })
    }

    if (values.evConfigs?.supportsPremiumParking === false) {
      setValues({ ...values, evConfigs: { ...values.evConfigs, maxPremiumParkingMinutes: 0 } })
    }

    // eslint-disable-next-line
  }, [isPayOnExitLocation, values.evConfigs?.supportsPremiumParking])

  return (
    <div>
      <RowFields>
        <SelectDropdown
          label="Permit creation frequency"
          placeholder="Select frequency"
          value={values.enforcementConfigs?.permitsCreationFrequency}
          disabled={!isAdmin}
          items={[
            { value: 'IMMEDIATE', label: 'Immediate' },
            { value: 'HOURLY', label: 'Hourly' },
            { value: 'NEVER', label: 'Never' },
          ]}
          onSelect={(selectedItems) => {
            setFieldValue('enforcementConfigs.permitsCreationFrequency', selectedItems?.[0].value)
          }}
          error={errors?.enforcementConfigs?.['permitsCreationFrequency']}
        />
        <InputField
          placeholder="Permit provider's location code"
          nestedName="enforcementConfigs.providerLocationCode"
          label="Permit Provider Location Code"
          disabled={!isAdmin}
          error={errors?.enforcementConfigs?.['providerLocationCode']}
          onChange={(value) => {
            setFieldValue('enforcementConfigs.providerLocationCode', value)
          }}
        />
      </RowFields>

      <RowFields>
        <FormikSelectDropdown
          placeholder="Select the payment collection type"
          name="configs.defaultPaymentCollectionType"
          label="Payment collection type"
          multi={false}
          disabled={!isAdmin}
          items={[
            { label: 'On entry', value: 'ON_ENTRY' },
            { label: 'On exit', value: 'ON_EXIT' },
          ]}
        />
        <InputField
          name="configs.extensionGracePeriodMinutes"
          label="Extension grace period (minutes)"
          placeholder="Grace period minutes"
          type="number"
          disabled={!isAdmin || isPayOnExitLocation}
        />
      </RowFields>

      <Divider light my="12px" />
      <ToggleSettingSection
        title="Camera images"
        heading="This location supports camera images"
        name="configs.shouldUseDecodeImages"
        disabled={!isAdmin}
      />

      <Divider light my="12px" />
      <x.h4 mb="28px" fontSize="16">
        <strong>Checkout options</strong>
      </x.h4>

      <ToggleSettingSection
        title="Standard parking"
        heading="This location supports standard parking as a booking option"
        name="configs.supportsRegularParking"
        disabled={!isAdmin}
      />

      <Condition when={values?.configs?.supportsRegularParking}>
        <x.div mt={6}>
          <InputField
            name="configs.maxStandardParkingMinutes"
            label="Standard parking allowance (minutes)"
            placeholder="Parking minutes"
            type="number"
            disabled={!isAdmin}
          />
        </x.div>
      </Condition>

      <Divider light my="12px" />
      <ToggleSettingSection
        title="Charging only"
        heading="This location supports charge-only as a booking option"
        name="configs.supportsChargeOnly"
        disabled={!isAdmin}
      />

      <Divider light my="12px" />
      <ToggleSettingSection
        title="Park and Charge"
        heading="This location supports Park and Charge as a booking option"
        name="configs.supportsEVCharging"
        disabled={!isAdmin}
      />

      <Condition when={values?.configs?.supportsEVCharging}>
        <Divider light my="24px" />
        <ToggleSettingSection
          title="Allow post-charging parking"
          heading="Allow EVs to add extra parking time to their Park and Charge booking"
          name="evConfigs.supportsPremiumParking"
          disabled={!isAdmin}
        />
        <Condition when={!!values?.evConfigs?.supportsPremiumParking}>
          <x.div mt={6}>
            <InputField
              name="evConfigs.maxPremiumParkingMinutes"
              label="Post-charging parking allowance (minutes)"
              placeholder="Parking minutes"
              type="number"
              disabled={!isAdmin}
            />
          </x.div>
        </Condition>
      </Condition>

      <Condition when={values?.configs?.supportsChargeOnly || values?.configs?.supportsEVCharging}>
        <Divider light my="12px" />
        <x.h4 mb="28px" fontSize="14" fontWeight="600" color="grey100">
          Charging configs
        </x.h4>

        <RowFields mt={6}>
          <InputField
            name="evConfigs.maxChargingMinutes"
            label="Charging allowance (minutes)"
            placeholder="Charging minutes"
            type="number"
            disabled={!isAdmin}
          />
          <InputField
            name="evConfigs.maxChargingPower"
            label="Location energy capacity (KWh)"
            placeholder="Charging power"
            type="number"
            disabled={!isAdmin}
          />
        </RowFields>
      </Condition>
    </div>
  )
}
