import ExportIcon from '@mui/icons-material/FileOpen'
import { Button } from 'src/components'
import { useToast } from 'src/hooks'
import { DownloadLink, getFormattedTimestampInterval } from 'src/utils'

interface IExportButtonProps {
  organisationId?: string
  filters: TransactionFilter
  onClick: () => void
  disabled?: boolean
  text: string
  filename: string
}

export const ExportCsvButton = ({ organisationId, filters, onClick, disabled, text, filename }: IExportButtonProps) => {
  const toast = useToast()

  return (
    <DownloadLink
      fetchRequest={async () => {
        if (!organisationId) throw new Error('No organisation id')
        return onClick()
      }}
      onSuccess={() => toast('Successfully exported', { variant: 'success', autoHideDuration: 2000 })}
      onError={() => toast('Please try again', { variant: 'error', autoHideDuration: 2000 })}
      fileName={`${filename} (${getFormattedTimestampInterval(filters?.timestamp)})`.replace(/ /g, '_')}
      fileType="csv"
      disabled={disabled}
    >
      <Button
        icon={<ExportIcon fontSize="inherit" style={{ marginRight: 5 }} />}
        iconPosition="before"
        text={text}
        variant="accent"
        style={{ marginRight: '8px' }}
      />
    </DownloadLink>
  )
}
