import * as TablerIcons from 'tabler-icons-react'

interface ProviderIconProps extends TablerIcons.IconProps {
  provider?: BookingSource | SessionSource | IProviderSlug
}

export const ProviderIcon = ({ provider, size }: ProviderIconProps) => {
  if (provider === 'ALIO' || provider === 'KIOSK') return <TablerIcons.DeviceDesktop size={size} />
  if (provider === 'NMI' || provider === 'MOBILE') return <TablerIcons.DeviceMobile size={size} />
  if (provider === 'METRIC') return <TablerIcons.Router size={size} />

  return <TablerIcons.QuestionMark size={size} />
}
