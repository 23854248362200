export const isDevelopment = window.location.hostname === 'localhost'
export const isProduction = [
  'twincloud-canary.herokuapp.com',
  'cloud.twinbank.co.uk',
  'office.twinbank.co.uk',
].includes(window.location.hostname)
export const isStaging = window.location.hostname === 'twincloud-staging.herokuapp.com'

export const getEnvironment = () => {
  if (isDevelopment) return 'development'
  if (isProduction) return 'production'
  if (isStaging) return 'staging'

  return 'development'
}

// TODO - update to use the server's environment
export const getCloudUrl = () => {
  if (isDevelopment) return 'http://localhost:4001'
  if (isProduction) return 'https://twin-cloud-api-prod.herokuapp.com'
  if (isStaging) return 'https://twin-cloud-api-staging.herokuapp.com'

  return 'http://localhost:4001'
}
