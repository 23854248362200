import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  height: 38px;
  color: darkGray;
  font-size: 14px;
  transition: all 0.2s ease-in-out;

  &:first-child {
    margin: 5px 0;
  }

  &:hover {
    color: darker;
    background-color: accentFaded;
  }

  &[data-active='true'] {
    color: accent;

    p {
      color: accentHover;
    }

    path {
      fill: accent;
    }
  }
`

export const $Text = styled.h3Box`
  color: grey8;
  font-weight: 500;

  &[data-active='true'] {
    color: accent;
  }
`

export const $Badge = styled.h3Box`
  background-color: accent;
  color: light;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  margin-right: 20px;
`

export const $Icon = styled.divBox`
  color: #97a6ba;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  font-size: 18px;
`
