import React from 'react'
import { UseAxiosFetch } from 'src/hooks'

interface Props {
  data?: any
  fetchRequest?: UseAxiosFetch<any>
  fileName: string
  fileType: string
  children: Children
  style?: React.CSSProperties
  disabled?: boolean
  onError?: (e: any) => void
  onSuccess?: () => void
  onTrigger?: () => void
}

export const DownloadLink = ({
  data,
  fetchRequest,
  fileName,
  fileType,
  children,
  style,
  disabled,
  onTrigger,
  onError,
  onSuccess,
}: Props) => {
  return (
    <div
      style={{ ...style, ...(disabled && { opacity: 0.5, cursor: 'not-allowed', pointerEvents: 'none' }) }}
      onClick={async () => {
        if (disabled) return

        let fetchedData

        try {
          onTrigger?.()

          fetchedData = await fetchRequest?.()

          onSuccess?.()
        } catch (e: any) {
          onError?.(e)

          return
        }

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([data || fetchedData]))
        link.setAttribute('download', `${fileName}.${fileType}`)
        document.body.appendChild(link)
        link.click()
      }}
    >
      {children}
    </div>
  )
}
