import { x } from '@xstyled/styled-components'

import * as S from './Shell.styled'

interface ShellProps extends SCProps<typeof S.$Wrapper> {
  title?: string
  subtitle?: string
  endSlot?: JSX.Element
  loading?: boolean
  error?: boolean
  children?: Children
}

export default function Shell({ endSlot, loading, error, children, ...rest }: ShellProps) {
  return (
    <S.$Wrapper {...rest}>
      <S.$Container>
        <S.$Heading>
          <S.$HeadingRight>
            <x.div>{endSlot}</x.div>
          </S.$HeadingRight>
        </S.$Heading>
        {loading && <S.$Loader className="shell__loader">LOADING</S.$Loader>}
        {!loading && !error && children}
      </S.$Container>
    </S.$Wrapper>
  )
}
