import * as TablerIcons from 'tabler-icons-react'
import { Tooltip } from '@mantine/core'

interface ProviderTableCellIconProps {
  provider: BookingSource | SessionSource | IProviderSlug
}

export const ProviderTableCellIcon = ({ provider }: ProviderTableCellIconProps) => {
  if (provider === 'ALIO' || provider === 'KIOSK') {
    return (
      <Tooltip label="Kiosk" openDelay={500}>
        <TablerIcons.DeviceDesktop size={20} />
      </Tooltip>
    )
  }

  if (provider === 'METRIC') {
    return (
      <Tooltip label="Metric" openDelay={500}>
        <TablerIcons.Router size={20} />
      </Tooltip>
    )
  }

  if (provider === 'NMI' || provider === 'MOBILE') {
    return (
      <Tooltip label="Mobile" openDelay={500}>
        <TablerIcons.DeviceMobile size={20} />
      </Tooltip>
    )
  }

  return (
    <Tooltip label="Unknown" openDelay={500}>
      <TablerIcons.QuestionMark size={20} />
    </Tooltip>
  )
}
