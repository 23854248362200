import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all 1s linear;
  transition-duration: 1s;

  span {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &[data-loading='true'] {
    pointer-events: auto;
    opacity: 0.5;
    background-color: white;
    z-index: 999;

    &[data-opaque='true'] {
      opacity: 1;
    }
  }
`
