import { x } from '@xstyled/styled-components'
import { Text } from 'src/components'

import * as S from './ImportError.styled'

interface ImportErrorProps {
  onShowErrors: () => void
}

export const ImportError = ({ onShowErrors }: ImportErrorProps) => {
  return (
    <S.ErrorMessage>
      <Text type="p" color="inherit">
        There were errors in your CSV. Please fix them and try again.{' '}
        <x.span cursor="pointer" textDecoration="underline" onClick={onShowErrors}>
          Show
        </x.span>
      </Text>
    </S.ErrorMessage>
  )
}
