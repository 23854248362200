import { createGlobalStyle, css } from '@xstyled/styled-components'
import '../node_modules/antd/dist/antd.min.css'

export const GlobalStyles = createGlobalStyle`
	${css`
    @font-face {
      font-family: 'Nunito';
      src: local('Nunito'), url(/fonts/Nunito-Light.ttf) format('truetype');
      font-weight: 400;
    }
    @font-face {
      font-family: 'Nunito';
      src: local('Nunito'), url(/fonts/Nunito-Regular.ttf) format('truetype');
      font-weight: 500;
    }
    @font-face {
      font-family: 'Nunito';
      src: local('Nunito'), url(/fonts/Nunito-SemiBold.ttf) format('truetype');
      font-weight: 600;
    }
    @font-face {
      font-family: 'Nunito';
      src: local('Nunito'), url(/fonts/Nunito-Bold.ttf) format('truetype');
      font-weight: bold;
    }

    html {
      overflow: hidden;
      font-family: 'Nunito', sans-serif;
      /* scrollbar width */
      *::-webkit-scrollbar {
        height: 5px;
        width: 5px;
      }

      /* scrollbar track */
      *::-webkit-scrollbar-track {
        background-color: grey8;
        border-radius: 8px;
      }

      /* scrollbar handle */
      *::-webkit-scrollbar-thumb {
        background-color: grey24;
        border-radius: 8px;
      }

      /* scrollbar handle (hover) */
      *::-webkit-scrollbar-thumb:hover {
        background-color: grey48;
        cursor: pointer;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    b,
    strong,
    div {
      font-family: 'Nunito';
      margin-bottom: 0;
    }

    button {
      background-color: transparent;
      min-height: 34px;
      outline: none;
      padding: 0;
    }

    .notistack-error,
    .notistack-info,
    .notistack-success,
    .notistack-warning,
    .notistack-root,
    .notistack {
      border-radius: 10px !important;
    }

    .notistack-success {
      background-color: #179b6a !important;
    }

    .MuiAppBar-colorPrimary {
      background-color: transparent !important;
      box-shadow: none !important;
      color: 'unset';
    }

    .MuiInputLabel-formControl {
      top: -2px !important;
      left: 2px !important;
      font-size: 18px !important;
    }

    /* ANTD Styles */
    .ant-btn-primary {
      background-color: accent;
      border-color: accent;

      :hover {
        background-color: accent;
        border-color: accent;
      }
    }

    .ant-select-item {
      font-size: 12px;
      line-height: 24px;
    }

    .ant-select-item-option-selected {
      background-color: accentFaded !important;
    }

    .anticon-check {
      path {
        fill: accent;
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: accent !important;
      border-right-width: 1px !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .ant-picker {
      width: 100%;
      border-radius: 10px;
      min-height: 32px;
      padding: 8px 12px;

      :hover {
        border-color: accent;
      }
    }

    .ant-picker-range .ant-picker-active-bar {
      background-color: accent;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: accent;
    }

    .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
      background-color: accentFaded;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid accent;
    }

    .ant-picker-range .ant-picker-clear {
      right: 30px;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: accent;
    }

    .ant-picker-focused {
      border-color: accent;
      border-right-width: 1px !important;
      outline: none;
      box-shadow: none;
    }
  `}
`
