import { CheckIcon } from '@radix-ui/react-icons'
import React from 'react'

import * as S from './Checkbox.styled'

interface CheckboxProps {
  id: string
  label?: string | React.ReactNode
  checked?: boolean
  defaultChecked?: boolean
  onCheckedChange?: (checked: boolean) => void
}

export const Checkbox = ({ id, label, checked, defaultChecked, onCheckedChange }: CheckboxProps) => (
  <S.Container>
    <S.Root
      className="Root"
      id={id}
      checked={checked}
      onCheckedChange={onCheckedChange}
      defaultChecked={defaultChecked}
    >
      <S.Indicator className="Indicator">
        <CheckIcon />
      </S.Indicator>
    </S.Root>
    <S.Label htmlFor={id} data-state={checked ? 'checked' : 'unchecked'}>
      {label}
    </S.Label>
  </S.Container>
)
