import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

export const getWarningDevicesByHeartbeats = (devices: IDevice[], heartbeats: IDeviceHeartbeat[] = []) => {
  const warningDeviceIds = heartbeats
    ?.filter(({ level, data }) => {
      const { alio, printer } = data ?? {}

      const hasAlio = !!alio
      const hasPrinter = !!printer

      const isKioskWarning = level === 'warn'
      const isAlioWarning = hasAlio && alio.level === 'warn'
      const isPrinterWarning = hasPrinter && printer.level === 'warn'

      return isKioskWarning || isAlioWarning || isPrinterWarning
    })
    .map(({ deviceId }) => deviceId)

  const warningDevices = devices?.filter(({ id }) => warningDeviceIds?.includes(id))

  return warningDevices
}
