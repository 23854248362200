import styled from '@xstyled/styled-components'

export const $Container = styled.divBox`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 50px;
  padding: 0 30px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid lightGray;
  padding: 2px 6px;
  font-size: 14px;
  color: dark;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1px solid accentHover;
  }

  &:focus-within:not([data-error='true']) {
    border: 1px solid accentHover;
  }
`

export const $Value = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
`

export const $Select = styled.selectBox`
  width: 100%;
  border: none;
  padding: 2px 6px;
  background-color: transparent;
  font-size: 12px;

  &:disabled {
    color: grayer;
  }

  &:focus {
    outline: none;
  }
`

export const $Icon = styled.divBox`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  color: grayer;
  transition: all 0.2s ease-in-out;
`
