import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  background-color: white;
  height: 40px;
  min-width: 300px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid;
  border-color: grey12;
  padding: 2px 6px;
  font-size: 14px;
  color: dark;
  transition: all 0.2s ease-in-out;

  ${Styles.screens.small`
		min-width: unset;
		margin-right: auto;
		margin-left: 20px;
	`}

  &:focus-within {
    border-color: grey24;
  }
`

export const $Icon = styled.divBox`
  display: flex;
  align-items: center;
  color: grayer;
`

export const $Search = styled.divBox`
  width: 100%;
  background-color: transparent;
`

export const $Input = styled.inputBox`
  background-color: transparent;
  width: 100%;
  border: none;
  padding: 2px 6px;

  &:focus {
    outline: none;
  }
`
