import React from 'react'

import { ToggleField, ToggleFieldProps } from '../ToggleField'

import * as S from './ToggleSettingSection.styled'

interface ToggleSettingSectionProps extends ToggleFieldProps {
  title: string
  heading: string
}

export const ToggleSettingSection = ({ title, heading, ...toggleFieldProps }: ToggleSettingSectionProps) => {
  return (
    <S.$Container>
      <S.$DetailsContainer>
        <S.$Title>{title}</S.$Title>
        <S.$Heading>{heading}</S.$Heading>
      </S.$DetailsContainer>
      <ToggleField {...toggleFieldProps} />
    </S.$Container>
  )
}
