import PhotoIcon from '@mui/icons-material/PhotoCamera'
import React from 'react'
import { useFileUploadHandlers } from 'src/hooks'

import { convertBytesIntoHumanReadable, truncateInTheMiddle } from './util'
import * as S from './ImageUpload.styled'

interface ImageUploadProps {
  accept: string
  label: string
  disabled?: boolean
  dataUrl?: string
  onFileChoose: (file: File, dataUrl?: string | ArrayBuffer | null) => void
  size?: number
}

export const ImageUpload = ({
  accept,
  dataUrl: initialDataUrl,
  disabled,
  size = 50,
  onFileChoose,
}: ImageUploadProps) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [dataUrl, setDataUrl] = React.useState<string | ArrayBuffer | null>(initialDataUrl || null)

  const placeholder = initialDataUrl ? 'Change logo' : 'Upload logo'

  React.useEffect(() => {
    setDataUrl(initialDataUrl || null)
  }, [initialDataUrl])

  const { handleFileSelect } = useFileUploadHandlers()

  const onFileLoad = React.useCallback(
    (file: File, dataUrl: string | ArrayBuffer | null) => {
      setFile(file)
      setDataUrl(dataUrl)
      onFileChoose(file, dataUrl)
    },
    [setFile, setDataUrl],
  )

  return (
    <S.$Container>
      <S.$ImagePlaceholder onClick={() => !disabled && handleFileSelect({ accept, onFileLoad })}>
        <PhotoIcon fontSize="inherit" className="photo-icon" />
      </S.$ImagePlaceholder>
      {dataUrl && (
        <S.$ImagePreview onClick={() => !disabled && handleFileSelect({ accept: 'image', onFileLoad })}>
          <img height={size} width={size} src={dataUrl as string} alt="Uploaded file" />
        </S.$ImagePreview>
      )}
      <S.$FileAttributes>
        <S.$FileName>{truncateInTheMiddle(file?.name, 20) || placeholder}</S.$FileName>
        <S.$FileSize>{convertBytesIntoHumanReadable(file?.size)}</S.$FileSize>
      </S.$FileAttributes>
    </S.$Container>
  )
}
