import React from 'react'
import { BE_PATHS } from 'src/constants/paths'
import { useOrganisationContext } from 'src/context'
import { useAxios, UseAxiosData } from 'src/hooks'
import { genQueryParams, sortBy } from 'src/utils'

export const useBarriers = ({
  searchTerm,
}: {
  searchTerm?: string
}): [UseAxiosData<IBarrier[]>, () => Promise<void>] => {
  const { organisation, locations } = useOrganisationContext()
  const [barriers, setBarriers] = React.useState<IBarrier[]>([])

  const [{ data: barriersData, fetching }, refetchBarriers] = useAxios<IBarrier[]>({
    url: BE_PATHS.BARRIERS(organisation?.id as string),
    params: {
      filter: genQueryParams({ locationId: { in: locations?.map(({ id }) => id) } }),
    },
    pause: !organisation,
    runOnInit: true,
  })

  React.useEffect(() => {
    if (!barriersData) return

    setBarriers(barriersData || [])
  }, [barriersData])

  const handleRefetchBarriers = React.useCallback(async () => {
    const response = await refetchBarriers({
      params: {
        filter: genQueryParams({
          OR: [{ name: { contains: searchTerm } }],
        }),
      },
    })

    if (!response) return

    const sortedBarriers = response?.sort(sortBy('createdAt', 'DESC'))

    setBarriers(sortedBarriers)

    // eslint-disable-next-line
  }, [refetchBarriers, organisation, searchTerm])

  React.useEffect(() => {
    handleRefetchBarriers()

    //eslint-disable-next-line
  }, [organisation, searchTerm])

  return [{ data: barriers, fetching }, handleRefetchBarriers]
}
