import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { FORMATS } from 'src/constants/formats'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const convertToSince = (time: number) => {
  const since = dayjs().subtract(time, 'seconds')
  return dayjs(since).fromNow().split('ago')[0].trim()
}

export const convertToDuration = (startDate?: Date, endDate?: Date | null) => {
  if (!startDate) return '—'

  const durationInDays = dayjs(endDate || new Date()).diff(dayjs(startDate), 'days')
  const durationInHours = dayjs(endDate || new Date()).diff(dayjs(startDate), 'hours') % 24
  const durationInMinutes = dayjs(endDate || new Date()).diff(dayjs(startDate), 'minutes') % 60
  const durationInSeconds = dayjs(endDate || new Date()).diff(dayjs(startDate), 'seconds') % 60

  if (durationInDays > 0) {
    return `${durationInDays}d ${durationInHours}h ${durationInMinutes}m`
  }

  if (durationInHours > 0) {
    return `${durationInHours}h ${durationInMinutes}m`
  }

  return `${durationInMinutes}m ${durationInSeconds}s`
}

export const getFormattedTimestampInterval = (
  timestamp: { gt?: string; gte?: string; lt: string; lte?: string },
  format?: string,
) => {
  const startDate = timestamp?.gte || timestamp?.gt
  const endDate = timestamp?.lte || timestamp?.lt

  return `${dayjs(startDate).format(format || FORMATS.DATETIMELONG)} - ${dayjs(endDate).format(
    format || FORMATS.DATETIMELONG,
  )}`
}

const defaultTz = 'Europe/London'

interface AdjustedDateParams {
  date: Date | string
  tz?: string
}

export const getISOString = ({ date, tz = defaultTz }: AdjustedDateParams) => {
  return dayjs.tz(date, tz).toISOString()
}

export const getDayjsDate = ({ date, tz = defaultTz }: AdjustedDateParams) => {
  const utcDate = dayjs(date)
  const tzAdjustedDate = utcDate.tz(tz)

  return tzAdjustedDate
}
