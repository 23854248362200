import { Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import * as TablerIcons from 'tabler-icons-react'
import ClipboardIcon from '@mui/icons-material/ContentCopy'
import ExternalLinkIcon from '@mui/icons-material/OpenInNew'
import React from 'react'
import { useAuthContext } from 'src/context'
import { EmptyState, Table, Condition, Button, TableAction } from 'src/components'
import { buildURL } from 'src/utils'
import { useCopyToClipboard } from 'src/hooks'
import { getCloudUrl } from 'src/constants'

import { DeleteCameraModal } from './components/DeleteCameraModal'
import { CameraModal } from './components/CameraModal'

interface LocationCamerasProps {
  cameras?: ICamera[]
  locationId: string
  onDataRefetch: () => void
}

const capitalizeFirstLettersAndRemoveUnderscores = (value: string) => {
  if (!value) return ''

  const withoutUnderscores = value.replace(/_/g, ' ')
  const firstLetterOfEachWordRegex = /(?:^|\s)\S/g

  const capitalized = withoutUnderscores.replace(firstLetterOfEachWordRegex, (match) => match.toUpperCase())

  return capitalized
}

export const LocationCameras = ({ cameras, locationId, onDataRefetch }: LocationCamerasProps) => {
  const { isAdmin } = useAuthContext()

  const [cameraModalOpen, setCameraModalOpen] = React.useState<boolean>(false)
  const [deleteCameraModalOpen, setDeleteCameraModalOpen] = React.useState<boolean>(false)

  const [cameraToDelete, setCameraToDelete] = React.useState<ICamera>()
  const [cameraToEdit, setCameraToEdit] = React.useState<ICamera>()
  const handleDelete = async (device: ICamera) => {
    setCameraToDelete(device)
    setDeleteCameraModalOpen(true)
  }

  const copyToClipboard = useCopyToClipboard()

  const actions: TableAction<ICamera>[] = [
    isAdmin && {
      label: 'Copy decodes URL for camera',
      onClick: (item: ICamera) => {
        const provider = item?.provider?.toLowerCase()

        const url = `${getCloudUrl()}/api/${provider}/decodes?c=${item?.code}`

        copyToClipboard(url)
      },
      icon: <ClipboardIcon width={24} />,
      tooltip: 'Copy decodes URL for camera',
    },
    {
      label: 'Open in new tab',
      onClick: (item: ICamera) => {
        const url = buildURL({ domain: item?.domain, port: item?.port, protocol: 'http' })

        window.open(url, '_blank')
      },
      icon: <ExternalLinkIcon width={24} />,
      tooltip: 'Open in new tab',
    },
    isAdmin && {
      label: 'Edit',
      onClick: (item: ICamera) => {
        setCameraToEdit(item)
        setCameraModalOpen(true)
      },
      type: 'edit',
    },
    {
      label: 'Delete',
      onClick: handleDelete,
      type: 'delete',
    },
  ].filter(Boolean) as TableAction<ICamera>[]

  return (
    <div>
      <Condition when={isAdmin}>
        <x.div mt="10px" mb="20px" w="100%" display="flex">
          <Button text="+ Add camera" variant="accent" onClick={() => setCameraModalOpen(true)} />
        </x.div>
      </Condition>
      {cameras && cameras?.length === 0 && (
        <EmptyState
          title="There are no cameras currently set up"
          subtitle="Cameras serve as an excellent means for you to customize and oversee the status and remote capabilities of surveillance systems deployed across different locations."
          icon={<TablerIcons.VideoOff size={60} />}
          buttonProps={
            isAdmin
              ? {
                  text: 'Add a camera',
                  variant: 'accent',
                  onClick: () => setCameraModalOpen(true),
                  style: { h: '50px' },
                }
              : undefined
          }
        />
      )}
      {cameras && cameras.length > 0 && (
        <Table
          items={cameras}
          headers={[
            { key: 'name' },
            { key: 'type', format: capitalizeFirstLettersAndRemoveUnderscores },
            { key: 'provider', format: capitalizeFirstLettersAndRemoveUnderscores },
            { key: 'direction', format: capitalizeFirstLettersAndRemoveUnderscores },
            {
              key: 'domain',
              render: (domain, camera) => {
                const url = buildURL({ domain, port: camera?.port, protocol: 'http' })

                return (
                  <Anchor onClick={() => window.open(url, '_blank')}>
                    <x.span fontSize="12px" color="dark">
                      {domain}
                    </x.span>
                  </Anchor>
                )
              },
            },
            { key: 'port' },
            { key: 'code' },
          ]}
          actions={actions}
        />
      )}
      <Condition when={isAdmin}>
        <CameraModal
          isOpen={cameraModalOpen}
          camera={cameraToEdit}
          locationId={locationId}
          onSubmitSuccess={async () => {
            await onDataRefetch()
          }}
          onClose={() => {
            setCameraModalOpen(false)
            setCameraToEdit(undefined)
          }}
        />
      </Condition>
      <Condition when={isAdmin}>
        <DeleteCameraModal
          camera={cameraToDelete as ICamera}
          locationId={locationId}
          isOpen={deleteCameraModalOpen}
          onClose={() => setDeleteCameraModalOpen(false)}
          onDelete={async () => {
            await onDataRefetch()
          }}
        />
      </Condition>
    </div>
  )
}
