import MenuIcon from '@mui/icons-material/Menu'

import { Breadcrumb, BreadcrumbProps } from '../Breadcrumb'
import { Searchbar, SearchbarProps } from '../Searchbar'

import * as S from './Topbar.styled'

type TopbarProps = { breadcrumb?: BreadcrumbProps } & SearchbarProps & {
    onMenuClick: () => void
    searchValue?: string
    endSlot?: React.ReactNode
  }

export default function Topbar({
  onSearch,
  sanitizer,
  searchValue,
  searchPlaceholder,
  onMenuClick,
  endSlot,
  breadcrumb,
}: TopbarProps) {
  return (
    <S.$Container>
      {(onSearch || endSlot) && (
        <S.$Bar>
          <S.$Menu onClick={onMenuClick}>
            <MenuIcon />
          </S.$Menu>
          {onSearch && (
            <Searchbar
              onSearch={onSearch}
              sanitizer={sanitizer}
              value={searchValue}
              searchPlaceholder={searchPlaceholder}
            />
          )}
          <S.$End>{endSlot}</S.$End>
        </S.$Bar>
      )}
      {breadcrumb && <Breadcrumb items={breadcrumb?.items} />}
    </S.$Container>
  )
}
