import styled from '@xstyled/styled-components'

export const $Profile = styled.divBox`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: 100%;
  z-index: 10;
`

export const $Image = styled.imgBox`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: transparent;
  display: none;
`

export const $User = styled.divBox``

export const $UserName = styled.divBox`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 600;
`

export const $UserEmail = styled.divBox`
  color: grey24;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
`

export const $Icon = styled.divBox`
  margin-left: auto;
  margin-right: 20px;
  color: grayer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: dark;
  }
`

export const $Dropdown = styled.divBox`
  position: absolute;
  z-index: 10;
`

export const $Menu = styled.divBox`
  border-radius: 10px;
  padding: 10px;
  min-width: 220px;
  background-color: rgba(251, 251, 251, 0.9);
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.32), 0 6px 16px 0 rgba(0, 0, 0, 0.1), 0 9px 28px 4px rgba(0, 0, 0, 0.1);

  transition: all 0.2s ease-in-out;
`

export const $MenuItem = styled.divBox`
  border-radius: 5px;
  z-index: 100;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #80808049;
    transition: all 0.2s ease-in-out;
  }

  &:focus {
    background-color: #5f5f5f49;
    transition: all 0.2s ease-in-out;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 4px 8px;
    background-color: transparent !important;

    :hover {
      background-color: #80808049;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
    }

    &:focus {
      background-color: #5f5f5f49;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
    }
  }
`

export const $AntDropdown = styled.divBox`
  > .ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
  > .ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
  > .ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    padding-top: 0 !important;
  }

  > .ant-dropdown-placement-topLeft {
    left: 80px !important;
  }

  > .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  > .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  > .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: -4px !important;
  }

  > .ant-dropdown {
    z-index: 9999;
  }

  > .ant-dropdown::before {
    top: -20px !important;
  }
`
