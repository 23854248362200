import { IDevice, IDeviceHeartbeat } from 'src/typings/device'

import { HealthFilter } from '../types'

import { getDegradedDevicesByHeartbeats } from './getDegradedDevicesByHeartbeats'
import { getHealthyDevicesByHeartbeats } from './getHealthyDevicesByHeartbeats'
import { getWarningDevicesByHeartbeats } from './getWarningDevicesByHeartbeats'

export const getDevicesByHealth = (
  devices: IDevice[],
  heartbeats?: IDeviceHeartbeat[],
  healthFilter?: HealthFilter,
) => {
  if (healthFilter === 'healthy') return getHealthyDevicesByHeartbeats(devices, heartbeats)
  if (healthFilter === 'warning') return getWarningDevicesByHeartbeats(devices, heartbeats)
  if (healthFilter === 'degraded') return getDegradedDevicesByHeartbeats(devices, heartbeats)

  return devices
}
