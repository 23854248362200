import styled from '@xstyled/styled-components'

export const $Error = styled.divBox`
  background-color: white;
  color: danger;
  min-height: 20px;
  position: relative;
  margin-right: auto;
  font-size: 12px;
  font-weight: 600;
  opacity: 1;

  transition: all 0.3s ease;

  &::first-letter {
    text-transform: capitalize;
  }

  &[data-visible='false'] {
    opacity: 0;
  }
`
