import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('* Required'),
  logo: yup.string().optional(),
  contact1: yup.object().shape({
    name: yup.string().required('* Required'),
    email: yup.string().email('* Invalid email').required('* Required'),
    phone: yup.string().required('* Required'),
  }),
  contact2: yup
    .object()
    .shape({
      name: yup.string().optional(),
      email: yup.string().email('* Invalid email').optional(),
      phone: yup.string().optional(),
    })
    .nullable(),
})

export default schema
