import styled, { css } from '@xstyled/styled-components'

const $DatePickerBase = css`
  background-color: white;
  box-shadow: none;
  width: 100%;
  border: 1px solid grayDim;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  padding: 8px 12px;
  min-height: 32px;

  font-size: 12px;
  line-height: 12px;
  cursor: pointer;

  &:hover {
    border-color: grayer;
  }
`

export const $Container = styled.divBox`
  > .ant-picker,
  > .ant-picker-focused,
  > .ant-picker-range,
  > .date-picker {
    ${$DatePickerBase}

    > .ant-picker-input {
      line-height: 12px;
      font-size: 12px;

      input {
        cursor: pointer;
        font-size: 12px;

        &::placeholder {
          color: grayer;
        }
      }
    }
  }

  > .ant-picker-panel-container {
    border-radius: 10px;
    font-size: 12px;

    button:hover {
      color: accent;
    }
  }
`
