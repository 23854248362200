import React from 'react'

interface FileSelectOpts {
  accept: string
  maxSize?: number
  onFileLoad?: (file: File, dataUrl: string | ArrayBuffer | null) => void
}

const MAX_FILE_SIZE = 3000000 // 3MB

interface FileResult {
  file: File
  data: string | ArrayBuffer | null
}

export const useFileUploadHandlers = () => {
  const handleFileSelect = React.useCallback(
    ({ accept, maxSize = MAX_FILE_SIZE, onFileLoad }: FileSelectOpts): Promise<FileResult> => {
      return new Promise((resolve, reject) => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = accept

        input.click()

        input.onchange = (e) => {
          try {
            const file = (e?.target as any)?.files?.[0] as File

            if (file) {
              const reader = new FileReader()

              reader.onload = (e) => {
                const fileData = e?.target?.result
                if (!fileData) return

                if (file.size > maxSize) {
                  alert('File size is too big. Max allowed: 3MB')
                  return null
                }

                onFileLoad?.(file, fileData)

                resolve({ file, data: fileData })
              }

              reader.readAsDataURL(file)
            }
          } catch (error) {
            reject(error)
          }
        }
      })
    },
    [],
  )

  return { handleFileSelect }
}
