import { x } from '@xstyled/styled-components'
import { Form, useFormikContext } from 'formik'
import React from 'react'
import { Divider, ImageUpload, InputField, InputFieldProps } from 'src/components'

const OrganisationInputField = (props: InputFieldProps<Partial<Organisation>>) =>
  InputField<Partial<Organisation>>(props)

interface OrganisationFormProps {
  onChange?: (data: Partial<Organisation>) => void
  organisation?: Partial<Organisation>
  isActive?: boolean
  errors?: any
}

export default function OrganisationForm({ organisation, onChange, isActive, errors }: OrganisationFormProps) {
  const formik = useFormikContext<Partial<Organisation>>()

  React.useEffect(() => {
    formik?.resetForm()
    formik.setValues(organisation ?? {}, false)
    // eslint-disable-next-line
  }, [organisation, isActive])

  React.useEffect(() => {
    onChange?.(formik.values)
    // eslint-disable-next-line
  }, [formik.values])

  return (
    <Form onSubmit={formik.handleSubmit} style={{ minWidth: '100%' }}>
      <x.div display="flex" spaceX={10}>
        <x.div w="100%">
          <h4 className="--margin-bottom-medium --margin-top-medium">
            <strong>DETAILS</strong>
          </h4>
          <Divider light />
          <br />
          <OrganisationInputField placeholderName="organisation" name="name" label="Name" error={errors?.name} />
          <br />
          <OrganisationInputField
            placeholder="Permit provider"
            name="permitProvider"
            label="Permit provider"
            error={errors?.permitProvider}
          />
          <br />
        </x.div>
        <x.div w="100%">
          <h4 className="--margin-bottom-medium --margin-top-medium">
            <strong>BRAND</strong>
          </h4>
          <Divider light />
          <br />
          <ImageUpload
            dataUrl={organisation?.logo as string}
            accept="image/*"
            label="Logo"
            size={100}
            onFileChoose={(_, logoData) => {
              formik.setFieldValue('logo', logoData)
            }}
          />
          <br />
        </x.div>
      </x.div>

      <x.div display="flex" spaceX={10}>
        <x.div w="100%">
          <h4 className="--margin-bottom-medium --margin-top-medium">
            <strong>KEY ADMINISTRATOR 1</strong>
          </h4>
          <Divider light />
          <br />
          <OrganisationInputField
            placeholderName="key adminstrator"
            nestedName="contact1.name"
            label="Name"
            error={errors?.contact1?.name}
          />
          <br />
          <OrganisationInputField
            placeholderName="key adminstrator"
            nestedName="contact1.email"
            label="Email"
            error={errors?.contact1?.email}
          />
          <br />
          <OrganisationInputField
            placeholderName="key adminstrator"
            nestedName="contact1.phone"
            label="Phone number"
            error={errors?.contact1?.phone}
          />
          <br />
        </x.div>
        <x.div w="100%">
          <h4 className="--margin-bottom-medium --margin-top-medium">
            <strong>KEY ADMINISTRATOR 2 (optional)</strong>
          </h4>
          <Divider light />
          <br />
          <OrganisationInputField placeholderName="key adminstrator" nestedName="contact2.name" label="Name" />
          <br />
          <OrganisationInputField placeholderName="key adminstrator" nestedName="contact2.email" label="Email" />
          <br />
          <OrganisationInputField placeholderName="key adminstrator" nestedName="contact2.phone" label="Phone number" />
          <br />
        </x.div>
      </x.div>
    </Form>
  )
}
