import { Route, Routes } from 'react-router-dom'
import { Condition } from 'src/components'
import { useAuthContext } from 'src/context'

import { FE_PATHS } from '../constants'
import {
  BarrierEvents,
  Barriers,
  Bookings,
  Dashboard,
  EvReports,
  Location,
  Locations,
  Login,
  Metrics,
  Organisations,
  Permits,
  PermitsImport,
  Tariffs,
  Transactions,
  Unauthorized,
  Users,
} from '../screens'

const RoutesController = () => {
  const { isAdmin, isAtLeastManager } = useAuthContext()

  return (
    <Routes>
      <Route path={FE_PATHS.LOGIN} element={<Login />} />
      <Route path={FE_PATHS.BOOKINGS} element={<Bookings />} />
      <Route path={FE_PATHS.DASHBOARD} element={<Dashboard />} />
      <Route path={FE_PATHS.LOCATIONS} element={<Locations />} />
      <Route path={FE_PATHS.BARRIERS} element={<Barriers />} />
      <Route
        path={FE_PATHS.BARRIER_EVENTS}
        element={
          <Condition when={isAtLeastManager} fallback={<Unauthorized />}>
            <BarrierEvents />
          </Condition>
        }
      />
      <Route path={`${FE_PATHS.LOCATIONS}/:locationId`} element={<Location />} />
      <Route
        path={FE_PATHS.ORGANISATIONS}
        element={
          <Condition when={isAdmin} fallback={<Unauthorized />}>
            <Organisations />
          </Condition>
        }
      />
      <Route path={FE_PATHS.TRANSACTIONS} element={<Transactions />} />
      <Route path={FE_PATHS.PERMITS} element={<Permits />} />
      <Route
        path={FE_PATHS.PERMITS_IMPORT}
        element={
          <Condition when={isAdmin} fallback={<Unauthorized />}>
            <PermitsImport />
          </Condition>
        }
      />
      <Route path={FE_PATHS.METRICS} element={<Metrics />} />
      <Route path={FE_PATHS.TARIFFS} element={<Tariffs />} />
      <Route
        path={FE_PATHS.USERS}
        element={
          <Condition when={isAdmin} fallback={<Unauthorized />}>
            <Users />
          </Condition>
        }
      />
      <Route
        path={FE_PATHS.EV_REPORTS}
        element={
          <Condition when={isAtLeastManager} fallback={<Unauthorized />}>
            <EvReports />
          </Condition>
        }
      />
    </Routes>
  )
}

export default RoutesController
