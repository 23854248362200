import styled, { system } from '@xstyled/styled-components'

export const $Text = styled.pBox`
  &[data-type='h1'] {
    font-size: h1;
    font-weight: bold;
  }

  &[data-type='h2'] {
    font-size: h2;
    font-weight: bold;
  }

  &[data-type='h3'] {
    font-size: h3;
    font-weight: bold;
  }

  &[data-type='h4'] {
    font-size: h4;
    font-weight: bold;
  }

  &[data-type='h5'] {
    font-size: h5;
    font-weight: bold;
  }

  &[data-type='h6'] {
    font-size: h6;
    font-weight: bold;
  }

  &[data-type='p'] {
    font-size: medium;
    font-weight: normal;
  }

  &[data-type='span'] {
    font-size: medium;
    font-weight: normal;
  }

  color: grey100;

  && {
    ${system}
  }
`
