import styled from '@xstyled/styled-components'
import { Button } from 'src/components'

export const Container = styled.divBox`
  display: flex;
  flex-direction: row;
  gap: 30px;

  align-items: center;
  justify-content: space-between;

  padding: 10px;

  background-color: white;

  border: 1px solid;
  border-color: grey16;
  border-radius: 10px;

  max-width: 500px;
`

export const Status = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`

export const StatusText = styled.pBox`
  font-size: 14px;
  font-weight: semibold;
`

export const UploadButton = styled(Button)``
