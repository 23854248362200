import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('* Required'),
  providerId: yup.string().required('* Required').default(''),
  chargePointId: yup.number().required('* Required'),
  evseId: yup.number().required('* Required'),
  evseNetworkId: yup.number().required('* Required'),
})

export default schema
