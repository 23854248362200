import TariffsIcon from '@mui/icons-material/LocalOffer'
import _ from 'lodash'
import React from 'react'
import { useOrganisationContext } from 'src/context'
import { Badge, Button, Condition, EmptyState, Table } from 'src/components'
import { Page, Shell } from 'src/containers'

import { DeleteTariffModal, TariffModal } from './Modals'

export function Tariffs() {
  const { organisation, tariffs = [], fetchingOrgData, refetchOrgData } = useOrganisationContext()

  const [searchTariffs, setSearchTariffs] = React.useState<ITariff[]>()

  const [deleteTariffModalOpen, setDeleteTariffModalOpen] = React.useState(false)
  const [tariffModalOpen, setTariffModalOpen] = React.useState<boolean>(false)

  const [tariffToDelete, setTariffToDelete] = React.useState<ITariff>()
  const [tariffToEdit, setTariffToEdit] = React.useState<ITariff>()

  const handleDelete = async (tariff: ITariff) => {
    setTariffToDelete(tariff)
    setDeleteTariffModalOpen(true)
  }

  const handleSearch = (searchTerm: string) => {
    if (searchTerm) {
      setSearchTariffs([
        ...tariffs.filter(({ name }) => {
          if (name?.toLowerCase().includes(searchTerm?.toLowerCase())) return true
          return false
        }),
      ])
    } else {
      setSearchTariffs(undefined)
    }
  }

  React.useEffect(() => {
    refetchOrgData()
    // eslint-disable-next-line
  }, [organisation?.id])

  return (
    <Page
      onSearch={handleSearch}
      endSlot={
        <Condition when={!!tariffs && tariffs.length > 0}>
          <Button text="+ Add" onClick={() => setTariffModalOpen(true)} variant="accent" />
        </Condition>
      }
    >
      <Shell title="Tariffs" subtitle="Review your tariffs">
        {fetchingOrgData && !tariffs && <h3>Loading...</h3>}
        {!fetchingOrgData && tariffs && tariffs.length === 0 && (
          <EmptyState
            title="There are no tariffs currently set up"
            subtitle="Tariffs are a great way for you to set and track prices on remote tariffs deployed in locations. Start by adding a new tariff. This will then appear here."
            icon={<TariffsIcon />}
            buttonProps={{
              text: 'Create a tariff',
              variant: 'accent',
              onClick: () => setTariffModalOpen(true),
              style: { h: '50px' },
            }}
          />
        )}
        {tariffs && tariffs.length > 0 && (
          <Table
            items={searchTariffs || tariffs}
            headers={[
              { key: 'name' },
              {
                customKey: 'location',
                format: (_, tariff?: ITariff) => tariff?.location?.name,
              },
              {
                customKey: 'rates',
                align: 'center',
                format: (_, tariff?: ITariff) => String(tariff?.rates?.length),
              },
              {
                customKey: 'type',
                render: (_t, tariff?: ITariff) => (
                  <Badge text={_.capitalize(tariff?.type)} variant={tariff?.type === 'regular' ? 'yellow' : 'purple'} />
                ),
              },
            ]}
            actions={[
              {
                label: 'Edit',
                onClick: (item) => {
                  setTariffToEdit(item)
                  setTariffModalOpen(true)
                },
                type: 'edit',
              },
              { label: 'Delete', onClick: handleDelete, type: 'delete' },
            ]}
          />
        )}
        <TariffModal
          isOpen={tariffModalOpen}
          tariff={tariffToEdit}
          onSubmitSuccess={async () => {
            refetchOrgData()
          }}
          onClose={() => {
            setTariffModalOpen(false)
            setTariffToEdit(undefined)
          }}
        />
        <DeleteTariffModal
          tariff={tariffToDelete as ITariff}
          isOpen={deleteTariffModalOpen}
          onClose={() => setDeleteTariffModalOpen(false)}
          onDelete={async () => {
            refetchOrgData()
          }}
        />
      </Shell>
    </Page>
  )
}
