import { DatePicker as AntdDatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'

import { Label } from '../Label'

import * as S from './RangePicker.styled'

export const RangePicker = (props: RangePickerProps<any> & { label: string | React.ReactNode }) => {
  return (
    <S.$Wrapper>
      <Label>{props?.label}</Label>
      <S.$Container>
        <AntdDatePicker.RangePicker className="date-picker" {...props} />
      </S.$Container>
    </S.$Wrapper>
  )
}
