import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import packageJson from '../../package.json'

export const init = () =>
  Sentry.init({
    dsn: 'https://1ba06473e7be42261394bd20861d7c83@o1124035.ingest.us.sentry.io/4506903797039104',
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.NODE_ENV,
    release: packageJson?.version,
  })

export const setTags = Sentry.setTags

interface ErrorContext {
  extras?: Record<string, any>
  message?: string
}

export const error = (error: any, { message, extras = {} }: ErrorContext = {}) => {
  Sentry.captureException(error, (scope) => {
    scope.setTransactionName(message ?? error?.message ?? 'Unknown error')
    scope.setExtras(extras)

    return scope
  })
}

export const debug = (message: string, extra?: Record<string, any>) => {
  Sentry.captureMessage(message, { extra })
}

export const withErrorBoundary = Sentry.withErrorBoundary
