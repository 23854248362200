import styled from '@xstyled/styled-components'
import { Styles } from 'src/utils'

export const $Container = styled.divBox`
  display: flex;
  align-items: flex-start;
  width: 100%;

  gap: 12px;

  ${Styles.screens.small`
		flex-wrap: wrap;
	`}

  &:not(:last-child),
  &:not(:last-child),
  > div {
    width: 100%;

    ${Styles.screens.small`
			margin-right: 0 !important;
			margin-bottom: 19px;
			width: 100%;
		`}
  }
`
