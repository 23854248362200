import styled from '@xstyled/styled-components'

import { $Container as $DatePickerContainer } from './DatePicker.styled'

export const $Wrapper = styled.divBox`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`

export const $Container = styled.divBox`
  .ant-picker-input > input {
    font-size: 12px;
    line-height: 24px;
  }

  ${$DatePickerContainer}
`
