import styled from '@xstyled/styled-components'

export const $RainbowContainer = styled.divBox`
  background: linear-gradient(
    90deg,
    rgba(178, 15, 241, 1) 0%,
    rgba(85, 158, 235, 1) 25%,
    rgba(116, 208, 148, 1) 50%,
    rgba(232, 226, 101, 1) 75%,
    rgba(247, 58, 132, 1) 100%
  );
  opacity: 0.4;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
  position: absolute;
`
