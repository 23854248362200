import { Anchor } from '@mantine/core'
import { x } from '@xstyled/styled-components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Table, TableAction, TableProps } from 'src/components'
import { BE_PATHS, FE_PATHS } from 'src/constants'
import { useOrganisationContext } from 'src/context'
import { useAxios, useToast } from 'src/hooks'

import { ReleaseBarrierButton } from './ReleaseBarrierButton'
import { ToggleBarrierButton } from './ToggleBarrierButton'

type BarriersTableProps = {
  barriers: IBarrier[]
} & Pick<TableProps<IBarrier>, 'loading'>

type ActiveAction = {
  actionId: 'open' | 'close' | 'release'
  barrierId: string
}

export const BarriersTable = ({ barriers, ...tableProps }: BarriersTableProps) => {
  const { locations } = useOrganisationContext()
  const navigate = useNavigate()
  const toast = useToast()

  const cameras = locations?.flatMap((loc) => loc?.cameras ?? [])
  const getCameraNameById = (cameraId: string) => cameras?.find((cam) => cam.id === cameraId)?.name
  const getLocationNameById = (locationId: string) => locations?.find((loc) => loc.id === locationId)?.name

  const [, releaseBarrier] = useAxios({
    method: 'POST',
    runOnInit: false,
  })
  const [, toggleBarrier] = useAxios({
    method: 'POST',
    runOnInit: false,
  })

  const [activeActions, setActiveActions] = React.useState<ActiveAction[]>([])

  const isActionLoading = (actionId: ActiveAction['actionId'], barrierId: ActiveAction['barrierId']) =>
    activeActions?.some((action) => action.actionId === actionId && action.barrierId === barrierId)

  const isActionDisabled = (barrierId: ActiveAction['barrierId']) =>
    activeActions?.some((action) => action.barrierId === barrierId && action.actionId !== null)

  const tableActions = [
    {
      label: 'Release',
      tooltip: 'Release barrier',
      render: ({ locationId, id: barrierId, name }: IBarrier) => {
        return (
          <ReleaseBarrierButton
            loading={isActionLoading('release', barrierId)}
            disabled={isActionDisabled(barrierId)}
            onRelease={async () => {
              if (!locationId || !barrierId) return
              try {
                setActiveActions((prevActions) => [...prevActions, { actionId: 'release', barrierId }])
                await releaseBarrier({ url: BE_PATHS.BARRIER_RELEASE(locationId, barrierId) })
                toast(`Barrier: ${name} was released successfully`, { variant: 'success' })
              } catch (error) {
                toast(`Failed to release barrier: ${name}`, { variant: 'error' })
              } finally {
                setActiveActions((prevActions) => prevActions.filter((action) => action.barrierId !== barrierId))
              }
            }}
          />
        )
      },
    },
    {
      label: 'Open barrier',
      render: ({ locationId, id: barrierId, name }: IBarrier) => (
        <ToggleBarrierButton
          loading={isActionLoading('open', barrierId)}
          disabled={isActionDisabled(barrierId)}
          type="ON"
          onToggle={async () => {
            if (!locationId || !barrierId) return
            try {
              setActiveActions((prevActions) => [...prevActions, { actionId: 'open', barrierId }])
              await toggleBarrier({ url: BE_PATHS.BARRIER_TOGGLE(locationId, barrierId), params: { state: 'ON' } })
              toast(`Barrier: ${name} was opened`, { variant: 'success' })
            } catch (error) {
              toast(`Failed to open barrier: ${name}`, { variant: 'error' })
            } finally {
              setActiveActions((prevActions) => prevActions.filter((action) => action.barrierId !== barrierId))
            }
          }}
        />
      ),
    },
    {
      label: 'Close barrier',
      render: ({ locationId, id: barrierId, name }: IBarrier) => (
        <ToggleBarrierButton
          loading={isActionLoading('close', barrierId)}
          disabled={isActionDisabled(barrierId)}
          type="OFF"
          onToggle={async () => {
            if (!locationId || !barrierId) return
            try {
              setActiveActions((prevActions) => [...prevActions, { actionId: 'close', barrierId }])
              await toggleBarrier({ url: BE_PATHS.BARRIER_TOGGLE(locationId, barrierId), params: { state: 'OFF' } })
              toast(`Barrier: ${name} was closed`, { variant: 'success' })
            } catch (error) {
              toast(`Failed to close barrier: ${name}`, { variant: 'error' })
            } finally {
              setActiveActions((prevActions) => prevActions.filter((action) => action.barrierId !== barrierId))
            }
          }}
        />
      ),
    },
  ] as TableAction<IBarrier>[]

  return (
    <Table
      {...tableProps}
      items={barriers}
      headers={[
        {
          key: 'name',
          render: (_, barrier) => (
            <Anchor onClick={() => navigate(`${FE_PATHS.LOCATION(barrier?.locationId)}?barriers=true`)}>
              <x.span fontSize="12px" color="dark">
                {barrier?.name}
              </x.span>
            </Anchor>
          ),
        },
        {
          customKey: 'Location',
          render: (_, barrier) => (
            <Anchor onClick={() => navigate(FE_PATHS.LOCATION(barrier?.locationId))}>
              <x.span fontSize="12px" color="dark">
                {getLocationNameById(barrier?.locationId)}
              </x.span>
            </Anchor>
          ),
        },
        {
          customKey: 'Camera',
          render: (_, barrier) => (
            <Anchor onClick={() => navigate(`${FE_PATHS.LOCATION(barrier?.locationId)}?cameras=true`)}>
              <x.span fontSize="12px" color="dark">
                {getCameraNameById(barrier?.cameraId)}
              </x.span>
            </Anchor>
          ),
        },
        {
          customKey: 'Pulse channel',
          format: (_, barrier) => barrier?.channel || '-',
          align: 'center',
        },
        {
          customKey: 'Toggle channel',
          format: (_, barrier) => barrier?.toggleChannel || '-',
          align: 'center',
        },
      ]}
      actions={tableActions}
    />
  )
}
