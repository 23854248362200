import { x } from '@xstyled/styled-components'

interface EntryErrorProps {
  data: CSVEntryError
}

export const EntryError = ({ data }: EntryErrorProps) => {
  return (
    <x.div display="flex" gap="10px">
      <x.div fontWeight="semibold">Line {data.csvRow}:</x.div>
      <x.div display="flex" flexDirection="column" gap="4px">
        {data?.errors?.map((error, idx) => (
          <x.div key={`error-${idx}`}>{error}</x.div>
        ))}
      </x.div>
    </x.div>
  )
}
