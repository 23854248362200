import * as Tooltip from '@radix-ui/react-tooltip'
import styled, { x } from '@xstyled/styled-components'
import React from 'react'
import { Card, Divider, Table } from 'src/components'
import { useOrganisationContext } from 'src/context'
import { useCopyToClipboard } from 'src/hooks'

const ValueDetail = styled.span`
  margin-left: auto;
  cursor: pointer;
  float: right;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background-color: #dddddd86;
  }
`

interface OrgDetailRowProps {
  label: string
  value?: string | number
  valueTooltipMessage?: string
  tooltipAction?: (value: string | number, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

const OrgDetailRow = ({
  label,
  value = '—',
  valueTooltipMessage = 'Click to copy to clipboard',
  tooltipAction,
}: OrgDetailRowProps) => {
  const copyToClipboard = useCopyToClipboard()

  return (
    <x.div display="flex">
      <x.strong maxW="230px" mr="20px">
        {label}:
      </x.strong>
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <ValueDetail
              onClick={(e) => {
                if (tooltipAction) {
                  tooltipAction?.(value, e)
                  return
                }

                copyToClipboard(value)
              }}
            >
              {value}
            </ValueDetail>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <x.div maxW={500}>
              <Card withoutPadding style={{ boxShadow: '1px 3px 10px #00000040', padding: '5px 10px' }}>
                <x.div display="flex">
                  <x.p>{valueTooltipMessage}</x.p>
                </x.div>
              </Card>
            </x.div>
          </Tooltip.Content>
        </Tooltip.Root>
      </Tooltip.Provider>
    </x.div>
  )
}

const KeyAdministratorSection = ({ title, contact }: { title: string; contact?: Organisation['contact1'] }) => {
  if (!contact) return null

  return (
    <>
      <h4 className="--margin-bottom-medium --margin-top-medium">
        <strong>{title}</strong>
      </h4>
      <Divider light />
      <x.div mb={2} />
      <OrgDetailRow label="Name" value={contact?.name} />
      <OrgDetailRow
        label="Email"
        value={contact?.email}
        valueTooltipMessage="Click to send an email"
        tooltipAction={(v, event) => {
          window.location = `mailto:${v}` as any
          event.preventDefault()
        }}
      />
      <OrgDetailRow
        label="Phone number"
        value={contact?.phone}
        valueTooltipMessage="Click to make phone call"
        tooltipAction={(v, event) => {
          window.location = `tel:${v}` as any
          event.preventDefault()
        }}
      />
    </>
  )
}

interface OrganisationViewProps {
  organisation: Partial<Organisation>
}

export const OrganisationView = ({ organisation }: OrganisationViewProps) => {
  const { locations } = useOrganisationContext()

  // const [{ data: providers }] = useAxios<IProvider[]>({
  //   url: BE_PATHS.PROVIDERS,
  //   params: { filter: genQueryParams({ organisationId: organisation?.id, type: 'PERMIT' }) },
  // })

  const attachedLocations = locations?.filter((location) => location.organisationId === organisation?.id)

  return (
    <x.div minWidth="100%">
      <x.div>
        <h4 className="--margin-bottom-medium --margin-top-medium">
          <strong>DETAILS</strong>
        </h4>
        <Divider light />
        <x.div mb={2} />
        <OrgDetailRow label="Permit Provider" value={'ZatPark'} />
        {organisation?.contact1 && <br />}
        <KeyAdministratorSection title="KEY ADMINISTRATOR 1" contact={organisation?.contact1} />
        {organisation?.contact2 && <br />}
        <KeyAdministratorSection title="KEY ADMINISTRATOR 2" contact={organisation?.contact2} />
      </x.div>
      <x.div>
        <x.div mt={5}>
          <h4 className="--margin-bottom-medium --margin-top-medium">
            <strong>LOCATIONS</strong>
          </h4>
          <Divider light />
          <x.div mb={2} />
          {attachedLocations && attachedLocations.length > 0 && (
            <Table
              items={attachedLocations}
              headers={[
                { key: 'name', align: 'left' },
                { customKey: 'city', align: 'center', format: (_, item) => item.address.city },
                { customKey: 'Location code', align: 'center', format: (_, item) => item.code },
              ]}
            />
          )}
          {(!attachedLocations || attachedLocations.length === 0) && (
            <x.p>No locations assigned to this organisation</x.p>
          )}
        </x.div>
      </x.div>
    </x.div>
  )
}
