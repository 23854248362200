import dayjs from 'dayjs'

export function sortBy<T>(key: keyof T, order: 'ASC' | 'DESC' = 'ASC'): (a: T, b: T) => number {
  return (a, b) => {
    if (a[key] < b[key]) return order === 'ASC' ? -1 : 1
    if (a[key] > b[key]) return order === 'ASC' ? 1 : -1
    return 0
  }
}

export function sortByDate<T>(key: keyof T, order: 'ASC' | 'DESC' = 'ASC'): (a: T, b: T) => number {
  return (a, b) => {
    const aDate = dayjs(a[key] as any).toDate()
    const bDate = dayjs(b[key] as any).toDate()
    if (aDate < bDate) return order === 'ASC' ? -1 : 1
    if (aDate > bDate) return order === 'ASC' ? 1 : -1
    return 0
  }
}
