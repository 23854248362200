import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import { Field, FieldProps } from 'formik'

import { Condition } from '../Condition'
import { Error } from '../Error'

import * as S from './FormikDatePicker.styled'
import { RangePicker } from './RangePicker'

export interface FormikRangePickerProps<T> {
  label?: string
  disabled?: boolean
  type?: string
  name?: keyof T | string
  nestedName?: string
  error?: boolean | string
  onChange?: RangePickerProps<any>['onChange']
  onBlur?: RangePickerProps<any>['onBlur']
  autoFocus?: boolean
  value?: any
  hideErrorWhenNull?: boolean
}

export function FormikRangePicker<T>({
  label,
  name,
  nestedName,
  type,
  onChange,
  onBlur,
  disabled = false,
  autoFocus = false,
  value,
  error,
  hideErrorWhenNull = false,
  ...rangePickerProps
}: FormikRangePickerProps<T> & RangePickerProps<any>) {
  return (
    <Field name={name || nestedName} type={type} placeholder={'Input'}>
      {({ meta }: FieldProps) => {
        const hasError = !!(meta.touched && meta.error)

        return (
          <S.$Container>
            <RangePicker
              {...rangePickerProps}
              label={label}
              disabled={disabled}
              autoFocus={autoFocus}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              status={hasError ? 'error' : undefined}
            />
            <Condition when={hideErrorWhenNull ? hasError : true}>
              <Error visible={hasError}>{meta.error || error}</Error>
            </Condition>
          </S.$Container>
        )
      }}
    </Field>
  )
}
