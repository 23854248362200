export const httpsRedirect = () => {
  const url = window?.location.href
  const isLocalEnvironment = url?.includes('localhost')

  if (isLocalEnvironment) return

  const httpTokens = /^http:\/\/(.*)$/.exec(url)

  if (httpTokens) {
    window?.location.replace('https://' + httpTokens[1])
  }
}
